import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Dialog,
  DialogProps,
  IconButton,
  IconButtonProps,
  Tabs,
  TabsProps,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

const mapSizes = {
  small: {
    '.MuiPaper-root': {
      maxWidth: '424px',
      width: '100%',
    },
  },
  medium: {
    '.MuiPaper-root': {
      maxWidth: '540px',
      width: '100%',
    },
  },
  xl: {
    '.MuiPaper-root': {
      maxWidth: '1080px',
      width: '100%',
    },
  },
};

export const StyledTabs = styled(Tabs)<TabsProps>(() => ({
  width: '100%',
}));

export const StyledAreaBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  borderRadius: '12px',
  border: `1px solid ${theme.colors.background.disabledButton}`,
}));

export const StyledShadowLessButton = styled(Button)<ButtonProps>({
  boxShadow: 'none',
});

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'small' | 'medium' | 'xl' }>(({ size }) => ({
  ...(size && mapSizes[size]),
}));

export const StyledCancelIconButton = styled(IconButton)<IconButtonProps>({
  padding: '0',
});

export const StyledDataGrid = styled(DataGrid)<DataGridProps & { theme?: Theme }>(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-cell': {
    padding: '10px 0',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '&.MuiDataGrid-root': {
    '--DataGrid-rowBorderColor': 'transparent',
    height: '100%',
  },
  '& .MuiDataGrid-footerContainer': {
    border: 'transparent',
  },
  '& .MuiDataGrid-row': {
    position: 'relative',
    borderRadius: '12px',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'block',
      border: `1px solid ${theme.colors.action.disabledBackground}`,
      pointerEvents: 'none',
      borderRadius: '12px',
      zIndex: 1,
    },
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
  '& .MuiDataGrid-cell--withRenderer': {
    border: 'transparent',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '&.MuiDataGrid-root .MuiDataGrid-withBorderColor': {
    border: 'transparent',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
}));
