import { Palette } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
  h1: {
    fontFamily: 'InterBold',
    fontWeight: 700,
    fontSize: '96px',
    lineHeight: 1.167,
  },
  h2: {
    fontFamily: 'InterBold',
    fontWeight: 700,
    fontSize: '60px',
    lineHeight: 1.2,
  },
  h3: {
    fontFamily: 'InterBold',
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: 1.167,
  },
  h4: {
    fontFamily: 'InterBold',
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: 1.235,
    letterSpacing: '0.25px',
  },
  h5: {
    fontFamily: 'InterBold',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: 1.334,
  },
  h6: {
    fontFamily: 'InterBold',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: 1.6,
    letterSpacing: '0.15px',
  },
  body1: {
    fontFamily: 'InterRegular',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.15px',
  },
  body2: {
    fontFamily: 'InterRegular',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.43,
    letterSpacing: '0.17px',
  },
  subtitle1: {
    fontFamily: 'InterSemiBold',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.15px',
  },
  subtitle2: {
    fontFamily: 'InterSemiBold',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.43,
    letterSpacing: '0.1px',
  },
  overline: {
    fontFamily: 'InterRegular',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 2.66,
    letterSpacing: '1px',
  },
  caption: {
    fontFamily: 'InterRegular',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.66,
    letterSpacing: '0.4px',
  },
  custom: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.17px',
  },
  button: {
    fontFamily: 'InterSemiBold',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: 1.7,
    textTransform: 'initial',
  },
};
