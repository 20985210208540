import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Campaign } from '@api/api';
import { STATUS_MAP, StatusType } from '@constants/status';
import Controls from '@pages/LeadScrapers/components/ScrapersList/Controls';
import { StyledTypographyWrap } from '@pages/LeadScrapers/styled';
import { capitalize } from '@utils/formatInitials';
import { convertIsoToReadable } from '@utils/formatTime';

import { Box, Chip, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';

export const useListColumns = (): GridColDef[] => {
  const { t } = useTranslation();

  return [
    {
      field: 'name',
      headerName: 'Scraper Name',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams<Campaign>) => (
        <Box display="flex" flexDirection="column" flex={1} pl={2}>
          <Typography variant="subtitle2">{row.name}</Typography>
          <StyledTypographyWrap variant="caption">
            {`${t('dashboard.scraperDetails.updatedAt')} ${convertIsoToReadable(row.created_at)}`}
          </StyledTypographyWrap>
        </Box>
      ),
    },
    {
      field: 'type',
      headerName: 'Types',
      hideable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams<Campaign>) => (
        <Box>
          <Typography variant="body2" component="span">
            {row.campaign_type.type_name}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'filters',
      headerName: 'Filter',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams<Campaign>) => (
        <Box flex={1}>
          {row.campaign_scrapers.map((scraper, idx) => (
            <Fragment key={scraper.scraper_id}>
              <StyledTypographyWrap variant="body2" component="span">
                {`${capitalize(scraper.scrapers.scraper_name).trim()}${idx < row.campaign_scrapers.length - 1 ? ', ' : ''}`}
              </StyledTypographyWrap>
            </Fragment>
          ))}
        </Box>
      ),
    },
    {
      field: 'leads',
      headerName: 'Leads',
      width: 80,
      hideable: false,
      sortable: false,
      resizable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: GridCellParams<Campaign>) => (
        <Typography variant="subtitle2">
          {row?.campaign_scrapers.reduce((acc, next) => acc + next.leads_count, 0)}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      hideable: false,
      sortable: false,
      resizable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: GridCellParams<Campaign>) => {
        const isScheduled = new Date(row.campaign_start[0].campaign_start) > new Date();
        const status: StatusType = isScheduled
          ? { color: 'default', label: 'dashboard.scraperDetails.scheduled' }
          : STATUS_MAP[row.status];

        return <Chip variant="filled" label={t(status.label)} color={status.color} />;
      },
    },
    {
      field: 'controls',
      headerName: '',
      width: 150,
      hideable: false,
      sortable: false,
      resizable: false,
      align: 'center',
      renderCell: ({ row }: GridCellParams<Campaign>) => (
        <Controls
          status={row.status}
          campaignId={row.campaign_id}
          name={row.name}
          description={row.description}
          campaignStart={row.campaign_start[0].campaign_start}
        />
      ),
    },
  ];
};
