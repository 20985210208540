export type StatusType = {
  color: 'error' | 'success' | 'default';
  label: string;
};

export const STATUS_MAP: { [key: number]: StatusType } = {
  0: {
    color: 'error',
    label: 'dashboard.scraperDetails.stopped',
  },
  1: {
    color: 'success',
    label: 'dashboard.scraperDetails.active',
  },
  2: {
    color: 'default',
    label: 'dashboard.scraperDetails.finished',
  },
};
