import { Paper, PaperProps, TextField, TextFieldProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledPaper = styled(Paper)<PaperProps>(() => ({
  borderRadius: '12px',
  backgroundColor: grey[100],
  padding: '16px',
}));

export const StyledTextField = styled(TextField)<TextFieldProps & { theme?: Theme }>(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.colors.white,
  },
  appearance: 'none',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));
