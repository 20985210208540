import { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { useUser } from '@hooks/api/useUser';

import Loading from '@components/Loading/Loading';

const Dashboard: FC = () => {
  const { isLoading } = useUser();

  if (isLoading) return <Loading />;

  return <Outlet />;
};

export default memo(Dashboard);
