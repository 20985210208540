import { createContext, FC, PropsWithChildren, useContext } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

const ScreenSizeContext = createContext<ReturnType<typeof useScreenSizeContextValue> | null>(null);

const useScreenSizeContextValue = () => {
  const theme = useTheme();
  const isLargerScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return {
    isLargerScreen,
  };
};

export const useScreenSizeContext = () => {
  const context = useContext(ScreenSizeContext);
  if (!context) throw new Error('useScreenSizeContext must be inside SettingsProvider');
  return context;
};

export const ScreenSizeProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useScreenSizeContextValue();
  return <ScreenSizeContext.Provider value={value}>{children}</ScreenSizeContext.Provider>;
};
