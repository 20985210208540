import { useMemo } from 'react';
import { useGetCampaignSourcesQuery } from '@api/api';
import { CAMPAIGN_ID } from '@constants/common';

export const useCampaignSources = (id: string) => {
  const { data, isLoading, error } = useGetCampaignSourcesQuery(
    { [CAMPAIGN_ID]: id as string },
    {
      refetchOnMountOrArgChange: true,
      skip: id === null,
    },
  );
  const info = useMemo(() => data ?? [], [data]);
  return { data: info, isLoading, error };
};
