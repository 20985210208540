/* eslint-disable no-template-curly-in-string */
import { SLIDER_MAX_VAL, SLIDER_MIN_VAL } from '@constants/common';

import AutocompleteSearchFilter from '@/shared/SearchFilters/AutocompleteSearchFilter';
import CheckboxSearchFilter from '@/shared/SearchFilters/CheckboxSearchFilter';
import DropdownSearchFilter from '@/shared/SearchFilters/DropdownSearchFilter';
import {
  GoodsOptions,
  JobshiftOptions,
  JobtypeOptions,
  PropertyOptions,
  PropertyStatusOptions,
  PropertyTypeOptions,
  SqftOptions,
} from '@/shared/SearchFilters/options';
import SliderSearchFilter from '@/shared/SearchFilters/SliderSearchFilter';
import TextFieldSearchFilter from '@/shared/SearchFilters/TextFieldSearchFilter';

export const createJobsFilters = () => [
  {
    field: 'data_filter_+string_contains+email',
    Component: TextFieldSearchFilter,
    label: 'Email',
  },
  {
    field: 'data_filter_+string_contains+phone',
    Component: TextFieldSearchFilter,
    label: 'Contact phone',
    type: 'number',
  },
  {
    field: 'data_filter_+string_contains+company_name',
    Component: TextFieldSearchFilter,
    label: 'Company',
  },
  {
    field: 'data_filter_+string_contains+company_location',
    Component: TextFieldSearchFilter,
    label: 'Company location',
  },
  {
    field: 'data_filter_+array_contains+job_type',
    Component: AutocompleteSearchFilter,
    label: 'Job type',
    type: 'text',
    options: JobtypeOptions,
  },
  {
    field: 'data_filter_+string_contains+job_title',
    Component: TextFieldSearchFilter,
    label: 'Job title',
  },
  {
    field: 'data_filter_+array_contains+job_shift',
    Component: AutocompleteSearchFilter,
    label: 'Job shift',
    type: 'text',
    options: JobshiftOptions,
  },
  {
    field: 'data_filter_+ne+vacancy_expired',
    Component: CheckboxSearchFilter,
    label: 'Vacant',
  },
  {
    field: 'data_filter_+string_contains+rating',
    Component: TextFieldSearchFilter,
    label: 'Rating',
    minValue: 0, // prevent TS error
    maxValue: 5, // prevent TS error
    dependencies: {}, // prevent TS error
  },
];

export const createGoodsFilters = () => [
  {
    field: 'data_filter_+string_contains+asin',
    Component: TextFieldSearchFilter,
    label: 'Asin',
    type: 'text', // prevent TS error
    options: [], // prevent TS error
    minValue: SLIDER_MIN_VAL, // prevent TS error
    maxValue: SLIDER_MAX_VAL, // prevent TS error
  },
  {
    field: 'data_filter_+string_contains+availability',
    Component: CheckboxSearchFilter,
    label: 'Availability',
  },
  {
    field: 'data_filter_+string_contains+category',
    Component: TextFieldSearchFilter,
    label: 'Category',
  },
  [
    {
      field: 'data_filter_+gte+price',
      Component: DropdownSearchFilter,
      label: 'Price Min',
      dependencies: {
        min: 'data_filter_+gte+price',
        max: 'data_filter_+lte+price',
      },
      options: GoodsOptions,
    },
    {
      field: 'data_filter_+lte+price',
      Component: DropdownSearchFilter,
      label: 'Price Max',
      dependencies: {
        min: 'data_filter_+gte+price',
        max: 'data_filter_+lte+price',
      },
      options: GoodsOptions,
    },
  ],
  {
    field: 'data_filter_+string_contains+title',
    Component: TextFieldSearchFilter,
    label: 'Title',
  },
];

export const createPropertyFilters = () => [
  {
    field: 'data_filter_+string_contains+address',
    Component: TextFieldSearchFilter,
    label: 'City',
    type: 'text', // prevent TS error
  },
  [
    {
      field: 'data_filter_+gte+price',
      Component: DropdownSearchFilter,
      label: 'Price Min',
      dependencies: {
        min: 'data_filter_+gte+price',
        max: 'data_filter_+lte+price',
      },
      options: PropertyOptions,
    },
    {
      field: 'data_filter_+lte+price',
      Component: DropdownSearchFilter,
      label: 'Price Max',
      dependencies: {
        min: 'data_filter_+gte+price',
        max: 'data_filter_+lte+price',
      },
      options: PropertyOptions,
    },
  ],
  {
    field: 'data_filter_+array_contains+property_type',
    Component: AutocompleteSearchFilter,
    label: 'Property type',
    options: PropertyTypeOptions,
  },
  {
    field: 'data_filter_+eq+bedrooms',
    Component: SliderSearchFilter,
    label: 'Beds',
    minValue: SLIDER_MIN_VAL,
    maxValue: SLIDER_MAX_VAL,
  },
  {
    field: 'data_filter_+eq+bathrooms',
    Component: SliderSearchFilter,
    label: 'Bathrooms',
    minValue: SLIDER_MIN_VAL,
    maxValue: SLIDER_MAX_VAL,
  },
  [
    {
      field: 'data_filter_+gte+sqft',
      Component: DropdownSearchFilter,
      label: 'Sqft Min',
      dependencies: {
        min: 'data_filter_+gte+sqft',
        max: 'data_filter_+lte+sqft',
      },
      options: SqftOptions,
    },
    {
      field: 'data_filter_+lte+sqft',
      Component: DropdownSearchFilter,
      label: 'Sqft Max',
      dependencies: {
        min: 'data_filter_+gte+sqft',
        max: 'data_filter_+lte+sqft',
      },
      options: SqftOptions,
    },
  ],
  {
    field: 'data_filter_+array_contains+status',
    Component: AutocompleteSearchFilter,
    label: 'Status',
    options: PropertyStatusOptions,
  },
  {
    field: 'data_filter_+string_contains+contact_phone',
    Component: TextFieldSearchFilter,
    label: 'Contact phone',
    type: 'number',
  },
];
