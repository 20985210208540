import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useUser } from '@hooks/api/useUser';
import { SettingsProvider } from '@pages/Settings/context';
import AppLayout from '@providers/layouts/AppLayout/AppLayout';

import { Alert, Box, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';

import ActiveTab from './components/ActiveTab';
import ToggleTabs from './components/ToggleTabs';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const Settings: FC = () => {
  const { t } = useTranslation();
  const { data: me, isLoading } = useUser();
  const { isLargerScreen } = useScreenSizeContext();

  const noActiveSubscription = me?.subscriptions?.[0]?.stripe_customer_id && !me?.subscriptions?.[0].subscription_id;

  if (isLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.settings')}</title>
      </Helmet>

      <SettingsProvider>
        <AppLayout showSidebar={isLargerScreen}>
          <Box my={3} display="flex" justifyContent={isLargerScreen ? 'center' : 'start'}>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              maxWidth="1200px"
              minWidth={isLargerScreen ? '580px' : '360px'}
              px={isLargerScreen ? 5 : 0}
              gap={1}
            >
              <Typography variant="h6">{t('common.titles.settings')}</Typography>
              <ToggleTabs />
              {noActiveSubscription && (
                <Box mt={2} mb={1} textAlign="center">
                  <Alert severity="warning" variant="filled">
                    <Typography variant="subtitle1">{t('billing.noActiveSubscription')}</Typography>
                  </Alert>
                </Box>
              )}
              <ActiveTab />
            </Box>
          </Box>
        </AppLayout>
      </SettingsProvider>
    </>
  );
};

export default memo(Settings);
