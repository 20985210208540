import { FC, memo, useEffect } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useChangePasswordMutation, User, useUpdateMeMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { useUser } from '@hooks/api/useUser';
import { useSettingsContext } from '@pages/Settings/context';
import { ProfileDataFieldsNames } from '@pages/Settings/enums';
import { ProfileData } from '@pages/Settings/types';
import { getUpdateMePayload } from '@pages/Settings/utils';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import ProfileForm from './ProfileForm';

const ProfileTab: FC = () => {
  const { profileForm, editPassword, setEditPassword } = useSettingsContext();
  const { data: userData } = useUser();
  const [updateUserMutation, { isLoading: updateUserIsLoading }] = useUpdateMeMutation();
  const [changePasswordMutation, { isLoading: changePasswordIsLoading }] = useChangePasswordMutation();
  const snackbar = useSnackbar();

  const handleSubmit: SubmitHandler<ProfileData> = async (formData: ProfileData) => {
    try {
      const updateUserPayload = getUpdateMePayload(formData, userData as User);

      await updateUserMutation({ updateUserDto: updateUserPayload });

      if (formData.newPassword && formData.confirmNewPassword) {
        await changePasswordMutation({
          changePasswordDto: {
            password: formData.newPassword as string,
            repeatPassword: formData.confirmNewPassword as string,
          },
        });
        setEditPassword((prevState: boolean) => !prevState);
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const isLoading = [changePasswordIsLoading, updateUserIsLoading].some(Boolean);

  useEffect(() => {
    if (userData) {
      profileForm.setValue(ProfileDataFieldsNames.firstname, userData.firstname);
      profileForm.setValue(ProfileDataFieldsNames.lastname, userData.lastname);
      profileForm.setValue(ProfileDataFieldsNames.email, userData.email);
      profileForm.setValue(ProfileDataFieldsNames.phone, userData.phone);
    }
  }, [profileForm, userData]);

  return (
    <>
      <FormProvider {...profileForm}>
        <form onSubmit={profileForm.handleSubmit(handleSubmit)}>
          <ProfileForm loading={isLoading} editPassword={editPassword} setEditPassword={setEditPassword} />
        </form>
      </FormProvider>
    </>
  );
};

export default memo(ProfileTab);
