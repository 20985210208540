import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCampaignDetailsContext } from '@pages/CampaignDetails/context';

import { Box, Tab } from '@mui/material';

import { Indicators } from '../enums';

import { StyledDivider, StyledTabs } from './styled';

const config = [
  { label: 'common.titles.generalInfo', value: Indicators.GENERAL_INFO },
  { label: 'common.titles.leads', value: Indicators.LEADS },
];

const ToggleTabs: FC = () => {
  const { t } = useTranslation();
  const { tab, handleChange } = useCampaignDetailsContext();

  return (
    <Box>
      <StyledTabs
        value={tab}
        onChange={handleChange}
        aria-label="campaign info tabs"
        textColor="primary"
        indicatorColor="primary"
      >
        {config.map(v => (
          <Tab value={v.value} label={t(v.label)} key={v.value} />
        ))}
      </StyledTabs>
      <StyledDivider />
    </Box>
  );
};

export default memo(ToggleTabs);
