import { Box, BoxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '24px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '300px',
  },
}));

export const StyledBoxContainer = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    borderLeft: `1px solid ${theme.colors.action.disabledBackground}`,
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '340px',
  },
}));

export const StyledConfirmBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.action.disabledBackground}`,
}));
