import { User } from '@api/api';
import { TRIAL_DAYS } from '@constants/auth';
import { ProfileData } from '@pages/Settings/types';
import { differenceInDays } from 'date-fns';

export const isFieldsDiffer = (initialField: string, updatedField: string) => initialField !== updatedField;
export const getUpdateMePayload = (formData: ProfileData, userData: User) => ({
  ...(isFieldsDiffer(formData.firstname as string, userData?.firstname as string) && {
    firstname: formData.firstname,
  }),
  ...(isFieldsDiffer(formData.lastname as string, userData?.lastname as string) && {
    lastname: formData.lastname,
  }),
  ...(isFieldsDiffer(formData.phone as string, userData?.phone as string) && { phone: formData.phone }),
  ...(isFieldsDiffer(formData.email as string, userData?.email as string) && { email: formData.email }),
});

export const convertFromCents = (price: number) => (price / 100).toFixed(2);

export const isTrialExpired = (createdDate: string): boolean => {
  const createdDateObject = new Date(createdDate);
  const currentDate = new Date();
  return differenceInDays(currentDate, createdDateObject) > TRIAL_DAYS;
};
