import { FC, memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLeadScraperContext } from '@pages/LeadScraper/context';
import { StepContentProps } from '@pages/LeadScraper/types';
import { StyledAreaBox } from '@pages/styled';

import EditableBlock from '../EditableBlock';

import SchedulingForm from './SchedulingForm';

const ScraperScheduling: FC<StepContentProps> = ({ handleNext, handleBack, isActive, isCompleted }) => {
  const { schedulingForm } = useLeadScraperContext();

  return (
    <StyledAreaBox ml="8px" active={isActive}>
      <EditableBlock
        isCompleted={isCompleted}
        handleClick={handleBack}
        title="dashboard.newScraper.scheduling.title"
        subtitle="dashboard.newScraper.scheduling.subtitle"
      />

      {isActive && (
        <FormProvider {...schedulingForm}>
          <form onSubmit={schedulingForm.handleSubmit(handleNext)}>
            <SchedulingForm />
          </form>
        </FormProvider>
      )}
    </StyledAreaBox>
  );
};

export default memo(ScraperScheduling);
