import { useCallback, useEffect } from 'react';
import { useWS } from '@providers/ws/WebsocketProvider';

export const useWsEvent = (event: string, callback: (response: Record<string, any>) => void) => {
  const { socket } = useWS();

  const callEvent = useCallback((data: Record<string, any>) => callback(data), [callback]);

  useEffect(() => {
    if (socket) {
      socket.on(event, callEvent);
    }
    return () => {
      if (socket) {
        socket.off(event, callEvent);
      }
    };
  }, [callEvent, event, socket]);
};
