import { FC, memo, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { DEFAULT_PAGE_LIMIT } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { STATUS_MAP } from '@constants/status';
import Filters from '@pages/LeadScrapers/components/Filters';
import Controls from '@pages/LeadScrapers/components/ScrapersList/Controls';
import { StyledTypographyWrap } from '@pages/LeadScrapers/styled';
import { MobileScrapersListProps } from '@pages/LeadScrapers/types';
import { StyledAreaBox } from '@pages/styled';
import { capitalize } from '@utils/formatInitials';
import { convertIsoToReadable } from '@utils/formatTime';
import debounce from 'lodash-es/debounce';

import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import AddScraperButton from '@components/AddScraperButton/AddScraperButton';

const MobileScrapersList: FC<MobileScrapersListProps> = ({
  campaigns,
  isLargerScreen,
  isLoading = true,
  setPage,
  page = 0,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lastElementRef = useRef(null);
  const totalCampaigns = useMemo(() => campaigns?.data ?? [], [campaigns?.data]);

  const handleCardClick = (campaign_id: number) => {
    navigate(`/${ROUTING.CAMPAIGN_DETAILS}/${campaign_id}`);
  };

  const handleLoadMoreCampaigns = debounce(() => {
    if (!isLoading && setPage) {
      setPage(page + 1);
    }
  }, 3000);

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start" gap={2} my={2}>
        <Typography variant="h6">{t('dashboard.navigation.scrapers')}</Typography>
        <AddScraperButton />
      </Box>

      <Filters isLargerScreen={isLargerScreen} />

      <Box display="flex" flexDirection="column">
        {Array.from(totalCampaigns).map((campaign, index, array) => (
          <StyledAreaBox
            key={campaign.campaign_id}
            display="flex"
            gap={1}
            flexDirection="column"
            my={1}
            onClick={() => handleCardClick(campaign.campaign_id)}
            ref={index === array.length - 1 ? lastElementRef : null}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle2">{campaign.name}</Typography>
              <StyledTypographyWrap variant="caption">
                {`${t('dashboard.scraperDetails.updatedAt')} ${convertIsoToReadable(campaign.created_at)}`}
              </StyledTypographyWrap>
            </Box>
            <Typography variant="body2">{campaign.campaign_type.type_name}</Typography>
            <Box>
              {campaign.campaign_scrapers.map((scraper, idx) => (
                <StyledTypographyWrap variant="body2" component="span" key={scraper.scraper_id}>
                  {`${capitalize(scraper.scrapers.scraper_name).trim()}${idx < campaign.campaign_scrapers.length - 1 ? ', ' : ''}`}
                </StyledTypographyWrap>
              ))}
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Controls
                status={campaign.status}
                campaignId={campaign.campaign_id}
                name={campaign.name}
                description={campaign.description}
                campaignStart={campaign.campaign_start[0].campaign_start}
              />

              <Box display="flex" gap={2} alignItems="center">
                <Typography variant="subtitle2">
                  {`${capitalize(t('dashboard.newScraper.leadsLabel'))}: ${campaign.campaign_scrapers.reduce((acc, next) => acc + next.leads_count, 0)}`}
                </Typography>
                <Chip
                  variant="filled"
                  label={t(STATUS_MAP[campaign.status].label)}
                  color={STATUS_MAP[campaign.status].color}
                />
              </Box>
            </Box>
            {index === array.length - 1 && array.length >= DEFAULT_PAGE_LIMIT && (
              <Waypoint onEnter={handleLoadMoreCampaigns} />
            )}
          </StyledAreaBox>
        ))}
        {isLoading && (
          <Box p={3} margin="0 auto">
            <CircularProgress size="32px" />
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(MobileScrapersList);
