import { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CampFilterDto } from '@api/api';
import { StyledAreaBox } from '@pages/styled';

import { Box, Typography } from '@mui/material';

import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const AppliedFilters: FC<{ pricedFilters: CampFilterDto[] }> = ({ pricedFilters }) => {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenSizeContext();

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      <Typography variant="subtitle2">{t('dashboard.campaignDetails.appliedFilters')}</Typography>
      <StyledAreaBox display="flex" gap={3} flexDirection={isLargerScreen ? 'row' : 'column'}>
        {pricedFilters.map(({ scraper_id, filter_name, filter_val }) => (
          <Fragment key={scraper_id}>
            <Box display="flex" flexDirection="column" gap="4px">
              <Typography variant="caption" color="text.secondary">
                {filter_name}
              </Typography>
              <Typography variant="body2">{filter_val}</Typography>
            </Box>
          </Fragment>
        ))}
      </StyledAreaBox>
    </Box>
  );
};

export default memo(AppliedFilters);
