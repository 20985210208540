import { skipForwardProp } from '@utils/styled';

import { Box, BoxProps, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledSourcesWrapper = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  borderRadius: '12px',
  border: `1px solid ${theme.colors.background.disabledButton}`,
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup, { shouldForwardProp: skipForwardProp(['isMobile']) })<
  ToggleButtonGroupProps & { theme?: Theme; isMobile?: boolean }
>(({ theme, isMobile }) => ({
  width: '100%',
  display: 'flex',
  '&.MuiToggleButtonGroup-root': {
    width: isMobile ? '100%' : 'inherit',
    '.MuiButtonBase-root': {
      color: theme.colors.text,
      border: 'transparent',
      padding: '18px 24px',
      flex: isMobile ? 1 : 0,
      '&.MuiToggleButton-root': {
        borderRadius: '12px 0px 0px 12px',
      },
      '&.MuiToggleButtonGroup-firstButton': {
        borderRadius: '12px 0px 0px 12px',
      },
      '&.Mui-selected': {
        backgroundColor: theme.colors.primary.selected,
        '&.MuiToggleButtonGroup-middleButton': {
          borderRadius: '0px',
        },
        '&.MuiToggleButtonGroup-lastButton': {
          borderRadius: isMobile ? '0px 12px 12px 0px' : '0px',
        },
      },
      '&:hover': {
        backgroundColor: theme.colors.primary.hover,
        '&.MuiToggleButtonGroup-middleButton': {
          borderRadius: '0px',
        },
        '&.MuiToggleButtonGroup-lastButton': {
          borderRadius: '0px',
        },
      },
    },
  },
}));
