import { Dispatch, FC, memo, SetStateAction, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import { useLogout } from '@hooks/common/useLogout';
import { ProfileDataFieldsNames } from '@pages/Settings/enums';
import { ProfileData } from '@pages/Settings/types';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EditIcon from '@mui/icons-material/Edit';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField, Typography } from '@mui/material';
import TextFieldPhone from '@components/TextFieldPhone/TextFieldPhone';

import { LogoutAllConfirmationModalId } from '../ConfirmModal';
import { StyledShadowLessButton } from '../styled';

import EditPassword from './EditPassword';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const ProfileForm: FC<{
  loading: boolean;
  editPassword: boolean;
  setEditPassword: Dispatch<SetStateAction<boolean>>;
}> = ({ loading, editPassword, setEditPassword }) => {
  const { t } = useTranslation();
  const { control, formState, resetField } = useFormContext<ProfileData>();
  const { logout } = useLogout();
  const { isLargerScreen } = useScreenSizeContext();

  const handleEditPassword = () => setEditPassword((prevState: boolean) => !prevState);

  useEffect(() => {
    if (!editPassword) {
      resetField('oldPassword');
      resetField('newPassword');
      resetField('confirmNewPassword');
    }
  }, [editPassword, resetField]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Box display="flex" gap={1} alignItems="center">
          <AccountCircleRoundedIcon color="disabled" />
          <Typography variant="subtitle2">{t('profile.name')}</Typography>
        </Box>

        <Box display="flex" gap={2} mt={2} flexDirection={isLargerScreen ? 'row' : 'column'}>
          <Controller
            control={control}
            name={ProfileDataFieldsNames.firstname}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                variant="outlined"
                label={t('common.inputs.firstName')}
                type="text"
                autoComplete={ProfileDataFieldsNames.firstname}
                error={Boolean(fieldState.error)}
                disabled={loading}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name={ProfileDataFieldsNames.lastname}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                variant="outlined"
                label={t('common.inputs.lastName')}
                type="text"
                autoComplete={ProfileDataFieldsNames.lastname}
                disabled={loading}
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
              />
            )}
          />
        </Box>
      </Box>

      <Box>
        <Box display="flex" gap={1} alignItems="center">
          <EmailRoundedIcon color="disabled" />
          <Typography variant="subtitle2">{t('profile.email')}</Typography>
        </Box>

        <Box mt={2}>
          <Controller
            control={control}
            name={ProfileDataFieldsNames.email}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                variant="outlined"
                placeholder={t('common.inputs.email')}
                type="email"
                autoComplete="useremail"
                disabled={loading}
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                }
                {...field}
              />
            )}
          />
        </Box>
      </Box>

      {/* PHONE */}
      <Box>
        <Box display="flex" gap={1} alignItems="center">
          <LocalPhoneIcon color="disabled" />
          <Typography variant="subtitle2">{t('profile.phone')}</Typography>
        </Box>

        <Box mt={2}>
          <Controller
            control={control}
            name={ProfileDataFieldsNames.phone}
            render={({ field, fieldState }) => (
              <TextFieldPhone
                {...field}
                disabled={loading}
                error={!!fieldState.error}
                helperText={fieldState?.error?.message}
              />
            )}
          />
        </Box>
      </Box>

      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap={1} alignItems="center">
            {editPassword ? <LockOpenRoundedIcon color="disabled" /> : <LockRoundedIcon color="disabled" />}
            <Typography variant="subtitle2">{t('profile.password')}</Typography>
          </Box>

          <StyledShadowLessButton
            size="small"
            color="primary"
            variant="text"
            startIcon={<EditIcon />}
            onClick={handleEditPassword}
            disabled={loading}
            disableElevation
            disableRipple
          >
            {!editPassword ? t('profile.changePassword') : t('profile.abortChangePassword')}
          </StyledShadowLessButton>
        </Box>

        <Box mt={2}>
          {editPassword ? (
            <EditPassword />
          ) : (
            <TextField
              fullWidth
              value="************"
              type="password"
              variant="outlined"
              placeholder={t('common.inputs.password')}
              disabled
            />
          )}
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <StyledShadowLessButton
          size="small"
          color="primary"
          variant="text"
          startIcon={<LogoutIcon />}
          onClick={async e => {
            e.stopPropagation();
            const result = await NiceModal.show(LogoutAllConfirmationModalId);
            if (result) logout();
          }}
          disableElevation
          disableRipple
          disabled={loading}
        >
          {t('profile.logoutAll')}
        </StyledShadowLessButton>

        <LoadingButton
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          loading={loading}
          disabled={!formState.isValid}
        >
          {t('profile.save')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default memo(ProfileForm);
