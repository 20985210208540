import { CreateSchedulingDto, ScrapersDto } from '@api/api';
import { Scheduling, SchedulingDays } from '@pages/LeadScraper/types';

export const isFormFilled = <T extends Record<string, string>>(obj: T) =>
  Object.keys(obj ?? {}).length > 0 &&
  Object.values(obj ?? {})
    .filter(Boolean)
    .some((val: unknown) => Boolean(String(val).trim()));

export const compareCampaignType = (options: ScrapersDto[], inputFields: ScrapersDto[]) => {
  if (options.length === 0 || inputFields.length === 0) {
    return false;
  }
  const campaignTypesSet = new Set(inputFields.map(field => field.campaign_type_id_campaign_type));

  return options.some(option => campaignTypesSet.has(option.campaign_type_id_campaign_type));
};

export const daysOfWeek: SchedulingDays[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const formatDays = (daysArr: Array<Record<string, boolean>>): number[] | undefined => {
  if (daysArr.length < 1) return undefined;

  return daysArr.reduce((acc: number[], dayObj) => {
    const day = Object.keys(dayObj)[0] as SchedulingDays;
    if (dayObj[day]) {
      acc.push(daysOfWeek.indexOf(day));
    }
    return acc;
  }, []);
};

export const formatDayOfMonth = (dayOfTheMonth?: string): number[] | undefined => {
  if (!dayOfTheMonth) return undefined;
  const day = parseInt(dayOfTheMonth, 10);

  return [day];
};

export const combineDateAndTime = (startDate: Date, startTime: Date): string => {
  const datePart = startDate.toISOString().split('T')[0];
  const timePart = startTime.toISOString().split('T')[1];
  return `${datePart}T${timePart}`;
};

export const provideSchedulingData = (schedulingData: Scheduling) => {
  const { repeat, startTime, numberOfWeeks, numberOfMonths, days, startDate } = schedulingData;

  if (repeat.now) return undefined;

  const repeatTypeMap: { [key in keyof Scheduling['repeat']]: () => CreateSchedulingDto | undefined } = {
    now: () => undefined,
    once: () => ({
      repeat_count: 0,
      start_date: combineDateAndTime(startDate as Date, startTime as Date),
      repeat: 'Once',
    }),
    weekly: () => ({
      repeat_count: Number(numberOfWeeks),
      start_date: combineDateAndTime(startDate as Date, startTime as Date),
      days: formatDays(days),
      repeat: 'Weekly',
    }),
    monthly: () => ({
      repeat_count: Number(numberOfMonths),
      start_date: combineDateAndTime(startDate as Date, startTime as Date),
      days: formatDayOfMonth(schedulingData.dayOfTheMonth),
      repeat: 'Monthly',
    }),
  };

  const repeatKey = Object.keys(repeat).find(key => repeat[key as keyof Scheduling['repeat']]);

  if (repeatKey) {
    return repeatTypeMap[repeatKey as keyof Scheduling['repeat']]();
  }

  return null;
};
