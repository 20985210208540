import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Campaign, CampaignFiltersDto, CampFilterDto } from '@api/api';
import { TOKENS } from '@constants/common';
import { useCampaignDetailsContext } from '@pages/CampaignDetails/context';
import { Indicators } from '@pages/CampaignDetails/enums';
import { FreeFilter } from '@pages/CampaignDetails/types';
import { calculateUsedTokens, extendArrayWithFreeFilters, getSumOfPricedFilters } from '@pages/CampaignDetails/utils';
import { StyledAreaBox } from '@pages/styled';
import { capitalize } from '@utils/formatInitials';

import { ArrowForwardRounded } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const ScraperSources: FC<{
  campaignData?: Campaign;
  filtersData?: CampaignFiltersDto[];
  pricedFilters: CampFilterDto[];
}> = ({ campaignData, filtersData, pricedFilters }) => {
  const { t } = useTranslation();
  const { setTab, setSourceIndex } = useCampaignDetailsContext();
  const { isLargerScreen } = useScreenSizeContext();
  const extendedFreeFiltersData = extendArrayWithFreeFilters(
    campaignData as Campaign,
    filtersData as CampaignFiltersDto[],
  );
  const pricedPrice = getSumOfPricedFilters(filtersData as CampaignFiltersDto[], pricedFilters);

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      <Typography variant="subtitle2">{t('dashboard.campaignDetails.scraperSources')}</Typography>
      <Box display="flex" gap={3} flexDirection={isLargerScreen ? 'row' : 'column'}>
        {extendedFreeFiltersData?.map((scraper, index) => (
          <StyledAreaBox
            key={scraper.id}
            display="flex"
            flexDirection="column"
            gap={2}
            width="300px"
            justifyContent="space-between"
          >
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="baseline">
              <Typography variant="subtitle1">{capitalize(scraper.scrapers.site).trim()}</Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                textAlign="center"
              >{`${calculateUsedTokens({ leads: scraper.leads_count, basePrice: scraper.scrapers.price, additionalPrice: pricedPrice })} ${TOKENS} ${t('common.used')}`}</Typography>
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              {scraper.free_filters.map((freeFilter: FreeFilter) => (
                <Box key={`${freeFilter.filter_name}${freeFilter.filter_val}`}>
                  <Typography variant="caption" color="text.secondary">
                    {freeFilter.filter_name}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    {freeFilter.filter_val}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box>
              <Typography
                variant="subtitle2"
                mb={2}
              >{`${scraper.leads_count} ${t('dashboard.newScraper.leadsLabel')}`}</Typography>

              <Button
                size="medium"
                color="primary"
                variant="outlined"
                fullWidth
                endIcon={<ArrowForwardRounded />}
                onClick={e => {
                  e.preventDefault();
                  setSourceIndex(index);
                  setTab(Indicators.LEADS);
                }}
              >
                {t('dashboard.campaignDetails.viewLeads')}
              </Button>
            </Box>
          </StyledAreaBox>
        ))}
      </Box>
    </Box>
  );
};

export default memo(ScraperSources);
