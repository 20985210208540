import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Chip,
  ChipProps,
  LinkProps,
  List,
  ListItem,
  ListItemProps,
  ListProps,
} from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledFreeButton = styled(Button)<ButtonProps>({
  padding: '10px 20px',
  maxWidth: '150px',
});

export const StyledPricingBox = styled(Box)<BoxProps>({
  height: '300px',
  backgroundColor: 'black',
  borderRadius: '12px',
  background: 'radial-gradient(circle at bottom, rgba(31, 81, 125, 0.7) 0%, rgba(0, 0, 0, 0) 35%), black',
});

export const StyledPopularChip = styled(Chip)<ChipProps>({
  backgroundColor: deepPurple[500],
  color: 'white',
});

export const StyledBlackButton = styled(Button)<ButtonProps & LinkProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.black,
  color: theme.colors.white,
  '&:hover': {
    backgroundColor: theme.colors.secondary.dark,
  },
}));

export const StyledColoredBox = styled(Box)<BoxProps>({
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  minWidth: '280px',
});

export const StyledChip = styled(Chip)<ChipProps>({
  width: '170px',
});

export const StyledList = styled(List)<ListProps>({
  padding: '0',
});

export const StyledListItem = styled(ListItem)<ListItemProps>({
  padding: '4px 0',
});

export const StyledAreaBox = styled(Box)<BoxProps & { theme?: Theme; active?: boolean; maxWidth?: string }>(
  ({ theme, active = false, maxWidth }) => ({
    maxWidth: maxWidth || '580px',
    borderRadius: '12px',
    border: `1px solid ${active ? theme.colors.primary.main : theme.colors.background.disabledButton}`,
  }),
);
