import { NOT_AVAILABLE } from '@constants/common';

export const formatCurrency = (value: number | string, currency: string = 'USD', numberOfDecimalPlaces: number = 2) => {
  if (!value) return NOT_AVAILABLE;

  return Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
    minimumFractionDigits: numberOfDecimalPlaces,
    maximumFractionDigits: numberOfDecimalPlaces,
  }).format(+value);
};
