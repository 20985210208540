/* eslint-disable no-param-reassign */
import { TokensDto } from '@api/api';
import { invalidateAllTags } from '@api/baseApi';
import { USER_ACCESS_TOKEN } from '@constants/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tokenStorage } from '@utils/tokenStorage';

interface AuthState {
  isAuthenticated: boolean;
  forgotPasswordEmail: string;
  isConfirmed: boolean | null;
  authResponse?: TokensDto | null;
}

const initialState: AuthState = {
  isAuthenticated: !!tokenStorage.getToken(USER_ACCESS_TOKEN),
  forgotPasswordEmail: '',
  isConfirmed: null,
  authResponse: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state: AuthState, action: PayloadAction<TokensDto>) {
      invalidateAllTags();
      state.authResponse = action.payload;
      state.isAuthenticated = true;
      state.isConfirmed = null;
    },
    logoutSuccess(state: AuthState) {
      state.authResponse = null;
      state.isAuthenticated = false;
      state.isConfirmed = null;
    },
    provideForgotPasswordEmail(state: AuthState, action: PayloadAction<string>) {
      state.forgotPasswordEmail = action.payload;
    },
    clearForgotPasswordEmail(state: AuthState) {
      state.forgotPasswordEmail = '';
    },
    confirmSuccess(state: AuthState) {
      state.isConfirmed = true;
    },
    clearConfirm(state: AuthState) {
      state.isConfirmed = false;
    },
  },
});

export const {
  loginSuccess,
  logoutSuccess,
  provideForgotPasswordEmail,
  clearForgotPasswordEmail,
  confirmSuccess,
  clearConfirm,
} = authSlice.actions;
export default authSlice.reducer;
