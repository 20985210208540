export enum Indicators {
  PROFILE = 'PROFILE',
  BILLING = 'BILLING',
  TRANSACTIONS = 'TRANSACTIONS',
}

export enum ProfileDataFieldsNames {
  firstname = 'firstname',
  lastname = 'lastname',
  email = 'email',
  phone = 'phone',
  oldPassword = 'oldPassword',
  newPassword = 'newPassword',
  confirmNewPassword = 'confirmNewPassword',
}
