import { MAX_GOODS_PRICE, MAX_PROPERTY_PRICE, MAX_SQFT_VALUE } from '@constants/common';

export const JobtypeOptions = [
  { label: 'Full-time', value: 'Full-time' },
  { label: 'Part-time', value: 'Part-time' },
];

export const JobshiftOptions = [
  { label: 'Weekends', value: 'weekends' },
  { label: 'Evening', value: 'evening' },
  { label: 'Day', value: 'day' },
  { label: 'Night', value: 'night' },
];

export const PropertyTypeOptions = [
  { label: 'Single family', value: 'single_family' }, // house
  { label: 'Multi family', value: 'multi_family' },
  { label: 'Townhomes', value: 'townhomes' },
  { label: 'Condos', value: 'condos' },
  { label: 'Farm', value: 'farm' },
  { label: 'Land', value: 'land' },
  { label: 'Mobile', value: 'mobile' },
];

export const PropertyStatusOptions = [
  { label: 'For sale', value: 'for_sale' },
  { label: 'Ready to build', value: 'ready_to_build' },
];

export const PropertyOptions = [100_000, 300_000, 500_000, 1_000_000, 5_000_000, 10_000_000, MAX_PROPERTY_PRICE].map(
  v => String(v),
);

export const GoodsOptions = [
  50,
  100,
  500,
  1000,
  2000,
  5000,
  10000,
  20000,
  50000,
  100000,
  200000,
  300000,
  500000,
  MAX_GOODS_PRICE,
].map(v => String(v));

export const SqftOptions = [
  500,
  750,
  1000,
  1250,
  1500,
  1750,
  2000,
  2250,
  2500,
  2750,
  3000,
  3250,
  3500,
  3750,
  5000,
  7500,
  MAX_SQFT_VALUE,
].map(v => String(v));
