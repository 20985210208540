import { useState } from 'react';
import { ExportCampaignLeadsDto } from '@api/api';
import { ERROR, USER_ACCESS_TOKEN } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { formattedDateTime } from '@utils/formatTime';
import { getErrorMessage } from '@utils/getMessage';
import { tokenStorage } from '@utils/tokenStorage';
import { useSnackbar } from 'notistack';

const baseUrl = import.meta.env.VITE_APP_API_URL;

const useDownloadCsv = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const snackbar = useSnackbar();
  const token = tokenStorage.getToken(USER_ACCESS_TOKEN);

  const downloadCsv = async (payload: ExportCampaignLeadsDto) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${baseUrl}/export/export_csv`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network error');
      }
      const withCampaignId = payload.campaignId;

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = withCampaignId ? `leads_${payload.campaignId}.csv` : `my_leads_${formattedDateTime(new Date())}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(err as any);
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    } finally {
      setLoading(false);
    }
  };

  return { downloadCsv, isLoading: loading, isError: error };
};

export default useDownloadCsv;
