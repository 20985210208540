import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCampaignTypes } from '@hooks/api/useCampaignTypes';
import { useLeadScraperContext } from '@pages/LeadScraper/context';

import { Box, Typography } from '@mui/material';

import NoDetailsMessage from './NoDetailsMessage';
import { StyledAreaBox } from './styled';

const BasicInformationSummary: FC = () => {
  const { t } = useTranslation();
  const { basicInfoForm } = useLeadScraperContext();
  const formData = basicInfoForm.watch();
  const isFormChanged =
    Object.keys(formData ?? {}).length > 0 &&
    Object.values(formData ?? {})
      .filter(Boolean)
      .some((val: unknown) => Boolean(String(val).trim()));
  const { data: campaignTypes } = useCampaignTypes();

  return (
    <>
      {isFormChanged ? (
        <StyledAreaBox>
          <Typography variant="subtitle2">{t('dashboard.newScraper.settings.basicInfoTitle')}</Typography>
          <Box display="flex" flexWrap="wrap">
            {formData.type && (
              <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
                <Typography variant="caption" color="text.secondary">
                  {t('dashboard.newScraper.settings.scraperTypeLabel')}
                </Typography>
                <Typography variant="body2">
                  {campaignTypes?.find(type => +type.campaign_type_id === +formData.type)?.type_name}
                </Typography>
              </Box>
            )}

            {formData.campaignName && (
              <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
                <Typography variant="caption" color="text.secondary">
                  {t('dashboard.newScraper.settings.scraperNameLabel')}
                </Typography>
                <Typography variant="body2">{formData.campaignName}</Typography>
              </Box>
            )}

            {formData.campaignDescription && (
              <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
                <Typography variant="caption" color="text.secondary">
                  {t('dashboard.newScraper.settings.scraperDescription')}
                </Typography>
                <Typography variant="body2">{formData.campaignDescription}</Typography>
              </Box>
            )}
          </Box>
        </StyledAreaBox>
      ) : (
        <NoDetailsMessage />
      )}
    </>
  );
};

export default memo(BasicInformationSummary);
