import { Theme } from '@mui/material/styles';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsVariants } from '@mui/material/styles/variants';

export const MuiButton: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
} = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.button,
      borderRadius: '12px',
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
    }),
  },
  variants: [
    /* CONTAINED */
    {
      props: {
        variant: 'contained',
      },
      style: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    {
      props: {
        variant: 'contained',
        color: 'primary',
      },
      style: ({ theme }) => ({
        '&:hover': {
          // @ts-ignore
          backgroundColor: theme.colors.primary.dark,
        },
        '&:active': {
          // @ts-ignore
          backgroundColor: theme.colors.primary.light,
          boxShadow: 'none',
        },
      }),
    },
    {
      props: {
        variant: 'contained',
        disabled: true,
        color: 'primary',
      },
      style: ({ theme }) => ({
        '&.Mui-disabled': {
          // @ts-ignore
          backgroundColor: theme.colors.action.disabledBackground,
          boxShadow: 'none',
          // @ts-ignore
          color: theme.colors.action.disabled,
        },
      }),
    },
    {
      props: {
        variant: 'contained',
        color: 'secondary',
      },
      style: ({ theme }) => ({
        '&:hover': {
          // @ts-ignore
          backgroundColor: theme.colors.secondary.main,
        },
        '&:active': {
          // @ts-ignore
          backgroundColor: theme.colors.secondary.dark,
        },
      }),
    },
    {
      props: {
        variant: 'contained',
        disabled: true,
        color: 'secondary',
      },
      style: ({ theme }) => ({
        '&.Mui-disabled': {
          // @ts-ignore
          backgroundColor: theme.colors.action.disabledBackground,
          boxShadow: 'none',
          // @ts-ignore
          color: theme.colors.action.disabled,
        },
      }),
    },

    /* OUTLINED */
    {
      props: {
        variant: 'outlined',
      },
      style: ({ theme }) => ({
        // @ts-ignore
        ...theme.typography.button,
        outline: '1px solid',
        borderRadius: '12px',
      }),
    },
    {
      props: {
        variant: 'outlined',
        color: 'primary',
      },
      style: ({ theme }) => ({
        '&:hover': {
          // @ts-ignore
          backgroundColor: theme.colors.primary.main,
          // @ts-ignore
          color: theme.colors.white,
        },
        '&:active': {
          outlineColor: 'transparent',
          // @ts-ignore
          borderColor: theme.colors.primary.dark,
          // @ts-ignore
          backgroundColor: theme.colors.white,
        },
      }),
    },
    {
      props: {
        variant: 'outlined',
        disabled: true,
        color: 'primary',
      },
      style: ({ theme }) => ({
        '&.Mui-disabled': {
          // @ts-ignore
          borderColor: theme.colors.action.focus,
          // @ts-ignore
          backgroundColor: theme.colors.white,
          // @ts-ignore
          color: theme.colors.action.disabled,
        },
      }),
    },
    {
      props: {
        variant: 'outlined',
        color: 'secondary',
      },
      style: ({ theme }) => ({
        // @ts-ignore
        outlineColor: theme.colors.secondary.outline,
        '&:hover': {
          // @ts-ignore
          backgroundColor: theme.colors.secondary.background,
          // @ts-ignore
          color: theme.colors.secondary.main,
        },
        '&:active': {
          outlineColor: 'transparent',
          // @ts-ignore
          borderColor: theme.colors.action.selected,
          // @ts-ignore
          backgroundColor: theme.colors.secondary.background,
        },
      }),
    },
    {
      props: {
        variant: 'outlined',
        color: 'secondary',
        disabled: true,
      },
      style: ({ theme }) => ({
        '&.Mui-disabled': {
          // @ts-ignore
          outlineColor: theme.colors.secondary.border,
          // @ts-ignore
          backgroundColor: theme.colors.secondary.background,
          // @ts-ignore
          color: theme.colors.secondary.button,
        },
      }),
    },
  ],
};
