import { Box, BoxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minWidth: '500px',
  },
}));

export const StyledWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
