import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTransactionsQuery } from '@api/api';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';
import TransactionsList from '@pages/Settings/components/TransactionsTab/TransactionsList';

import { Box, Typography } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';

import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const TransactionsTab: FC = () => {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenSizeContext();
  const { page, limit, setPage, setLimit } = usePageFilterUrlParams();

  const { data: transactionsData, isLoading: isLoadingTransactions } = useGetTransactionsQuery({
    page,
    limit,
  });

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  return (
    <Box display="flex" flexDirection="column" gap={3} height="100%" px={isLargerScreen ? 2 : 1}>
      <Typography variant="h5" my={3}>
        {t('common.titles.transactions')}
      </Typography>
      {isLargerScreen && (
        <TransactionsList
          transactions={transactionsData}
          handlePageChange={handlePaginationModelChange}
          paginationModel={{ page, pageSize: limit }}
          isLoading={isLoadingTransactions}
        />
      )}
    </Box>
  );
};

export default memo(TransactionsTab);
