import { Button, ButtonProps, List, ListProps, Typography, TypographyProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledList = styled(List)<ListProps>({
  position: 'sticky',
  top: 0,
});

export const StyledOkButton = styled(Button)<ButtonProps>({
  marginTop: '16px',
});

export const StyledData = styled(Typography)<TypographyProps & { theme?: Theme }>(({ theme }) => ({
  color: theme.colors.secondary.button,
}));
