import { FC, Fragment, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { daysOfWeek } from '@pages/LeadScraper/utils';

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

const DaysOfTheWeek: FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" color="text.secondary">
        {t('dashboard.newScraper.scheduling.days.title')}
      </Typography>
      <Box>
        {daysOfWeek.map((day, index) => (
          <Fragment key={day}>
            <Controller
              control={control}
              name={`days.${index}.${day}`}
              render={({ field }) => (
                <FormControlLabel
                  value={field.value}
                  label={day}
                  labelPlacement="end"
                  control={<Checkbox {...field} checked={!!field.value} />}
                />
              )}
            />
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default memo(DaysOfTheWeek);
