import CircleIcon from '@mui/icons-material/Circle';
import { Box, BoxProps, Button, ButtonGroup, ButtonGroupProps, ButtonProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export const TokensButton = styled(Button)<ButtonProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.background.disabledButton,
  color: theme.colors.black,
  fontWeight: 600,
  fontSize: '14px',
  padding: '10px',
  pointerEvents: 'none',
}));

export const StyledButtonGroup = styled(ButtonGroup)<ButtonGroupProps & { component?: typeof ButtonGroup }>(() => ({
  boxShadow: 'none',
  position: 'relative',
  '& .MuiButtonGroup-firstButton': {
    width: '130px',
  },
}));

export const StyledSubtitleBox = styled(Box)<BoxProps>(() => ({
  backgroundColor: grey[100],
  borderRadius: '12px',
}));

export const StyledCircleIcon = styled(CircleIcon)<{ theme?: Theme }>(({ theme }) => ({
  fill: theme.colors.primary.main,
  height: '.5em',
  width: '.5em',
}));

export const StyledSelectableButtonGroup = styled(ButtonGroup)<ButtonGroupProps>(() => ({
  borderRadius: '12px',
}));

export const StyledSelectableButton = styled(Button)<ButtonProps & { theme?: Theme; active?: boolean }>(
  ({ theme, active }) => ({
    color: active ? theme.colors.white : theme.colors.black,
    backgroundColor: active ? theme.colors.primary.main : theme.colors.secondary.border,
  }),
);
