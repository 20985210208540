import styled from '@mui/system/styled';

const Logo = styled(props => (
  <svg width="157" height="18" viewBox="0 0 157 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.04 8.48V1.688L3.824 0.079999H9.896L12.68 1.688V4.88H9.32V2.96H4.4V6.968H9.896L12.68 8.576V15.512L9.896 17.12H3.584L0.8 15.512V12.32H4.16V14.24H9.32V10.088H3.824L1.04 8.48ZM26.7666 12.08H30.1266V15.512L27.3426 17.12H20.5506L17.7666 15.512V1.688L20.5506 0.079999H27.3426L30.1266 1.688V5.12H26.7666V2.96H21.1266V14.24H26.7666V12.08ZM47.7059 17H44.0099L40.4099 10.664H38.3219V17H34.9619V0.199999H44.0579L46.8419 1.808V9.056L44.0579 10.664L47.7059 17ZM38.3219 3.08V7.784H43.4819V3.08H38.3219ZM59.6988 0.199999L65.6988 17H62.3868L61.1148 13.376H54.8268L53.5548 17H50.2428L56.2428 0.199999H59.6988ZM57.9468 4.424L55.8348 10.496H60.0828L57.9948 4.424H57.9468ZM69.0947 17V0.199999H77.9507L80.7347 1.808V9.296L77.9507 10.904H72.4547V17H69.0947ZM72.4547 3.08V8.024H77.3747V3.08H72.4547ZM88.4547 9.8V14.12H95.1507V17H85.0947V0.199999H95.1507V3.08H88.4547V6.92H93.7107V9.8H88.4547ZM99.2666 17V0.199999H102.627V7.76H102.675L108.939 0.199999H112.923L106.299 8.072L113.667 17H109.635L104.283 10.472L102.627 12.416V17H99.2666ZM116.837 17V0.199999H120.197V17H116.837ZM128.627 6.128V17H125.267V0.199999H128.411L135.659 11.072H135.707V0.199999H139.067V17H135.923L128.675 6.128H128.627ZM150.149 11.12V8.24H156.269V15.512L153.485 17.12H146.693L143.909 15.512V1.688L146.693 0.079999H153.485L156.269 1.688V5.12H152.909V2.96H147.269V14.24H152.909V11.12H150.149Z"
      fill="#212121"
    />
  </svg>
))({});

export default Logo;
