import { FC, memo } from 'react';
import { useTabs } from '@hooks/common/useTabs';
import { usePricingContext } from '@pages/Pricing/context';

import { Box } from '@mui/material';
import Toggle from '@components/ToggleButtonGroup';

import { Indicators } from '../enums';

import BillingTab from './BillingTab/BillingTab';
import ProfileTab from './ProfileTab/ProfileTab';
import { StyledAreaBox } from './styled';
import TransactionsTab from './TransactionsTab';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const ActiveTab: FC = () => {
  const { tab } = useTabs(Indicators.PROFILE, Indicators);
  const { period, setPeriod } = usePricingContext();
  const { isLargerScreen } = useScreenSizeContext();

  return (
    <>
      {tab === Indicators.BILLING && (
        <Box display="flex" justifyContent="center" mb={2}>
          <Toggle active={period} setActive={setPeriod} isMobile={!isLargerScreen} />
        </Box>
      )}
      <StyledAreaBox
        display="flex"
        flexDirection="column"
        p={isLargerScreen ? 3 : 2}
        mt={1}
        minWidth={isLargerScreen ? '620px' : '360px'}
      >
        {tab === Indicators.PROFILE ? <ProfileTab /> : null}
        {tab === Indicators.BILLING ? <BillingTab /> : null}
        {tab === Indicators.TRANSACTIONS ? <TransactionsTab /> : null}
      </StyledAreaBox>
    </>
  );
};

export default memo(ActiveTab);
