import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useUser } from '@hooks/api/useUser';
import ActiveTab from '@pages/LeadsDatabase/components/ActiveTab';
import ToggleTabs from '@pages/LeadsDatabase/components/ToggleTabs';
import { LeadsDatabaseProvider } from '@pages/LeadsDatabase/context';
import AppLayout from '@providers/layouts/AppLayout/AppLayout';

import { Box, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';

import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const LeadsDatabase: FC = () => {
  const { isLoading } = useUser();
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenSizeContext();

  if (isLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.leadsDatabase')}</title>
      </Helmet>
      <LeadsDatabaseProvider>
        <AppLayout showSidebar={isLargerScreen}>
          <Box
            px={3}
            pt={3}
            display="flex"
            flexDirection="column"
            gap={3}
            minWidth={isLargerScreen ? '800px' : '360px'}
          >
            <Typography variant="h6">{t('dashboard.navigation.database')}</Typography>
            <ToggleTabs />
            <ActiveTab />
          </Box>
        </AppLayout>
      </LeadsDatabaseProvider>
    </>
  );
};

export default memo(LeadsDatabase);
