import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CreateCampaignDto, useCreateCampaignMutation, useGetBalanceQuery } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH, CAMPAIGN_ID, TOKENS } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { useCampaignFilters } from '@hooks/api/useCampaignFilters';
import { useGetSearchParam } from '@hooks/common/useGetSearchParams';
import { useLeadScraperContext } from '@pages/LeadScraper/context';
import { ScraperData } from '@pages/LeadScraper/types';
import { isFormFilled, provideSchedulingData } from '@pages/LeadScraper/utils';
import { calcPaidFiltersPrice, calculateTotalPrice } from '@pages/LeadScraper/utils/calculatePrice';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Divider, Typography } from '@mui/material';

import BasicInformationSummary from './components/BasicInformationSummary';
import FiltersSummary from './components/FiltersSummary';
import SchedulingSummary from './components/SchedulingSummary';
import ScraperSettingsSummary from './components/ScraperSettingsSummary';
import { StyledBox, StyledBoxContainer, StyledConfirmBox } from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const ScraperSummary: FC = () => {
  const { t } = useTranslation();
  const {
    sourceSettingsForm,
    basicInfoForm,
    filtersForm,
    submitButtonLocale,
    isEdit,
    setMobileShowSettings,
    mobileShowSettings,
    schedulingForm,
  } = useLeadScraperContext();
  const { autocomplete }: { autocomplete: ScraperData[] } = sourceSettingsForm.watch();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const campaignId = useGetSearchParam(CAMPAIGN_ID);
  const [createCampaignMutation, { isLoading: isLoadingCreateCampaign }] = useCreateCampaignMutation();

  const { data: filtersData } = useCampaignFilters(campaignId as string);
  const { data: balanceData } = useGetBalanceQuery();
  const { isLargerScreen } = useScreenSizeContext();

  const formData = filtersForm.watch();
  const isFiltersFormChanged = isFormFilled(formData?.filters?.paidFilters);

  const allPaidFiltersSelected = Object.keys(formData?.filters?.paidFilters ?? {});

  const totalNumberOfLeads = autocomplete?.reduce((acc, val) => acc + Number(val?.filters?.numberOfLeads ?? '0'), 0);

  const paidFiltersPrice = isFiltersFormChanged
    ? calcPaidFiltersPrice(filtersData, allPaidFiltersSelected) * (totalNumberOfLeads > 0 ? totalNumberOfLeads : 1)
    : 0;

  const totalPrice = Number(calculateTotalPrice(autocomplete, paidFiltersPrice));
  const pricePerLead = totalNumberOfLeads > 0 ? (totalPrice / totalNumberOfLeads).toFixed(2) : 0;

  const isValid =
    autocomplete?.length > 0 &&
    sourceSettingsForm.formState.isValid &&
    basicInfoForm.formState.isValid &&
    filtersForm.formState.isValid;

  const accountBallance = balanceData?.token || 0;

  const handleSubmitCampaignData = async () => {
    try {
      const creationDate = new Date().toISOString();

      const createCampaignPayload: CreateCampaignDto = {
        campaign_id: +campaignId!,
        campaign_start: creationDate,
        status: 1, // enabled by default
        name: basicInfoForm.getValues().campaignName,
        description: basicInfoForm.getValues().campaignDescription,
        scrapers: autocomplete.map(v => {
          const { numberOfLeads, ...restFilters } = v.filters;

          return {
            scraper_id: v.scrapers_id,
            leads_count: +numberOfLeads,
            filters: Object.entries(restFilters)
              .filter(([, value]) => !!value)
              .map(([key, value]) => {
                const { campaign_filters_id: filterId } = filtersData.find(
                  f => f.key.toLowerCase() === key.toLowerCase(),
                )!;

                return {
                  id: filterId,
                  name: key,
                  value: value!,
                };
              }),
          };
        }),
        paid_filters: Object.entries(filtersForm?.getValues()?.filters?.paidFilters ?? {})
          .filter(([, value]) => !!value)
          .map(([key, value]) => {
            const { campaign_filters_id: filterId } = filtersData.find(f => f.key.toLowerCase() === key.toLowerCase())!;

            return {
              id: filterId,
              name: key,
              value: value!,
            };
          }),
        scheduling: provideSchedulingData(schedulingForm.getValues()),
      };

      const messageText = isEdit ? 'common.campaignUpdated' : 'common.campaignCreated';

      if (!isEdit) {
        await createCampaignMutation({
          createCampaignDto: createCampaignPayload,
        });
      }

      snackbar.enqueueSnackbar(t(messageText));

      navigate(ROUTING.ROOT);
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  if (mobileShowSettings && !isLargerScreen) return null;

  return (
    <StyledBoxContainer
      maxWidth="550px"
      minWidth={isLargerScreen ? '444px' : '390px'}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" flexDirection="column" p={3} height="100px" justifyContent="center">
        <Typography variant="subtitle1">{t('dashboard.newScraper.summary.title')}</Typography>
        <Typography variant="body2">{t('dashboard.newScraper.summary.subtitle')}</Typography>
      </Box>
      <Divider />

      <StyledBox display="flex" flexDirection="column" gap={isLargerScreen ? 2 : 1} height="100%">
        <BasicInformationSummary />
        <ScraperSettingsSummary />
        <FiltersSummary
          filtersData={filtersData}
          isFormChanged={isFiltersFormChanged}
          selectedFilters={allPaidFiltersSelected}
          formData={formData}
          totalNumberOfLeads={totalNumberOfLeads}
        />
        <SchedulingSummary />
        {!isLargerScreen && (
          <Button variant="contained" color="secondary" size="large" onClick={() => setMobileShowSettings(true)}>
            EDIT Scraper
          </Button>
        )}
      </StyledBox>

      <StyledConfirmBox p={2} height="120px">
        <Box display="flex" justifyContent="space-between" p={1}>
          <Typography variant="caption">
            {pricePerLead} {TOKENS} / {t('dashboard.newScraper.leadLabel')}
          </Typography>
          <Typography variant="subtitle2" color={accountBallance < totalPrice ? 'red' : 'currentColor'}>
            {t('dashboard.newScraper.summary.total')}: {totalPrice} {TOKENS}
          </Typography>
        </Box>
        <LoadingButton
          variant="contained"
          fullWidth
          disabled={!isValid || accountBallance < totalPrice}
          onClick={handleSubmitCampaignData}
          loading={isLoadingCreateCampaign}
        >
          {t(submitButtonLocale)}
        </LoadingButton>

        <Typography variant="body2" mt={1} color="red" height="20px" aria-label="not-enough-tokens-validation-text">
          {accountBallance < totalPrice && t('billing.scraper.notEnoughTokens')}
        </Typography>
      </StyledConfirmBox>
    </StyledBoxContainer>
  );
};

export default memo(ScraperSummary);
