import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '@api/api';
import { logoutSuccess } from '@store/authSlice';
import { tokenStorage } from '@utils/tokenStorage';

export const useLogout = () => {
  const dispatch = useDispatch();

  const logout = useCallback(() => {
    tokenStorage.cleanup();
    dispatch(logoutSuccess());
    dispatch(api.util.resetApiState());
  }, [dispatch]);

  return {
    logout,
  };
};
