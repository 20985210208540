import { LinkProps } from 'react-router-dom';
import { skipForwardProp } from '@utils/styled';

import {
  StepConnector,
  stepConnectorClasses,
  StepConnectorProps,
  StepContent,
  StepContentProps,
  StepLabel,
  StepLabelProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledStepConnector = styled(StepConnector)<StepConnectorProps & { theme?: Theme }>(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeft: `2px solid ${theme.colors.primary.main}`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeft: `2px solid ${theme.colors.primary.main}`,
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderLeft: `2px solid ${theme.colors.background.disabledButton}`,
    },
  },
}));

export const StyledStepLabel = styled(StepLabel, { shouldForwardProp: skipForwardProp(['isActive', 'isMobile']) })<
  StepLabelProps & { theme?: Theme; isActive: boolean; isMobile?: boolean }
>(({ theme, isActive, isMobile }) => ({
  padding: '0',
  marginLeft: '2.5px',
  marginBottom: isMobile ? '8px' : '0',
  marginTop: isMobile ? '8px' : '0',
  '&.MuiStepLabel-root .MuiStepLabel-iconContainer': {
    display: isMobile ? 'none' : 'block',
  },
  '&.MuiStepLabel-root .MuiStepLabel-labelContainer': {
    marginLeft: isMobile ? '-70px' : '-100px',
    '& .MuiStepLabel-label': {
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'Inter',
      color: isActive ? theme.colors.primary.main : theme.colors.secondary.outline,
    },
  },
}));

export const StyledStepContent = styled(StepContent, {
  shouldForwardProp: skipForwardProp(['active', 'isLast', 'isMobile']),
})<StepContentProps & { theme?: Theme; isActive: boolean; isLast?: boolean; isMobile?: boolean }>(
  ({ theme, isActive, isLast, isMobile }) => ({
    marginLeft: isMobile ? '-100px' : '12px',
    borderLeft: `2px solid ${isLast ? 'none' : isActive ? theme.colors.primary.main : theme.colors.background.disabledButton}`,
  }),
);

export const StyledLink = styled(Typography)<TypographyProps & LinkProps>({
  textDecoration: 'none',
});
