import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';

import EmailIcon from '@mui/icons-material/Email';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import { Box, Collapse } from '@mui/material';
import { CollapseMenu, ExpandMenu } from '@components/icons';
import { theme } from '@components/theme';

import {
  StyledIconButton,
  StyledList,
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';
// eslint-disable-next-line import/extensions
import { useProtectedRoutesContext } from '@/shared/ProtectedRoutesContext';

type NavigationRoutes = '/' | 'database' | 'campaigns' | 'accounts';

const linksList = [
  { label: 'dashboard.navigation.scrapers', icon: <SearchIcon />, route: ROUTING.ROOT },
  { label: 'dashboard.navigation.database', icon: <FolderSharedIcon />, route: `/${ROUTING.LEADS_DATABASE}` },
  { label: 'dashboard.navigation.campaigns', icon: <SendIcon />, route: `/${ROUTING.EMAIL_CAMPAIGNS}`, disabled: true },
  { label: 'dashboard.navigation.accounts', icon: <EmailIcon />, route: `/${ROUTING.EMAIL_ACCOUNTS}`, disabled: true },
];

const Navigation: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { expandedMenu, toggleMenu } = useProtectedRoutesContext();
  const { isLargerScreen } = useScreenSizeContext();

  const handleClick = (route: NavigationRoutes) => {
    navigate(route);
    if (!isLargerScreen) {
      toggleMenu();
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      borderRight={`1px solid ${theme.colors.action.disabledBackground}`}
      alignItems="start"
    >
      <Box minWidth="80px">
        <StyledList>
          {linksList.map(({ label, icon, route, disabled }) => (
            <StyledListItem key={label} disablePadding activePage={pathname} route={route}>
              <StyledListItemButton
                disableRipple
                disabled={disabled}
                onClick={() => handleClick(route as NavigationRoutes)}
                expanded={expandedMenu}
              >
                <StyledListItemIcon activePage={pathname} route={route}>
                  {icon}
                </StyledListItemIcon>
                <Collapse in={expandedMenu}>
                  <StyledListItemText primary={t(label)} expanded={expandedMenu} />
                </Collapse>
              </StyledListItemButton>
            </StyledListItem>
          ))}
        </StyledList>
      </Box>
      {isLargerScreen && (
        <StyledIconButton onClick={toggleMenu} disableRipple>
          {expandedMenu ? <CollapseMenu /> : <ExpandMenu />}
        </StyledIconButton>
      )}
    </Box>
  );
};

export default memo(Navigation);
