import { Dispatch, FC, memo, MouseEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { User } from '@api/api';
import { SUCCESS } from '@constants/auth';
import { PERIOD } from '@constants/billing';
import { MONTHS_IN_YEAR } from '@constants/common';
import { ROUTING } from '@constants/routing';
import NiceModal from '@ebay/nice-modal-react';
import { useMenuActions } from '@hooks/common/useMenuActions';
import { GetPackagesResponse, GetPricesResponse, GetSubscriptionsResponse, Package } from '@pages/Settings/types';
import { convertFromCents } from '@pages/Settings/utils';
import { formatCurrency } from '@utils/formatCurrency';
import { useSnackbar } from 'notistack';
import { CountUp } from 'use-count-up';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Chip, Typography } from '@mui/material';
import { BillingConfirmModalId } from '@components/BillingConfirmModal';

import {
  StyledButtonGroup,
  StyledCircleIcon,
  StyledSelectableButton,
  StyledSelectableButtonGroup,
  StyledSubtitleBox,
  TokensButton,
} from './styled';
import { StyledMenu, StyledMenuItemSelectableText } from '@/shared/styled';
import { PeriodValue } from '@/shared/types';

const AddTokensButton: FC<{
  packagesData: unknown;
  subscriptionData: unknown;
  pricesData: unknown;
  userData?: User;
  tokensAmount?: number;
  handleCheckout: (priceId: string) => Promise<void>;
  handleTopup: (priceId: string) => Promise<void>;
  isDisabledAction: boolean;
  period: PeriodValue;
  setPeriod: Dispatch<SetStateAction<PeriodValue>>;
  isLargerScreen: boolean;
}> = ({
  packagesData,
  pricesData,
  userData,
  handleCheckout,
  handleTopup,
  subscriptionData,
  isDisabledAction,
  tokensAmount = 0,
  period = PERIOD.month,
  setPeriod,
  isLargerScreen,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<null | Package>(null);
  const { t } = useTranslation();
  const { anchorEl, open, handleOpen, handleClose } = useMenuActions();
  const navigate = useNavigate();
  const tokensBalance = useRef(tokensAmount);
  const snackbar = useSnackbar();

  const sortedPackages = (packagesData as GetPackagesResponse)?.data
    .filter(pkg =>
      (pricesData as GetPricesResponse)?.data?.some(
        price => price.id === pkg.default_price && price.recurring.interval === period,
      ),
    )
    .sort((a, b) => +a.metadata.tokens - +b.metadata.tokens);

  const activePackage = sortedPackages?.find(p => {
    const basicPackage = p.default_price === (subscriptionData as GetSubscriptionsResponse)?.plan.id;
    const customPackage = p.default_price === (subscriptionData as GetSubscriptionsResponse)?.metadata.original_id;

    if (basicPackage) return basicPackage;
    if (customPackage) return customPackage;

    return undefined;
  });

  const selectedPackage = sortedPackages
    ?.filter(pkg =>
      (pricesData as GetPricesResponse)?.data?.some(
        price => price.id === pkg.default_price && price.recurring.interval === period,
      ),
    )
    .find(p => p.id === selectedPlan?.id);

  const packageDescription = selectedPackage?.description
    .split(';')
    .map(desc => desc.trim())
    .filter(desc => desc);

  const selectedPriceObj = (pricesData as GetPricesResponse)?.data?.find(
    price => price.id === selectedPackage?.default_price,
  );

  const convertedPrice =
    selectedPriceObj &&
    formatCurrency(
      convertFromCents(
        selectedPriceObj.recurring.interval === PERIOD.month
          ? +selectedPriceObj.unit_amount_decimal
          : +selectedPriceObj.unit_amount_decimal / MONTHS_IN_YEAR,
      ),
    );

  const packagePrice = `for ${selectedPriceObj ? convertedPrice : formatCurrency(0)}`;

  const activePackagePrice = (pricesData as GetPricesResponse)?.data?.find(
    price => price?.id === activePackage?.default_price,
  )?.unit_amount_decimal;

  const buttonText =
    activePackage && selectedPackage?.id === activePackage.id
      ? `${t('billing.other.topUpText')} ${packagePrice}`
      : selectedPriceObj?.unit_amount_decimal &&
          activePackagePrice &&
          Number(selectedPriceObj?.unit_amount_decimal) > Number(activePackagePrice)
        ? `${t('billing.other.upgradePlan')} ${packagePrice}`
        : `${t('billing.other.downgradePlan')} ${packagePrice}`;

  const selectedPackageTokens = selectedPackage?.metadata.tokens;

  const isCurrentPackage = activePackage && selectedPackage?.id === activePackage.id;

  const handlePayment = async () => {
    if (activePackage && selectedPackage?.id === activePackage.id && selectedPriceObj) {
      const result = await NiceModal.show(BillingConfirmModalId, {
        price: +selectedPriceObj.unit_amount_decimal,
        tokens: selectedPackageTokens,
        description: packageDescription,
        period,
      });
      if (result) {
        await handleTopup(selectedPriceObj.id);
        selectedPackageTokens &&
          snackbar.enqueueSnackbar(t('dashboard.tokens.topup', { tokens: +selectedPackageTokens }), {
            variant: SUCCESS,
          });
        return;
      }
      return;
    }

    const priceId = selectedPackage?.default_price;
    const result = await NiceModal.show(BillingConfirmModalId, {
      price: selectedPriceObj && +selectedPriceObj.unit_amount_decimal,
      tokens: selectedPackageTokens,
      description: packageDescription,
      isChangePlan: true,
      period,
    });
    if (result) {
      return handleCheckout(priceId as string);
    }
  };

  const handleSelectActivePackage = () =>
    activePackage ? setSelectedPlan(activePackage) : setSelectedPlan(sortedPackages[0]);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (!userData?.subscriptions?.[0].subscription_id) {
      return navigate(`/${ROUTING.PRICING}`);
    }
    if (open) {
      return handleClose();
    }
    handleOpen(e);
    handleSelectActivePackage();
  };

  useEffect(() => {
    if (tokensAmount) {
      tokensBalance.current = tokensAmount;
    }
  }, [tokensAmount]);

  useEffect(() => {
    if (!selectedPriceObj && period === PERIOD.month) {
      return setPeriod(PERIOD.year);
    }
  }, []);

  return (
    <>
      <StyledButtonGroup variant="contained" aria-label="Button group that shows tokens amount">
        <TokensButton aria-label="amount of tokens" disableRipple disableElevation>
          <Typography variant="subtitle2">
            <CountUp
              isCounting
              key={tokensAmount}
              start={tokensBalance.current}
              end={tokensAmount}
              duration={3}
              easing="easeInCubic"
              updateInterval={0}
            />
            {` ${t('common.tokens')}`}
          </Typography>
        </TokensButton>
        <Button size="small" aria-label="add more tokens" onClick={handleClick} disabled={!pricesData}>
          <AddIcon />
        </Button>
      </StyledButtonGroup>

      <StyledMenu
        width="500px"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={8}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItemSelectableText disableRipple>
          <Box display="flex" justifyContent="stretch" width="100%" py={2}>
            {sortedPackages && (
              <StyledSelectableButtonGroup
                fullWidth
                color="primary"
                orientation={isLargerScreen ? 'horizontal' : 'vertical'}
                variant="contained"
                disabled={isDisabledAction}
              >
                {sortedPackages.map((p, index) => (
                  <StyledSelectableButton
                    key={p.id}
                    active={selectedPlan?.id === sortedPackages[index].id}
                    onClick={() => setSelectedPlan(sortedPackages[index])}
                  >
                    {p.name}
                  </StyledSelectableButton>
                ))}
              </StyledSelectableButtonGroup>
            )}
          </Box>
        </StyledMenuItemSelectableText>
        {selectedPlan && (
          <StyledMenuItemSelectableText disableRipple>
            <StyledSubtitleBox width="100%" px={2} py={1}>
              <Box display="flex" justifyContent="space-between" alignItems="center" height="32px">
                <Typography variant="caption">
                  {isCurrentPackage ? t('billing.topupSubtitle') : t('billing.changeplanSubtitle')}
                </Typography>
                {isCurrentPackage && <Chip variant="filled" label={t('billing.other.activeChip')} color="success" />}
              </Box>
              <Typography variant="h5">{`${selectedPackageTokens} ${t('billing.tokensPerMonth')}`}</Typography>
            </StyledSubtitleBox>
          </StyledMenuItemSelectableText>
        )}
        <StyledMenuItemSelectableText disableRipple>
          <Box>
            {packageDescription?.map(line => (
              <Box display="flex" alignItems="center" ml={1} gap={1} mb={1}>
                <StyledCircleIcon fontSize="small" />
                <Typography variant="body2" key={line}>
                  {line}
                </Typography>
              </Box>
            ))}
          </Box>
        </StyledMenuItemSelectableText>
        <StyledMenuItemSelectableText disableRipple>
          <Button
            disableRipple
            disableElevation
            disableFocusRipple
            disableTouchRipple
            fullWidth
            onClick={handlePayment}
            disabled={isDisabledAction || !selectedPlan}
          >
            {buttonText}
          </Button>
        </StyledMenuItemSelectableText>
      </StyledMenu>
    </>
  );
};

export default memo(AddTokensButton);
