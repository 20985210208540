import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledChip,
  StyledColoredBox,
  StyledList,
  StyledListItem,
  StyledPopularChip,
} from '@pages/Settings/components/BillingTab/styled';
import { StyledAreaBox, StyledCancelIconButton } from '@pages/Settings/components/styled';

import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import { SuccessTickIcon } from '@components/icons';

import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

interface PlanCardProps {
  isActive: boolean;
  isExpired?: boolean;
  isPopular?: boolean;
  isFree?: boolean;
  isEnterprise?: boolean;
  bgColor: string;
  tokensInPackage?: number;
  title: string;
  subtitle: string;
  packagePriceTitle: string;
  onClick: () => void;
  onCancelClick: () => void;
  isLoading?: boolean;
  packageButtonLocale?: string;
}

const PlanCard: FC<PlanCardProps> = ({
  title,
  subtitle,
  isActive,
  bgColor,
  packagePriceTitle,
  onClick,
  isPopular = false,
  isFree = false,
  isEnterprise = false,
  isExpired = false,
  tokensInPackage = 0,
  isLoading,
  packageButtonLocale = '',
  onCancelClick,
}) => {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenSizeContext();

  const tokensPerMonth = `${tokensInPackage} ${isFree ? t('billing.tokens') : t('billing.tokensPerMonth')}`;
  const subtitleText = t(subtitle)
    .split(';')
    .map(desc => desc.trim());
  const freeConstraint = isActive && isFree;

  return (
    <StyledAreaBox
      display="flex"
      minWidth={isLargerScreen ? '560px' : '330px'}
      flexDirection={isLargerScreen ? 'row' : 'column'}
    >
      <StyledColoredBox
        display="flex"
        p={3}
        flexDirection="column"
        gap={2}
        flex={1}
        bgcolor={bgColor}
        isMobile={!isLargerScreen}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{t(title)}</Typography>
          {isExpired && <Chip variant="filled" label={t('billing.other.expiredChip')} color="error" />}
          {isActive && !isExpired && <Chip variant="filled" label={t('billing.other.activeChip')} color="success" />}
          {isPopular && !isActive && <StyledPopularChip variant="filled" label={t('billing.other.popularChip')} />}
        </Box>

        <StyledList>
          {subtitleText.map(item => (
            <StyledListItem key={item}>
              <Typography variant="body2">{item}</Typography>
            </StyledListItem>
          ))}
        </StyledList>
      </StyledColoredBox>
      <Box display="flex" p={3} flexDirection="column" gap={3} flex={1} minWidth="280px" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <Typography variant="subtitle1">{t(packagePriceTitle)}</Typography>
          {isActive && !isFree && (
            <Tooltip
              placement="top"
              title={<Typography variant="subtitle2">{t('billing.other.cancelPlan')}</Typography>}
            >
              <StyledCancelIconButton
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disabled={isLoading}
                onClick={onCancelClick}
              >
                <CancelIcon color="error" />
              </StyledCancelIconButton>
            </Tooltip>
          )}
        </Box>

        {(isFree || tokensInPackage > 0) && <StyledChip label={tokensPerMonth} color="default" variant="filled" />}

        {isEnterprise && <Typography variant="body2">{t('billing.other.enterpriseText')}</Typography>}

        {freeConstraint && (
          <Button disableRipple disableFocusRipple disableTouchRipple fullWidth color="secondary" disabled>
            <>
              <SuccessTickIcon />
              <Typography ml={1} py="4px" variant="subtitle2">
                {t('billing.other.activeChip')}
              </Typography>
            </>
          </Button>
        )}

        {!isFree && (
          <Button
            disableRipple
            disableFocusRipple
            disableTouchRipple
            fullWidth
            onClick={onClick}
            color="primary"
            disabled={isLoading}
          >
            {isActive && (
              <Typography py="4px" variant="subtitle2">
                {t('billing.other.topUpText')}
              </Typography>
            )}
            {!isActive && (
              <Typography py="4px" variant="subtitle2">
                {t(packageButtonLocale)}
              </Typography>
            )}
          </Button>
        )}
      </Box>
    </StyledAreaBox>
  );
};

export default memo(PlanCard);
