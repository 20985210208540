import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProfileDataFieldsNames } from '@pages/Settings/enums';
import { ProfileData } from '@pages/Settings/types';

import { Box, TextField } from '@mui/material';

const EditPassword: FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<ProfileData>();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Controller
        control={control}
        name={ProfileDataFieldsNames.oldPassword}
        render={({ field, fieldState }) => (
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t('common.inputs.currentPassword')}
            type="password"
            autoComplete="current-password"
            error={Boolean(fieldState.error)}
            helperText={
              fieldState.error &&
              t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
            }
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name={ProfileDataFieldsNames.newPassword}
        render={({ field, fieldState }) => (
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t('common.inputs.newPassword')}
            type="password"
            autoComplete="new-password"
            error={Boolean(fieldState.error)}
            helperText={
              fieldState.error &&
              t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
            }
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name={ProfileDataFieldsNames.confirmNewPassword}
        render={({ field, fieldState }) => (
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t('common.inputs.repeatPassword')}
            type="password"
            autoComplete="confirm-new-password"
            error={Boolean(fieldState.error)}
            helperText={
              fieldState.error &&
              t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
            }
            {...field}
          />
        )}
      />
    </Box>
  );
};

export default memo(EditPassword);
