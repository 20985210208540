import { emptyBaseSplitApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: queryArg => ({ url: `/users`, method: 'POST', body: queryArg.createUserDto }),
    }),
    getMe: build.query<GetMeApiResponse, GetMeApiArg>({
      query: () => ({ url: `/users/me` }),
    }),
    updateMe: build.mutation<UpdateMeApiResponse, UpdateMeApiArg>({
      query: queryArg => ({ url: `/users/me`, method: 'PATCH', body: queryArg.updateUserDto }),
    }),
    deleteMe: build.mutation<DeleteMeApiResponse, DeleteMeApiArg>({
      query: () => ({ url: `/users/me`, method: 'DELETE' }),
    }),
    getTransactions: build.query<GetTransactionsApiResponse, GetTransactionsApiArg>({
      query: queryArg => ({ url: `/billing/transactions`, params: { page: queryArg.page, limit: queryArg.limit } }),
    }),
    getBalance: build.query<GetBalanceApiResponse, GetBalanceApiArg>({
      query: () => ({ url: `/billing/balance` }),
    }),
    getPackages: build.query<GetPackagesApiResponse, GetPackagesApiArg>({
      query: () => ({ url: `/subscription/packages` }),
    }),
    getPrices: build.query<GetPricesApiResponse, GetPricesApiArg>({
      query: () => ({ url: `/subscription/prices` }),
    }),
    getInvoices: build.query<GetInvoicesApiResponse, GetInvoicesApiArg>({
      query: queryArg => ({ url: `/subscription/invoices`, params: { starting_after: queryArg.startingAfter } }),
    }),
    getInvoiceDetails: build.query<GetInvoiceDetailsApiResponse, GetInvoiceDetailsApiArg>({
      query: queryArg => ({
        url: `/subscription/invoice/${queryArg.id}`,
        params: { starting_after: queryArg.startingAfter },
      }),
    }),
    getSubscription: build.query<GetSubscriptionApiResponse, GetSubscriptionApiArg>({
      query: () => ({ url: `/subscription/subscription` }),
    }),
    createCheckout: build.mutation<CreateCheckoutApiResponse, CreateCheckoutApiArg>({
      query: queryArg => ({ url: `/subscription/checkout`, method: 'POST', body: queryArg.checkoutCreateDto }),
    }),
    cancelSubscription: build.mutation<CancelSubscriptionApiResponse, CancelSubscriptionApiArg>({
      query: () => ({ url: `/subscription/cancel-subscription`, method: 'POST' }),
    }),
    createTopup: build.mutation<CreateTopupApiResponse, CreateTopupApiArg>({
      query: queryArg => ({ url: `/subscription/topup`, method: 'POST', body: queryArg.topupDto }),
    }),
    webhookStripe: build.mutation<WebhookStripeApiResponse, WebhookStripeApiArg>({
      query: queryArg => ({
        url: `/subscription/webhook`,
        method: 'POST',
        headers: { 'stripe-signature': queryArg['stripe-signature'] },
      }),
    }),
    webhookTask: build.query<WebhookTaskApiResponse, WebhookTaskApiArg>({
      query: queryArg => ({
        url: `/subscription/webhook`,
        params: { type: queryArg['type'], taskId: queryArg.taskId },
      }),
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: queryArg => ({ url: `/auth/login`, method: 'POST', body: queryArg.authUserDto }),
    }),
    register: build.mutation<RegisterApiResponse, RegisterApiArg>({
      query: queryArg => ({ url: `/auth/register`, method: 'POST', body: queryArg.createUserDto }),
    }),
    forgotPassword: build.mutation<ForgotPasswordApiResponse, ForgotPasswordApiArg>({
      query: queryArg => ({ url: `/auth/forgot-password`, method: 'POST', body: queryArg.forgotPasswordDto }),
    }),
    resetPassword: build.mutation<ResetPasswordApiResponse, ResetPasswordApiArg>({
      query: queryArg => ({ url: `/auth/reset-password`, method: 'POST', body: queryArg.resetPasswordDto }),
    }),
    changePassword: build.mutation<ChangePasswordApiResponse, ChangePasswordApiArg>({
      query: queryArg => ({ url: `/auth/change-password`, method: 'POST', body: queryArg.changePasswordDto }),
    }),
    confirmEmail: build.mutation<ConfirmEmailApiResponse, ConfirmEmailApiArg>({
      query: queryArg => ({ url: `/auth/confirm-email`, method: 'POST', body: queryArg.confirmEmailDto }),
    }),
    isVerified: build.query<IsVerifiedApiResponse, IsVerifiedApiArg>({
      query: () => ({ url: `/auth/is-verified` }),
    }),
    resendCode: build.mutation<ResendCodeApiResponse, ResendCodeApiArg>({
      query: () => ({ url: `/auth/resend-code`, method: 'POST' }),
    }),
    postAuthRefreshToken: build.mutation<PostAuthRefreshTokenApiResponse, PostAuthRefreshTokenApiArg>({
      query: queryArg => ({ url: `/auth/refresh-token`, method: 'POST', body: queryArg.refreshTokenDto }),
    }),
    createCampaign: build.mutation<CreateCampaignApiResponse, CreateCampaignApiArg>({
      query: queryArg => ({ url: `/campaigns`, method: 'POST', body: queryArg.createCampaignDto }),
    }),
    getCampaigns: build.query<GetCampaignsApiResponse, GetCampaignsApiArg>({
      query: queryArg => ({
        url: `/campaigns`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          campaignType: queryArg.campaignType,
          status: queryArg.status,
          includeFuture: queryArg.includeFuture,
          campaignGroupId: queryArg.campaignGroupId,
        },
      }),
    }),
    getCampaignTypes: build.query<GetCampaignTypesApiResponse, GetCampaignTypesApiArg>({
      query: () => ({ url: `/campaigns/types` }),
    }),
    getCampaign: build.query<GetCampaignApiResponse, GetCampaignApiArg>({
      query: queryArg => ({ url: `/campaigns/${queryArg.id}` }),
    }),
    updateCampaign: build.mutation<UpdateCampaignApiResponse, UpdateCampaignApiArg>({
      query: queryArg => ({ url: `/campaigns/${queryArg.id}`, method: 'PATCH', body: queryArg.updateCampaignDto }),
    }),
    deleteCampaign: build.mutation<DeleteCampaignApiResponse, DeleteCampaignApiArg>({
      query: queryArg => ({ url: `/campaigns/${queryArg.id}`, method: 'DELETE' }),
    }),
    deleteCampaignStart: build.mutation<DeleteCampaignStartApiResponse, DeleteCampaignStartApiArg>({
      query: queryArg => ({ url: `/campaigns/start/${queryArg.campaignStartId}`, method: 'DELETE' }),
    }),
    getCampaignFilters: build.query<GetCampaignFiltersApiResponse, GetCampaignFiltersApiArg>({
      query: queryArg => ({ url: `/campaigns/filters/${queryArg.campaignId}` }),
    }),
    getCampaignSources: build.query<GetCampaignSourcesApiResponse, GetCampaignSourcesApiArg>({
      query: queryArg => ({ url: `/campaigns/sources/${queryArg.campaignId}` }),
    }),
    getLeads: build.query<GetLeadsApiResponse, GetLeadsApiArg>({
      query: queryArg => ({
        url: `/leads`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          campaignId: queryArg.campaignId,
          campaignType: queryArg.campaignType,
          scraperId: queryArg.scraperId,
          filters: queryArg.filters,
        },
      }),
    }),
    getLeadsDatabase: build.query<GetLeadsDatabaseApiResponse, GetLeadsDatabaseApiArg>({
      query: queryArg => ({
        url: `/leads/database`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          campaignId: queryArg.campaignId,
          campaignType: queryArg.campaignType,
          scraperId: queryArg.scraperId,
          filters: queryArg.filters,
        },
      }),
    }),
    getMyLeads: build.query<GetMyLeadsApiResponse, GetMyLeadsApiArg>({
      query: queryArg => ({
        url: `/leads/mine`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          campaignId: queryArg.campaignId,
          campaignType: queryArg.campaignType,
          scraperId: queryArg.scraperId,
          filters: queryArg.filters,
        },
      }),
    }),
    getPurchasedLeads: build.query<GetPurchasedLeadsApiResponse, GetPurchasedLeadsApiArg>({
      query: queryArg => ({ url: `/leads/purchased`, params: { campaignType: queryArg.campaignType } }),
    }),
    purchaseLeads: build.mutation<PurchaseLeadsApiResponse, PurchaseLeadsApiArg>({
      query: queryArg => ({ url: `/leads/purchase`, method: 'POST', body: queryArg.purchaseLeads }),
    }),
    purchaseAllLeads: build.mutation<PurchaseAllLeadsApiResponse, PurchaseAllLeadsApiArg>({
      query: queryArg => ({ url: `/leads/purchaseAll`, method: 'POST', body: queryArg.purchaseAllLeadsDto }),
    }),
    exportCsv: build.mutation<ExportCsvApiResponse, ExportCsvApiArg>({
      query: queryArg => ({ url: `/export/export_csv`, method: 'POST', body: queryArg.exportCampaignLeadsDto }),
    }),
    verifyEmails: build.mutation<VerifyEmailsApiResponse, VerifyEmailsApiArg>({
      query: queryArg => ({ url: `/verify-email`, method: 'POST', body: queryArg.verifyEmailDto }),
    }),
    tasks: build.query<TasksApiResponse, TasksApiArg>({
      query: () => ({ url: `/scheduler/tasks` }),
    }),
    postSchedulerStartTask: build.mutation<PostSchedulerStartTaskApiResponse, PostSchedulerStartTaskApiArg>({
      query: queryArg => ({ url: `/scheduler/start-task`, method: 'POST', body: queryArg.updateTaskDto }),
    }),
    postSchedulerCreateLead: build.mutation<PostSchedulerCreateLeadApiResponse, PostSchedulerCreateLeadApiArg>({
      query: queryArg => ({ url: `/scheduler/create-lead`, method: 'POST', body: queryArg.createLeadDto }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type CreateUserApiResponse = /** status 201  */ User;
export type CreateUserApiArg = {
  createUserDto: CreateUserDto;
};
export type GetMeApiResponse = /** status 200  */ User;
export type GetMeApiArg = void;
export type UpdateMeApiResponse = /** status 200  */ User;
export type UpdateMeApiArg = {
  updateUserDto: UpdateUserDto;
};
export type DeleteMeApiResponse = /** status 200  */ User;
export type DeleteMeApiArg = void;
export type GetTransactionsApiResponse = /** status 200  */ PaginatedBillingTransactionsDto;
export type GetTransactionsApiArg = {
  /** The page number to retrieve */
  page: number;
  /** The number of items per page */
  limit: number;
};
export type GetBalanceApiResponse = /** status 200  */ BalanceDto;
export type GetBalanceApiArg = void;
export type GetPackagesApiResponse = unknown;
export type GetPackagesApiArg = void;
export type GetPricesApiResponse = unknown;
export type GetPricesApiArg = void;
export type GetInvoicesApiResponse = unknown;
export type GetInvoicesApiArg = {
  startingAfter?: string;
};
export type GetInvoiceDetailsApiResponse = unknown;
export type GetInvoiceDetailsApiArg = {
  id: string;
  startingAfter?: any;
};
export type GetSubscriptionApiResponse = unknown;
export type GetSubscriptionApiArg = void;
export type CreateCheckoutApiResponse = /** status 200  */ SubscriptionEntity;
export type CreateCheckoutApiArg = {
  checkoutCreateDto: CheckoutCreateDto;
};
export type CancelSubscriptionApiResponse = unknown;
export type CancelSubscriptionApiArg = void;
export type CreateTopupApiResponse = /** status 200  */ SubscriptionEntity;
export type CreateTopupApiArg = {
  topupDto: TopupDto;
};
export type WebhookStripeApiResponse = unknown;
export type WebhookStripeApiArg = {
  'stripe-signature': string;
};
export type WebhookTaskApiResponse = unknown;
export type WebhookTaskApiArg = {
  type: string;
  taskId: string;
};
export type LoginApiResponse = /** status 201  */ TokensDto;
export type LoginApiArg = {
  authUserDto: AuthUserDto;
};
export type RegisterApiResponse = /** status 201  */ User;
export type RegisterApiArg = {
  createUserDto: CreateUserDto;
};
export type ForgotPasswordApiResponse = unknown;
export type ForgotPasswordApiArg = {
  forgotPasswordDto: ForgotPasswordDto;
};
export type ResetPasswordApiResponse = unknown;
export type ResetPasswordApiArg = {
  resetPasswordDto: ResetPasswordDto;
};
export type ChangePasswordApiResponse = unknown;
export type ChangePasswordApiArg = {
  changePasswordDto: ChangePasswordDto;
};
export type ConfirmEmailApiResponse = /** status 201  */ TokensDto;
export type ConfirmEmailApiArg = {
  confirmEmailDto: ConfirmEmailDto;
};
export type IsVerifiedApiResponse = unknown;
export type IsVerifiedApiArg = void;
export type ResendCodeApiResponse = unknown;
export type ResendCodeApiArg = void;
export type PostAuthRefreshTokenApiResponse = /** status 201  */ TokensDto;
export type PostAuthRefreshTokenApiArg = {
  refreshTokenDto: RefreshTokenDto;
};
export type CreateCampaignApiResponse = /** status 200  */ Campaign;
export type CreateCampaignApiArg = {
  createCampaignDto: CreateCampaignDto;
};
export type GetCampaignsApiResponse = /** status 200  */ PaginatedCampaignsDto;
export type GetCampaignsApiArg = {
  /** The page number to retrieve */
  page: number;
  /** The number of items per page */
  limit: number;
  campaignType?: number | null;
  status?: number | null;
  includeFuture?: boolean | null;
  campaignGroupId?: string | null;
};
export type GetCampaignTypesApiResponse = /** status 200  */ CampaignTypesDto[];
export type GetCampaignTypesApiArg = void;
export type GetCampaignApiResponse = /** status 200  */ Campaign;
export type GetCampaignApiArg = {
  id: string;
};
export type UpdateCampaignApiResponse = unknown;
export type UpdateCampaignApiArg = {
  id: string;
  updateCampaignDto: UpdateCampaignDto;
};
export type DeleteCampaignApiResponse = unknown;
export type DeleteCampaignApiArg = {
  id: string;
};
export type DeleteCampaignStartApiResponse = unknown;
export type DeleteCampaignStartApiArg = {
  campaignStartId: string;
};
export type GetCampaignFiltersApiResponse = /** status 200  */ CampaignFiltersDto[];
export type GetCampaignFiltersApiArg = {
  campaignId: string;
};
export type GetCampaignSourcesApiResponse = /** status 200  */ ScrapersDto[];
export type GetCampaignSourcesApiArg = {
  campaignId: string;
};
export type GetLeadsApiResponse = /** status 200  */ PaginatedLeadsDto;
export type GetLeadsApiArg = {
  /** The page number to retrieve */
  page: number;
  /** The number of items per page */
  limit: number;
  /** Campaign Id */
  campaignId?: number | null;
  /** Campaign Type */
  campaignType?: number | null;
  /** Scraper Id */
  scraperId?: number | null;
  /** Leads filters */
  filters?: string | null;
};
export type GetLeadsDatabaseApiResponse = /** status 200  */ PaginatedLeadsDto;
export type GetLeadsDatabaseApiArg = {
  /** The page number to retrieve */
  page: number;
  /** The number of items per page */
  limit: number;
  /** Campaign Id */
  campaignId?: number | null;
  /** Campaign Type */
  campaignType?: number | null;
  /** Scraper Id */
  scraperId?: number | null;
  /** Leads filters */
  filters?: string | null;
};
export type GetMyLeadsApiResponse = /** status 200  */ PaginatedLeadsDto;
export type GetMyLeadsApiArg = {
  /** The page number to retrieve */
  page: number;
  /** The number of items per page */
  limit: number;
  /** Campaign Id */
  campaignId?: number | null;
  /** Campaign Type */
  campaignType?: number | null;
  /** Scraper Id */
  scraperId?: number | null;
  /** Leads filters */
  filters?: string | null;
};
export type GetPurchasedLeadsApiResponse = /** status 200  */ PurchasedLeadsDto;
export type GetPurchasedLeadsApiArg = {
  /** Campaign Type */
  campaignType: number;
};
export type PurchaseLeadsApiResponse = unknown;
export type PurchaseLeadsApiArg = {
  purchaseLeads: PurchaseLeads;
};
export type PurchaseAllLeadsApiResponse = unknown;
export type PurchaseAllLeadsApiArg = {
  purchaseAllLeadsDto: PurchaseAllLeadsDto;
};
export type ExportCsvApiResponse = unknown;
export type ExportCsvApiArg = {
  exportCampaignLeadsDto: ExportCampaignLeadsDto;
};
export type VerifyEmailsApiResponse = unknown;
export type VerifyEmailsApiArg = {
  verifyEmailDto: VerifyEmailDto;
};
export type TasksApiResponse = /** status 201  */ TaskDto[];
export type TasksApiArg = void;
export type PostSchedulerStartTaskApiResponse = unknown;
export type PostSchedulerStartTaskApiArg = {
  updateTaskDto: UpdateTaskDto;
};
export type PostSchedulerCreateLeadApiResponse = unknown;
export type PostSchedulerCreateLeadApiArg = {
  createLeadDto: CreateLeadDto;
};
export type Subscription = {
  id: number;
  created_at: string;
  updated_at: string;
  subscription_id: string;
  users_id: number;
  stripe_customer_id: string;
  is_annual: boolean;
};
export type User = {
  users_id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  created_date: string | null;
  subscriptions: Subscription[] | null;
};
export type CreateUserDto = {
  firstname: string;
  lastname: string;
  password: string;
  phone: string;
  email: string;
};
export type UpdateUserDto = {
  firstname?: string;
  lastname?: string;
  password?: string;
  phone?: string;
  email?: string;
};
export type CampaignNameDto = {
  name: string;
};
export type TransactionsDto = {
  billing_transactions_id: number;
  paid_tokens: number;
  reserved_tokens: number;
  billing_id_billing: number;
  campaign_id_campaign: number;
  task_id_tasks: number;
  created_at: string;
  invoice_id: string;
  transaction_type:
    | 'topup'
    | 'subscription'
    | 'campaign_start'
    | 'purchase_leads'
    | 'campaign_refund'
    | 'email_verification';
  campaign: CampaignNameDto | null;
};
export type PaginatedBillingTransactionsDto = {
  total: number;
  data: TransactionsDto[];
  page: number;
  limit: number;
  totalPages: number;
};
export type BalanceDto = {
  balance: number;
  token: number;
  reserved: number;
};
export type SubscriptionEntity = {
  url: string;
};
export type CheckoutCreateDto = {
  successUrl: string;
  cancelUrl: string;
  priceId: string;
};
export type TopupDto = {
  successUrl: string;
  cancelUrl: string;
  priceId: string;
};
export type TokensDto = {
  accessToken: string;
  refreshToken: string;
};
export type AuthUserDto = {
  email: string;
  password: string;
};
export type ForgotPasswordDto = {
  email: string;
  redirectURL: string;
};
export type ResetPasswordDto = {
  password: string;
  repeatPassword: string;
  otp: string;
};
export type ChangePasswordDto = {
  password: string;
  repeatPassword: string;
};
export type ConfirmEmailDto = {
  code: string;
};
export type RefreshTokenDto = {
  refreshToken: string;
};
export type ScrapersDto = {
  scrapers_id: number;
  scraper_name: string;
  site: string;
  price: number;
  campaign_type_id_campaign_type: number;
};
export type CampaignScrapersDto = {
  id: number;
  campaign_id: number;
  scraper_id: number;
  leads_count: number;
  scrapers: ScrapersDto;
};
export type StartCampaignEntity = {
  campaign_start: string;
};
export type CampaignTypesDto = {
  campaign_type_id: number;
  type_name: string;
  price: number;
  status: string;
};
export type CampFilterDto = {
  camp_filters_id: number;
  filter_name: string;
  filter_val: string;
  campaign_id_campaign: number;
  scraper_id: number;
};
export type CreateSchedulingDto = {
  repeat?: 'Once' | 'Weekly' | 'Monthly';
  repeat_count: number;
  start_date: string;
  days?: number[];
};
export type Campaign = {
  name: string;
  description: string;
  status: number;
  campaign_id: number;
  campaign_type_id: number;
  created_at: string;
  campaign_scrapers: CampaignScrapersDto[];
  campaign_start: StartCampaignEntity[];
  campaign_type: CampaignTypesDto;
  camp_filters?: CampFilterDto[] | null;
  scheduling?: CreateSchedulingDto | null;
};
export type CreateScraperFiltersDto = {
  id: number;
  name: string;
  value: string;
};
export type CreateScrapersDto = {
  scraper_id: number;
  leads_count: number;
  filters: CreateScraperFiltersDto[];
};
export type CreateCampaignDto = {
  scrapers: CreateScrapersDto[];
  paid_filters: CreateScraperFiltersDto[];
  campaign_id: number;
  name: string;
  description: string;
  status: number;
  campaign_start: string;
  scheduling?: CreateSchedulingDto | null;
};
export type PaginatedCampaignsDto = {
  total: number;
  data: Campaign[];
  page: number;
  limit: number;
  totalPages: number;
};
export type UpdateCampaignDto = {
  name: string;
  description: string;
  status: number;
};
export type CampaignFiltersDto = {
  campaign_filters_id: number;
  key: string;
  type: string;
  campaign_type_id_campaign_type: number;
  price: number;
};
export type LeadsEntity = {
  leads_id: number;
  created_at: string;
  data: object;
  emails_count: number;
  campaign_id_campaign: number;
  tasks_id_tasks: number;
  inserted_at: string;
  purchased: boolean;
  scraper_id: number;
  campaign_type_id_campaign_type: number;
  raw: object;
};
export type PaginatedLeadsDto = {
  total: number;
  data: LeadsEntity[];
  page: number;
  limit: number;
  totalPages: number;
};
export type PurchasedLeadsDto = {
  totalPurchasedLeads: number;
};
export type PurchaseLeads = {
  id: number[];
};
export type PurchaseAllLeadsDto = {
  /** Leads filters */
  filters?: string | null;
  /** Campaign Type */
  campaignType?: number | null;
};
export type ExportCampaignLeadsDto = {
  campaignId?: string;
  selectedLeadIds?: any[];
  scraperId: number;
  /** Campaign Type */
  campaignType?: number | null;
  /** Leads filters */
  filters?: string | null;
};
export type VerifyEmailDto = {
  emails: string[];
};
export type ScraperDto = {
  /** The name of the scraper */
  scraper_name: string;
};
export type CampaignDto = {
  /** The ID of the campaign */
  campaign_id: number;
};
export type TaskDto = {
  /** The ID of the task */
  tasks_id: number;
  /** The number of leads */
  leads_count: number;
  /** The filters applied for the task */
  filters: object;
  /** The scraper information */
  scrapers: ScraperDto;
  /** The campaign information */
  campaign: CampaignDto;
};
export type UpdateTaskDto = {
  /** The ID of the task to be updated */
  tasks_id: number;
};
export type CreateLeadDto = {
  /** The date when the lead was created */
  created_at: string;
  /** Unique message ID related to the lead */
  message_id: string;
  /** The JSON data related to the lead */
  data: object;
  /** Campaign ID associated with the lead */
  campaign_id_campaign: number;
  /** Task ID associated with the lead */
  tasks_id_tasks: number;
};
export const {
  useCreateUserMutation,
  useGetMeQuery,
  useUpdateMeMutation,
  useDeleteMeMutation,
  useGetTransactionsQuery,
  useGetBalanceQuery,
  useGetPackagesQuery,
  useGetPricesQuery,
  useGetInvoicesQuery,
  useGetInvoiceDetailsQuery,
  useGetSubscriptionQuery,
  useCreateCheckoutMutation,
  useCancelSubscriptionMutation,
  useCreateTopupMutation,
  useWebhookStripeMutation,
  useWebhookTaskQuery,
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useConfirmEmailMutation,
  useIsVerifiedQuery,
  useResendCodeMutation,
  usePostAuthRefreshTokenMutation,
  useCreateCampaignMutation,
  useGetCampaignsQuery,
  useGetCampaignTypesQuery,
  useGetCampaignQuery,
  useUpdateCampaignMutation,
  useDeleteCampaignMutation,
  useDeleteCampaignStartMutation,
  useGetCampaignFiltersQuery,
  useGetCampaignSourcesQuery,
  useGetLeadsQuery,
  useGetLeadsDatabaseQuery,
  useGetMyLeadsQuery,
  useGetPurchasedLeadsQuery,
  usePurchaseLeadsMutation,
  usePurchaseAllLeadsMutation,
  useExportCsvMutation,
  useVerifyEmailsMutation,
  useTasksQuery,
  usePostSchedulerStartTaskMutation,
  usePostSchedulerCreateLeadMutation,
} = injectedRtkApi;
