import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { USER_ACCESS_TOKEN } from '@constants/auth';
import { RootState } from '@store/store';
import { tokenStorage } from '@utils/tokenStorage';
import socketIO, { Socket } from 'socket.io-client';

interface WebsocketContextType {
  socket: Socket | null;
}

const getTokenFromStorage = () => tokenStorage.getToken(USER_ACCESS_TOKEN);

const WsContext = createContext<WebsocketContextType>({ socket: null });

export const useWS = () => useContext(WsContext);

const WebsocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const connect = () => {
      const token = getTokenFromStorage();

      const newSocket = socketIO(`${import.meta.env.VITE_APP_API_URL}`, {
        reconnectionDelay: 5000,
        secure: true,
        reconnectionDelayMax: 5000,
        forceNew: true,
        autoConnect: false,
      });

      newSocket.auth = {
        Authorization: `Bearer ${token}`,
      };

      newSocket.connect();

      setSocket(newSocket);

      return newSocket;
    };

    // noinspection PointlessBooleanExpressionJS
    if (authState.isAuthenticated === true && tokenStorage.getToken(USER_ACCESS_TOKEN)) {
      const newSocket = connect();

      newSocket.on('disconnect', () => {
        if (newSocket.disconnected) {
          setTimeout(() => {
            const token = getTokenFromStorage();

            newSocket.auth = {
              Authorization: `Bearer ${token}`,
            };

            newSocket.connect();
          }, 5000);
        }
      });

      return () => {
        newSocket.disconnect();
      };
    }

    // noinspection PointlessBooleanExpressionJS
    if (authState.isAuthenticated === false && socket) {
      socket.disconnect();
      setSocket(null);
    }
    // No action for null state to wait
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isAuthenticated]);

  const contextValue = useMemo(() => ({ socket }), [socket]);

  return <WsContext.Provider value={contextValue}>{children}</WsContext.Provider>;
};

export default WebsocketProvider;
