import { FC, memo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGetBalanceQuery } from '@api/api';
import { useHeight } from '@hooks/common/useHeight';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';
import { StyledBoldText } from '@pages/styled';

import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Button, CircularProgress, Collapse, Tooltip, Typography } from '@mui/material';
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid';
import CustomFilters from '@components/CustomFilters';
import { ScrapekingDbDynamicDataGrid } from '@components/DynamicDataGrid';
import MobileScrapekingLeadsDbTable from '@components/LeadsTable/mobile/MobileScrapekingLeadsDbTable';
import { LeadsDbTableProps } from '@components/LeadsTable/types';
import LoadingButton from '@components/LoadingButton';

import {
  StyledIconButton,
  StyledLeadsBox,
  StyledSmartHeightDataGridContainer,
  StyledSmartHeightFiltersContainer,
} from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';
import { useProtectedRoutesContext } from '@/shared/ProtectedRoutesContext';

const ScrapekingLeadsDbTable: FC<LeadsDbTableProps> = ({
  totalLeadsFound,
  leadsData,
  isLoadingLeads,
  refetch,
  campaignType,
  handlePurchase = () => {},
  handlePurchaseAll,
  isLoadingPurchaseMutation,
  totalPurchasedLeads,
}) => {
  const [hasPurchasedLeads, setHasPurchasedLeads] = useState(false);
  const [selectedLeadIds, setSelectedLeadIds] = useState<never[] | GridRowSelectionModel>([]);
  const { t } = useTranslation();
  const { setPage, setLimit, page, limit } = usePageFilterUrlParams();
  const { expandedFilters, toggleFilters } = useProtectedRoutesContext();
  const { data: balanceData } = useGetBalanceQuery();
  const { isLargerScreen } = useScreenSizeContext();
  const { headerAppHeight } = useHeight();

  const isEnoughTokens = balanceData && balanceData.token > selectedLeadIds.length;

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  const handleSelectionChange = (selectionModel: GridRowSelectionModel) => {
    const purchasedLeads = leadsData?.data.filter(obj => selectionModel.includes(obj.leads_id) && obj.purchased);
    if (purchasedLeads) {
      setHasPurchasedLeads(purchasedLeads.length > 0);
    }
    setSelectedLeadIds([...selectionModel]);
  };

  const handlePurchaseClick = async () => {
    await handlePurchase(selectedLeadIds);
    setSelectedLeadIds([]);
  };

  if (!isLargerScreen) {
    return (
      <MobileScrapekingLeadsDbTable
        totalLeadsFound={totalLeadsFound}
        handlePaginationModelChange={handlePaginationModelChange}
        refetch={refetch}
        leadsData={leadsData}
        campaignType={campaignType}
        totalLeadsSelected={selectedLeadIds.length}
        isLoadingLeads={isLoadingLeads}
        handleSelectionChange={handleSelectionChange}
        isEnoughTokens={isEnoughTokens}
        hasPurchasedLeads={hasPurchasedLeads}
        handlePurchaseClick={handlePurchaseClick}
        handlePurchaseAllClick={handlePurchaseAll}
        isLoadingPurchaseMutation={isLoadingPurchaseMutation}
        totalPurchasedLeads={totalPurchasedLeads}
      />
    );
  }

  return (
    <>
      <Box width="100%" display="flex">
        <Box display="flex" alignItems="center" width="100%">
          <Box display="flex" justifyContent="space-between" width="250px">
            <Box>
              <Typography variant="subtitle2" display="inline" mr={1}>
                {t('common.filtersTitle')}
              </Typography>
            </Box>

            <StyledIconButton onClick={toggleFilters} disableRipple>
              {expandedFilters ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </StyledIconButton>
          </Box>

          <StyledLeadsBox display="flex" justifyContent="space-between" maxHeight="72px">
            <Box pl={3} my={3}>
              <Typography variant="subtitle2" mr={1} display="inline">
                {totalLeadsFound || 0}
              </Typography>
              <Typography variant="body2" display="inline">
                {t('dashboard.campaignDetails.leadsFound')}
              </Typography>
              {selectedLeadIds.length > 0 && (
                <>
                  <Typography variant="body2" display="inline" ml={1}>
                    {t('dashboard.leads.selected')}
                  </Typography>
                  <Typography variant="subtitle2" display="inline" ml={1}>
                    {`${selectedLeadIds.length} ${t('dashboard.newScraper.leadsLabel')}`}
                  </Typography>
                </>
              )}
            </Box>

            <Box display="flex" gap={2} my={2}>
              {selectedLeadIds.length > 0 && (
                <Typography variant="body2" alignSelf="center" color={!isEnoughTokens ? 'red' : 'currentcolor'}>
                  <Trans
                    i18nKey="dashboard.db.totalDue"
                    values={{
                      price: selectedLeadIds.length,
                    }}
                    components={{
                      bold: <StyledBoldText component="span" fontSize="14px" />,
                    }}
                  />
                </Typography>
              )}
              {campaignType && campaignType === 'Jobs' && (
                <LoadingButton
                  disableRipple
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={selectedLeadIds.length < 1}
                  onClick={() => console.log('verify', selectedLeadIds)}
                  loadingIndicator={<CircularProgress color="inherit" size={22} />}
                >
                  <Typography variant="subtitle2" minWidth="140px">
                    {t('dashboard.db.verifyEmail')}
                  </Typography>
                </LoadingButton>
              )}
              {!isEnoughTokens ? (
                <Tooltip title={t('dashboard.db.notEnoughTokens')} placement="top">
                  <Button disableRipple variant="contained" color="primary" size="medium" disabled>
                    <Typography variant="subtitle2" minWidth="140px">
                      {t('dashboard.db.purchaseLeads')}
                    </Typography>
                  </Button>
                </Tooltip>
              ) : hasPurchasedLeads ? (
                <Tooltip title={t('dashboard.db.alreadyPurchased')} placement="top" open={hasPurchasedLeads}>
                  <Button disableRipple variant="contained" color="primary" size="medium" disabled>
                    <Typography variant="subtitle2" minWidth="140px">
                      {t('dashboard.db.purchaseLeads')}
                    </Typography>
                  </Button>
                </Tooltip>
              ) : (
                <Box display="flex" gap={2}>
                  <LoadingButton
                    disableRipple
                    variant="contained"
                    color="primary"
                    size="medium"
                    disabled={
                      isLoadingPurchaseMutation ||
                      isLoadingLeads ||
                      totalLeadsFound === 0 ||
                      totalPurchasedLeads === totalLeadsFound
                    }
                    onClick={handlePurchaseAll}
                    loadingIndicator={<CircularProgress color="inherit" size={22} />}
                  >
                    <Typography variant="subtitle2" minWidth="140px">
                      {t('dashboard.db.purchaseAllLeads')}
                    </Typography>
                  </LoadingButton>

                  <LoadingButton
                    disableRipple
                    variant="contained"
                    color="primary"
                    size="medium"
                    disabled={isLoadingPurchaseMutation || selectedLeadIds.length < 1}
                    onClick={handlePurchaseClick}
                    loadingIndicator={<CircularProgress color="inherit" size={22} />}
                  >
                    <Typography variant="subtitle2" minWidth="140px">
                      {t('dashboard.db.purchaseLeads')}
                    </Typography>
                  </LoadingButton>
                </Box>
              )}
            </Box>
          </StyledLeadsBox>
        </Box>
      </Box>

      <Box display="flex" height="100%">
        {expandedFilters && (
          <Collapse in={expandedFilters}>
            <StyledSmartHeightFiltersContainer
              display="flex"
              flexDirection="column"
              gap={1}
              pr={2}
              width="250px"
              headerAppHeight={headerAppHeight}
            >
              <CustomFilters campaignType={campaignType} refetch={refetch} />
            </StyledSmartHeightFiltersContainer>
          </Collapse>
        )}
        <StyledSmartHeightDataGridContainer expanded={expandedFilters} headerAppHeight={headerAppHeight}>
          <ScrapekingDbDynamicDataGrid
            data={leadsData}
            handlePageChange={handlePaginationModelChange}
            paginationModel={{ page, pageSize: limit }}
            isDataLoading={isLoadingLeads}
            expandedFilters={expandedFilters}
            campaignType={campaignType}
            handleCheckboxSelection={handleSelectionChange}
            selectedLeadIds={selectedLeadIds}
          />
        </StyledSmartHeightDataGridContainer>
      </Box>
    </>
  );
};

export default memo(ScrapekingLeadsDbTable);
