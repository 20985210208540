import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { StyledAreaBox } from '@pages/styled';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Box, IconButton, Typography } from '@mui/material';

interface InfoBlockProps {
  amount: number;
  title: string;
  isPricingRedirect?: boolean;
}

const InfoBlock: FC<InfoBlockProps> = ({ amount, title, isPricingRedirect = false }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRedirect = () => navigate(`/${ROUTING.PRICING}`);

  return (
    <StyledAreaBox display="flex" justifyContent="space-between" width="100%">
      <Box width="stretch">
        <Typography variant="h5">{amount}</Typography>
        <Typography variant="body2" color="text.secondary">
          {t(title)}
        </Typography>
      </Box>
      {isPricingRedirect ? (
        <IconButton onClick={handleRedirect} disableRipple>
          <AddRoundedIcon color="primary" />
        </IconButton>
      ) : null}
    </StyledAreaBox>
  );
};

export default memo(InfoBlock);
