import { NOT_AVAILABLE, TOOLTIP_MAX_CHARS } from '@constants/common';

import { Typography } from '@mui/material';
import LabelWithTooltip from '@components/LabelWithTooltip';

export const withTooltip = (text: string) => {
  if (!text) {
    return <Typography variant="body2">{NOT_AVAILABLE}</Typography>;
  }
  return text.length > TOOLTIP_MAX_CHARS ? (
    <LabelWithTooltip title={text} />
  ) : (
    <Typography variant="body2">{text}</Typography>
  );
};
