/* eslint-disable no-param-reassign */
import { FC, memo, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { api, useGetBalanceQuery } from '@api/api';
import { ROUTING } from '@constants/routing';
import { useUser } from '@hooks/api/useUser';
import { useWsEvent } from '@hooks/api/useWsEvent';
import { useBilling } from '@hooks/common/useBilling';
import { useHeight } from '@hooks/common/useHeight';
import { useLogout } from '@hooks/common/useLogout';
import { usePricingContext } from '@pages/Pricing/context';
import MobileDrawer from '@providers/layouts/AppLayout/components/MobileDrawer';
import { AppDispatch } from '@store/store';
import { convertIsoTrialDaysLeft } from '@utils/formatTime';

import { Box, Divider, IconButton, Link } from '@mui/material';
import AddTokensButton from '@components/AddTokensButton/AddTokensButton';
import { CollapseMenu, ExpandMenu, Logo, SmallLogo } from '@components/icons';
import ProfileChip from '@components/ProfileChip/ProfileChip';
import TrialNotificationBar from '@components/TrialNotificationBar';

import Footer from './components/Footer';
import Navigation from './components/Navigation/Navigation';
import { StyledAppBar, StyledBox, StyledContainer, StyledScrollableContainer, StyledWrapperContent } from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';
import { useProtectedRoutesContext } from '@/shared/ProtectedRoutesContext';

const AppLayout: FC<PropsWithChildren & { showSidebar?: boolean; showFooter?: boolean }> = ({
  children,
  showSidebar = true,
  showFooter = false,
}) => {
  const { data: userData } = useUser();
  const { period, setPeriod } = usePricingContext();
  const { data: balanceData } = useGetBalanceQuery();
  const { subscriptionData, packagesData, handleTopup, handleCheckout, isLoadingMutation, pricesData } =
    useBilling(period);
  const { isLargerScreen } = useScreenSizeContext();
  const { expandedMenu, toggleMenu } = useProtectedRoutesContext();
  const dispatch: AppDispatch = useDispatch();
  const { headerAppHeight } = useHeight();

  const { logout } = useLogout();

  useWsEvent('balance-updated', (res: Record<string, any>) => {
    const patchCollection = dispatch(
      api.util.updateQueryData('getBalance', undefined, draftBalance => {
        if (draftBalance) {
          draftBalance.balance = res.balance;
          draftBalance.token = res.token;
          draftBalance.reserved = res.reserved;
        }
      }),
    );

    dispatch(api.util.patchQueryData('getBalance', undefined, patchCollection.patches));
  });

  return (
    <>
      <StyledAppBar position={isLargerScreen ? 'static' : 'relative'} headerAppHeight={headerAppHeight}>
        <StyledContainer maxWidth={false}>
          <StyledBox>
            <Box display="flex" alignItems="center" gap={1}>
              {!isLargerScreen && (
                <IconButton onClick={toggleMenu} disableRipple>
                  {expandedMenu ? <CollapseMenu /> : <ExpandMenu />}
                </IconButton>
              )}
              <Link to={ROUTING.ROOT} component={RouterLink} mt="5px">
                {isLargerScreen ? <Logo /> : <SmallLogo />}
              </Link>
            </Box>

            <Box display="flex" gap={2}>
              <AddTokensButton
                tokensAmount={balanceData?.token}
                packagesData={packagesData}
                subscriptionData={subscriptionData}
                handleTopup={handleTopup}
                handleCheckout={handleCheckout}
                isDisabledAction={isLoadingMutation}
                pricesData={pricesData}
                userData={userData}
                period={period}
                setPeriod={setPeriod}
                isLargerScreen={isLargerScreen}
              />
              <ProfileChip userData={userData} handleLogout={logout} isLargerScreen={isLargerScreen} />
            </Box>
          </StyledBox>
        </StyledContainer>
        {!userData?.subscriptions?.[0]?.stripe_customer_id && (
          <TrialNotificationBar
            redirectLink={`/${ROUTING.PRICING}`}
            daysLeft={convertIsoTrialDaysLeft(userData?.subscriptions?.[0]?.created_at as string)}
            isMobile={!isLargerScreen}
          />
        )}
      </StyledAppBar>
      <Divider sx={{ marginTop: headerAppHeight > 80 ? '-10px' : 'inherit' }} />
      <StyledWrapperContent headerAppHeight={headerAppHeight}>
        {showSidebar ? <Navigation /> : null}
        {expandedMenu && !isLargerScreen && <MobileDrawer />}
        <StyledScrollableContainer maxWidth="xxl">{children}</StyledScrollableContainer>
      </StyledWrapperContent>
      {showFooter ? <Footer /> : null}
    </>
  );
};

export default memo(AppLayout);
