import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';
import { useSourceNameQueryParams } from '@pages/CampaignDetails/hooks/useSourceNameQueryParams';

import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Button, Collapse, Divider, Typography } from '@mui/material';
import CustomFilters from '@components/CustomFilters';
import { CampaignDetailsDynamicDataGrid } from '@components/DynamicDataGrid';
import { ScraperFilter } from '@components/Filter';
import { StyledIconButton } from '@components/LeadsTable/styled';
import { MobileCampaignDetailsLeadsTableProps } from '@components/LeadsTable/types';
import LoadingIconButton from '@components/LoadingIconButton';

import { useProtectedRoutesContext } from '@/shared/ProtectedRoutesContext';

const MobileCampaignLeadsTable: FC<MobileCampaignDetailsLeadsTableProps> = ({
  pricedFilters,
  freeFilters,
  requestedLeads,
  handlePaginationModelChange,
  totalLeadsRequested,
  totalLeadsFound,
  autocompleteSx,
  refetch,
  leadsData,
  campaign,
  isLoadingLeads,
  selectedLeadIds,
  handleCheckboxSelection,
  handleDownloadCsv,
  isLoadingCsv,
  totalLeadsSelected = 0,
}) => {
  const { t } = useTranslation();
  const { expandedFilters, toggleFilters } = useProtectedRoutesContext();
  const { page, limit } = usePageFilterUrlParams();
  const { sourceName } = useSourceNameQueryParams();

  return (
    <>
      <Box>
        <Box p={2}>
          {requestedLeads && (
            <>
              <Typography variant="subtitle2" mr={1} display="inline" color="text.secondary">
                {totalLeadsRequested || 0}
              </Typography>
              <Typography variant="body2" display="inline" color="text.secondary">
                {t('dashboard.campaignDetails.leadsRequested')}
              </Typography>
              {' / '}
              <Typography variant="subtitle2" mr={1} display="inline" color="text.secondary">
                {totalLeadsFound || 0}
              </Typography>
              <Typography variant="body2" display="inline" color="text.secondary">
                {t('dashboard.campaignDetails.leadsFound')}
              </Typography>
              {totalLeadsSelected > 0 && (
                <Box>
                  <Typography variant="body2" display="inline">
                    {t('dashboard.leads.selected')}
                  </Typography>
                  <Typography variant="subtitle2" display="inline">
                    {` ${totalLeadsSelected} ${t('dashboard.newScraper.leadsLabel')}`}
                  </Typography>
                </Box>
              )}
            </>
          )}

          <Box display="flex" gap={2} mt={2}>
            <Button disableRipple variant="contained" color="primary" size="medium" startIcon={<AddIcon />}>
              <Typography variant="subtitle2" minWidth="140px">
                {t('dashboard.campaignDetails.addToCampaign')}
              </Typography>
            </Button>
            <LoadingIconButton
              loaderSize="1.5rem"
              onClick={handleDownloadCsv}
              loading={isLoadingCsv}
              loaderColor="secondary"
              size="medium"
              color="primary"
              disabled={!totalLeadsFound}
            >
              <DownloadIcon />
            </LoadingIconButton>
          </Box>
        </Box>

        <Divider />

        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" p={2}>
            <Typography variant="subtitle2" mr={1}>
              {t('common.filtersTitle')}
            </Typography>

            <StyledIconButton onClick={toggleFilters} disableRipple>
              {expandedFilters ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </StyledIconButton>
          </Box>

          {expandedFilters && (
            <Collapse in={expandedFilters}>
              <Box display="flex" flexDirection="column" gap={1} p={2}>
                {freeFilters.length ? (
                  <Box>
                    <Typography variant="subtitle2" mb={1}>
                      {t('dashboard.freeFilters')}
                    </Typography>
                    {freeFilters.map(filter => (
                      <Box key={filter.filter_name} my={2}>
                        <ScraperFilter filter={filter} autocompleteSx={autocompleteSx} />
                      </Box>
                    ))}
                  </Box>
                ) : null}
                {pricedFilters.length ? (
                  <Box>
                    <Typography variant="subtitle2" mb={1}>
                      {t('dashboard.paidFilters')}
                    </Typography>
                    {pricedFilters.map(filter => (
                      <ScraperFilter filter={filter} autocompleteSx={autocompleteSx} />
                    ))}
                  </Box>
                ) : null}
                <CustomFilters campaignType={campaign?.campaign_type?.type_name} refetch={refetch} />
              </Box>
            </Collapse>
          )}
        </Box>
        <Divider />
      </Box>

      <Box display="flex" height="100%">
        <CampaignDetailsDynamicDataGrid
          data={leadsData}
          handlePageChange={handlePaginationModelChange}
          paginationModel={{ page, pageSize: limit }}
          isDataLoading={isLoadingLeads}
          sourceName={sourceName as string}
          expandedFilters={expandedFilters}
          selectedLeadIds={selectedLeadIds}
          handleCheckboxSelection={handleCheckboxSelection}
        />
      </Box>
    </>
  );
};

export default memo(MobileCampaignLeadsTable);
