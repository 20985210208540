export const background = {
  default: 'rgba(255, 255, 255, 1)',
  paperElevation0: 'rgba(248, 250, 251, 1)',
  disabledButton: 'rgba(224, 224, 224, 1)',
};

export const action = {
  active: 'rgba(0, 0, 0, 0.56)',
  hover: 'rgba(0, 0, 0, 0.04)',
  selected: 'rgba(0, 0, 0, 0.08)',
  disabledBackground: 'rgba(0, 0, 0, 0.12)',
  focus: 'rgba(0, 0, 0, 0.12)',
  disabled: 'rgba(0, 0, 0, 0.38)',
  divider: 'rgba(0, 0, 0, 0.12)',
};

export const success = {
  main: 'rgba(46, 125, 50, 1)',
  dark: 'rgba(27, 94, 32, 1)',
  light: 'rgba(76, 175, 80, 1)',
};

export const info = {
  main: 'rgba(2, 136, 209, 1)',
  dark: 'rgba(1, 87, 155, 1)',
  light: 'rgba(3, 169, 244, 1)',
};

export const warning = {
  main: 'rgba(239, 108, 0, 1)',
  dark: 'rgba(239, 108, 0, 1)',
  light: 'rgba(255, 152, 0, 1)',
};

export const error = {
  main: 'rgba(211, 47, 47, 1)',
  dark: 'rgba(198, 40, 40, 1)',
  light: 'rgba(239, 83, 80, 1)',
};

export const secondary = {
  main: 'rgba(66, 66, 66, 1)',
  dark: 'rgba(33, 33, 33, 1)',
  light: 'rgba(97, 97, 97, 1)',
  background: 'rgba(245,245,245, 1)',
  border: 'rgba(216,216,216,1)',
  button: 'rgba(0, 0, 0, 0.38)',
  outline: 'rgba(139,139,139,1)',
};

export const primary = {
  main: 'rgba(33, 150, 243, 1)',
  dark: 'rgba(30, 136, 229, 1)',
  light: 'rgba(66, 165, 245, 1)',
  hover: 'rgba(33, 150, 243, 0.04)',
  selected: 'rgba(33, 150, 243, 0.08)',
  focus: 'rgba(33, 150, 243, 0.12)',
  focusVisible: 'rgba(33, 150, 243, 0.3)',
  outlineBorder: 'rgba(33, 150, 243, 0.5)',
  list: 'rgba(237, 247, 254, 1)',
};

export const colors = {
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(0, 0, 0, 0.87)',
  text: 'rgba(0, 0, 0, 0.87)',
  disabled: 'rgba(0, 0, 0, 0.3)',
  background,
  action,
  success,
  info,
  warning,
  error,
  secondary,
  primary,
};
