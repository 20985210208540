import { useCallback, useState } from 'react';
import { SLIDER_MAX_VAL, SLIDER_MIN_VAL } from '@constants/common';
import { FormValues, GroupedValues } from '@hooks/types';

function groupFormValues(formValues: FormValues): GroupedValues {
  const groupedValues: GroupedValues = {};

  Object.keys(formValues).forEach(key => {
    /** criteria is 'gte' | 'lte' | undefined */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [prefix, criteria, ...propertyParts] = key.split('+');
    const property = propertyParts.join('$');
    const groupKey = `${prefix}${property}`;

    if (!groupedValues[groupKey]) {
      groupedValues[groupKey] = [];
    }

    groupedValues[groupKey].push(formValues[key]);
  });

  return groupedValues;
}

function countNonEmptyArrays(groupedValues: GroupedValues): number {
  return Object.values(groupedValues).reduce((count, value) => {
    if (value.some(item => typeof item === 'number' && item > SLIDER_MIN_VAL && item < SLIDER_MAX_VAL)) {
      return count + 1;
    }
    if (value.some(item => typeof item === 'string' && item !== '0' && item)) {
      return count + 1;
    }
    if (value.some(item => Array.isArray(item) && item.length)) {
      return count + 1;
    }
    if (value.some(item => typeof item === 'boolean' && item)) {
      return count + 1;
    }
    return count;
  }, 0);
}

export const useSelectedFilters = (form: FormValues) => {
  const grouped = groupFormValues(form);
  const initialCount = countNonEmptyArrays(grouped);

  const [appliedFiltersCount, setAppliedFiltersCount] = useState(initialCount || 0);

  const handleUpdateAppliedFilters = useCallback(() => {
    const formatted = groupFormValues(form);

    const selected = countNonEmptyArrays(formatted);
    setAppliedFiltersCount(selected);
  }, [form]);

  return { appliedFiltersCount, handleUpdateAppliedFilters, setAppliedFiltersCount };
};
