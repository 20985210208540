import { memo } from 'react';

import { StepIconProps } from '@mui/material';

import { StyledStepIcon } from './styled';

const StepperStepIcon = (props: StepIconProps) => {
  const { icon, active, completed } = props;
  return <StyledStepIcon {...props} icon={icon} active={active || completed} completed={false} />;
};

export default memo(StepperStepIcon);
