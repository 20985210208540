import { CampaignTypesDto } from '@api/api';
import { CAMPAIGN_ID } from '@constants/common';

export const transformData = (data: CampaignTypesDto[] | undefined) => {
  if (data !== undefined && Array.isArray(data)) {
    return data.map(item => ({
      label: item.type_name,
      value: item.type_name,
      [CAMPAIGN_ID]: item.campaign_type_id,
    }));
  }
  return [];
};

export const generateMonthlyOptions = (count: number) => {
  const suffixes = ['st', 'nd', 'rd'];
  return Array.from({ length: count }, (_, i) => {
    let suffix = 'th';
    if (i + 1 <= 3) {
      suffix = suffixes[i];
    }
    return {
      value: `${i + 1}${suffix}`,
      label: `${i + 1}${suffix}`,
    };
  });
};
