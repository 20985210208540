export const ROUTING = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  CONFIRM_EMAIL: 'confirm-email',
  ROOT: '/',
  FORGOT_PASSWORD: 'forgot-password',
  CHECK_EMAIL: 'check-email',
  RESET_PASSWORD: 'reset-password',
  LEADS_DATABASE: 'database',
  EMAIL_CAMPAIGNS: 'email-campaigns',
  EMAIL_ACCOUNTS: 'email-accounts',
  SETTINGS: 'settings',
  NEW_SCRAPER: 'new-scraper',
  CAMPAIGN_DETAILS: 'campaign-details',
  EDIT_SCRAPER: 'edit-scraper',
  PRICING: 'pricing',
};
