import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';
import { StyledBoldText } from '@pages/styled';

import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Button, CircularProgress, Collapse, Divider, Tooltip, Typography } from '@mui/material';
import CustomFilters from '@components/CustomFilters';
import { ScrapekingDbDynamicDataGrid } from '@components/DynamicDataGrid';
import { StyledIconButton } from '@components/LeadsTable/styled';
import { MobileScrapekingLeadsTableProps } from '@components/LeadsTable/types';
import LoadingButton from '@components/LoadingButton';

import { useProtectedRoutesContext } from '@/shared/ProtectedRoutesContext';

const MobileScrapekingLeadsDbTable: FC<MobileScrapekingLeadsTableProps> = ({
  handlePaginationModelChange,
  handleSelectionChange,
  totalLeadsFound,
  totalLeadsSelected = 0,
  refetch,
  leadsData,
  campaignType,
  isLoadingLeads,
  isEnoughTokens,
  hasPurchasedLeads,
  isLoadingPurchaseMutation,
  handlePurchaseClick,
  handlePurchaseAllClick,
  totalPurchasedLeads,
}) => {
  const { t } = useTranslation();
  const { expandedFilters, toggleFilters } = useProtectedRoutesContext();
  const { page, limit } = usePageFilterUrlParams();

  return (
    <>
      <Box>
        <Box p={2}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="subtitle2" mr={1} display="inline" color="text.secondary">
                {totalLeadsFound || 0}
              </Typography>
              <Typography variant="body2" display="inline" color="text.secondary">
                {t('dashboard.campaignDetails.leadsFound')}
              </Typography>
            </Box>
            {totalLeadsSelected > 0 && (
              <Box>
                <Typography variant="body2" display="inline" ml={1}>
                  {t('dashboard.leads.selected')}
                </Typography>
                <Typography variant="subtitle2" display="inline" ml={1}>
                  {`${totalLeadsSelected} ${t('dashboard.newScraper.leadsLabel')}`}
                </Typography>
              </Box>
            )}
          </Box>

          <Box display="flex" gap={2} my={2} justifyContent="space-between" flexWrap="wrap">
            {campaignType && campaignType === 'Jobs' && (
              <LoadingButton
                disableRipple
                variant="contained"
                color="primary"
                size="medium"
                disabled={totalLeadsSelected < 1}
                onClick={() => console.log('verify', totalLeadsSelected)}
                loadingIndicator={<CircularProgress color="inherit" size={22} />}
                sx={{ flexBasis: 'calc(50% - 8px)' }}
              >
                <Typography variant="subtitle2" minWidth="140px">
                  {t('dashboard.db.verifyEmail')}
                </Typography>
              </LoadingButton>
            )}
            {!isEnoughTokens ? (
              <Tooltip title={t('dashboard.db.notEnoughTokens')} placement="top">
                <Button
                  disableRipple
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled
                  sx={{ flexBasis: 'calc(50% - 8px)' }}
                >
                  <Typography variant="subtitle2" minWidth="140px">
                    {t('dashboard.db.purchaseLeads')}
                  </Typography>
                </Button>
              </Tooltip>
            ) : hasPurchasedLeads ? (
              <Tooltip title={t('dashboard.db.alreadyPurchased')} placement="top" open={hasPurchasedLeads}>
                <Button
                  disableRipple
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled
                  sx={{ flexBasis: 'calc(50% - 8px)' }}
                >
                  <Typography variant="subtitle2" minWidth="140px">
                    {t('dashboard.db.purchaseLeads')}
                  </Typography>
                </Button>
              </Tooltip>
            ) : (
              <>
                <LoadingButton
                  disableRipple
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={
                    isLoadingPurchaseMutation ||
                    isLoadingLeads ||
                    totalLeadsFound === 0 ||
                    totalPurchasedLeads === totalLeadsFound
                  }
                  onClick={handlePurchaseAllClick}
                  loadingIndicator={<CircularProgress color="inherit" size={22} />}
                  sx={{ flexBasis: 'calc(50% - 8px)' }}
                >
                  <Typography variant="subtitle2" minWidth="140px">
                    {t('dashboard.db.purchaseAllLeads')}
                  </Typography>
                </LoadingButton>

                <LoadingButton
                  disableRipple
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={isLoadingPurchaseMutation || totalLeadsSelected < 1}
                  onClick={handlePurchaseClick}
                  loadingIndicator={<CircularProgress color="inherit" size={22} />}
                  sx={{ flexBasis: 'calc(50% - 8px)' }}
                >
                  <Typography variant="subtitle2" minWidth="140px">
                    {t('dashboard.db.purchaseLeads')}
                  </Typography>
                </LoadingButton>
              </>
            )}
            {totalLeadsSelected > 0 && (
              <Typography variant="body2" alignSelf="center" color={!isEnoughTokens ? 'red' : 'currentcolor'}>
                <Trans
                  i18nKey="dashboard.db.totalDue"
                  values={{
                    price: totalLeadsSelected,
                  }}
                  components={{
                    bold: <StyledBoldText component="span" fontSize="14px" />,
                  }}
                />
              </Typography>
            )}
          </Box>
        </Box>

        <Divider />

        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" p={2}>
            <Typography variant="subtitle2" mr={1}>
              {t('common.filtersTitle')}
            </Typography>

            <StyledIconButton onClick={toggleFilters} disableRipple>
              {expandedFilters ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </StyledIconButton>
          </Box>

          {expandedFilters && (
            <Collapse in={expandedFilters}>
              <Box p={2}>
                <CustomFilters campaignType={campaignType} refetch={refetch} />
              </Box>
            </Collapse>
          )}
        </Box>
        <Divider />
      </Box>

      <Box display="flex" height="100%">
        <ScrapekingDbDynamicDataGrid
          data={leadsData}
          handlePageChange={handlePaginationModelChange}
          paginationModel={{ page, pageSize: limit }}
          isDataLoading={isLoadingLeads}
          expandedFilters={expandedFilters}
          campaignType={campaignType}
          handleCheckboxSelection={handleSelectionChange}
        />
      </Box>
    </>
  );
};

export default memo(MobileScrapekingLeadsDbTable);
