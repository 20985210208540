import { Campaign, CampaignFiltersDto, ScrapersDto } from '@api/api';

interface SourcesObject {
  sourcesData: ScrapersDto[];
  campaignData: Campaign;
  filtersData: CampaignFiltersDto[];
}

export const prefillMatchedSources = ({ sourcesData, campaignData, filtersData }: SourcesObject) =>
  sourcesData
    .filter(source => campaignData.campaign_scrapers.some(scraper => +scraper.scraper_id === +source.scrapers_id))
    .map(source => {
      const scraperData = campaignData.campaign_scrapers.find(scraper => +scraper.scraper_id === +source.scrapers_id);
      const freeFilters = filtersData.filter(filter => !filter.price);
      const relatedFilters = campaignData?.camp_filters
        ?.filter(filter => +filter.scraper_id === +source.scrapers_id)
        .filter(f => freeFilters.find(free => free.key.toLowerCase() === f.filter_name));

      const filters = relatedFilters?.reduce(
        (acc, filter) => {
          acc[filter.filter_name] = filter.filter_val;
          return acc;
        },
        { numberOfLeads: scraperData ? scraperData.leads_count : 0 } as Record<string, string | number>,
      );

      return {
        ...source,
        filters,
      };
    });
