import { FC, memo } from 'react';
import { useBilling } from '@hooks/common/useBilling';
import { usePricingContext } from '@pages/Pricing/context';
import PlanCard from '@pages/Settings/components/BillingTab/PlanCard';

import { Box } from '@mui/material';

const BillingTab: FC = () => {
  const { period } = usePricingContext();
  const { config, isLoadingQueries } = useBilling(period);

  if (isLoadingQueries) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {config.map((card: any) => (
        <PlanCard key={card.id} {...card} />
      ))}
    </Box>
  );
};

export default memo(BillingTab);
