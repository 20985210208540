enum Tag {
  CAMPAIGN = 'Campaign',
  CAMPAIGN_FILTERS = 'CampaignFilters',
  USER = 'User',
  BILLING = 'Billing',
  SUBSCRIPTIONS = 'Subscriptions',
  PRICES = 'Prices',
  PACKAGES = 'Packages',
  DB_LEADS = 'DBLeads',
  TRANSACTIONS = 'Transactions',
  PURCHASED_LEADS = 'PurchasedLeads',
}

export default Tag;
