import React from 'react';
import ReactDOM from 'react-dom/client';
import SnackbarProvider from '@providers/SnackbarProvider';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '@components/theme';

import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import './i18n';
import '@utils/yupValidation';
import App from './App';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);
