import { useTranslation } from 'react-i18next';
import { PERIOD } from '@constants/billing';
import { MONTHS_IN_YEAR, PROCESSING_FEE } from '@constants/common';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledModal } from '@pages/CampaignDetails/styled';
import { convertFromCents } from '@pages/Settings/utils';
import { formatCurrency } from '@utils/formatCurrency';
import { capitalize } from '@utils/formatInitials';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Divider, Typography } from '@mui/material';
import { StyledIconBackButton, StyledSubtitleBox } from '@components/BillingConfirmModal/styled';

const BillingConfirmModal = NiceModal.create(
  ({
    price,
    tokens,
    period,
    description,
    isChangePlan,
  }: {
    price: number;
    tokens: string;
    period: string;
    description: string[];
    isChangePlan?: boolean;
  }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const middleIndex = Math.ceil(description.length / 2);

    const handleConfirmClick = () => {
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="small" onTouchCancel={handleCancelClick} onClose={handleCancelClick}>
        <Box padding="12px 24px">
          <Box display="flex" justifyContent="center" alignItems="center" mb={5}>
            <StyledIconBackButton disableRipple onClick={handleCancelClick}>
              <ArrowBackIcon />
            </StyledIconBackButton>
            <Typography variant="h6" textAlign="center">
              {t('billing.orderPreview.title')}
            </Typography>
          </Box>

          <StyledSubtitleBox width="100%" px={2} py={1} my={2} aria-label="tokens-per-month">
            <Typography variant="h6">{`${tokens} ${t('billing.tokensPerMonth')}`}</Typography>
          </StyledSubtitleBox>

          {!isChangePlan && (
            <Box
              width="100%"
              px={2}
              py={1}
              my={2}
              display="flex"
              justifyContent="space-between"
              aria-label="topup-per-month-price"
            >
              <Typography variant="subtitle1">{`Single topup ${t('common.price').toLowerCase()}`}</Typography>
              <Typography variant="subtitle1">
                {formatCurrency(convertFromCents(period === PERIOD.year ? price / MONTHS_IN_YEAR : price))}
              </Typography>
            </Box>
          )}

          <Box
            width="100%"
            px={2}
            py={1}
            my={2}
            display="flex"
            justifyContent="space-between"
            aria-label="processing-fee"
          >
            <Typography variant="subtitle1">{t('billing.processingFee')}</Typography>
            <Typography variant="subtitle1">
              {formatCurrency(
                convertFromCents(isChangePlan ? price * PROCESSING_FEE : (price / MONTHS_IN_YEAR) * PROCESSING_FEE),
              )}
            </Typography>
          </Box>

          {isChangePlan && (
            <Box
              width="100%"
              px={2}
              py={1}
              my={2}
              display="flex"
              justifyContent="space-between"
              aria-label="charge-period"
            >
              <Typography variant="subtitle1">{t('billing.chargePeriod')}</Typography>
              <Typography variant="subtitle1">{capitalize(period)}</Typography>
            </Box>
          )}

          {isChangePlan && (
            <Box
              width="100%"
              px={2}
              py={1}
              my={2}
              display="flex"
              justifyContent="space-between"
              aria-label="subscription-price-without-fee"
            >
              <Typography variant="subtitle1">{`${t('billing.period.month')} ${t('common.price').toLowerCase()}`}</Typography>
              <Typography variant="subtitle1">
                {formatCurrency(convertFromCents(period === PERIOD.year ? price / MONTHS_IN_YEAR : price))}
              </Typography>
            </Box>
          )}

          <Box
            width="100%"
            px={2}
            py={1}
            my={2}
            display="flex"
            justifyContent="space-between"
            aria-label="total-price-package-price-with-fee"
          >
            <Typography variant="subtitle1">{t('billing.totalPrice')}</Typography>
            <Typography variant="subtitle1">
              {formatCurrency(
                convertFromCents(
                  isChangePlan ? price * (1 + PROCESSING_FEE) : (price / MONTHS_IN_YEAR) * (1 + PROCESSING_FEE),
                ),
              )}
            </Typography>
          </Box>

          <Divider />

          <Box my={2} display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" flex={1}>
              {description.map((line, index) => {
                if (index < middleIndex) {
                  return (
                    <Box display="flex" alignItems="center" gap={1} mb={1} key={line}>
                      <Typography variant="caption">{line}</Typography>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
              {description.map((line, index) => {
                if (index >= middleIndex) {
                  return (
                    <Box display="flex" alignItems="center" gap={1} mb={1} key={line}>
                      <Typography variant="caption">{line}</Typography>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          </Box>

          <Box mt={5}>
            <Button fullWidth variant="contained" color="primary" onClick={handleConfirmClick}>
              {t('common.confirm')}
            </Button>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const BillingConfirmModalId = 'BillingConfirmModal';

NiceModal.register(BillingConfirmModalId, BillingConfirmModal);
