import { NavigateOptions, useSearchParams } from 'react-router-dom';
import { CampaignTypesDto } from '@api/api';

export const useCampaignTypeQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const campaignTypeId = searchParams.get('campaign_type') || null;

  return {
    campaignTypeId,
    handleChangeCampaignType: (value: CampaignTypesDto, navigateOpts?: NavigateOptions) => {
      searchParams.set('campaign_type', String(value.campaign_type_id));

      setSearchParams(searchParams, navigateOpts);
    },
    handleClearCampaignType: () => {
      searchParams.delete('campaign_type');

      setSearchParams(searchParams);
    },
  };
};
