import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { BASIC_INFO, SOURCE_INFO } from '@constants/formFields';
import { ROUTING } from '@constants/routing';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSteps } from '@pages/LeadScraper/hooks/useSteps';
import { basicInfoSchema } from '@pages/LeadScraper/schemas';
import { BasicInfoData, Scheduling, ScraperData, ScraperProps } from '@pages/LeadScraper/types';
import { roundToNextFive, TODAY } from '@utils/formatTime';

const LeadScraperContext = createContext<ReturnType<typeof useLeadScraperContextValue> | null>(null);

const useLeadScraperContextValue = ({
  campaignId,
  scraperSubtitleLocale,
  submitButtonLocale,
  breadCrumbsLocale,
  editable,
}: {
  campaignId: string | null;
  scraperSubtitleLocale: string;
  submitButtonLocale: string;
  breadCrumbsLocale: string;
  editable?: boolean;
}) => {
  const {
    activeStep,
    handleNext,
    handleBack,
    completedSteps,
    inCompleteStep,
    completeStep,
    mobileShowSettings,
    setMobileShowSettings,
  } = useSteps();
  const { campaignId: id } = useParams();

  const basicInfoForm = useForm<BasicInfoData<string>>({
    resolver: yupResolver(basicInfoSchema),
    defaultValues: {
      [BASIC_INFO.TYPE]: campaignId ?? '',
      [BASIC_INFO.CAMPAIGN_NAME]: '',
      [BASIC_INFO.CAMPAIGN_DESCRIPTION]: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const sourceSettingsForm = useForm<{ autocomplete: ScraperData[] | any[] }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [SOURCE_INFO.AUTOCOMPLETE]: [],
    },
  });

  const filtersForm = useForm<{
    filters: {
      paidFilters: Record<string, string>;
    };
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const schedulingForm = useForm<Scheduling>({
    defaultValues: {
      repeat: {
        now: true,
        once: false,
        weekly: false,
        monthly: false,
      },
      numberOfWeeks: 1,
      numberOfMonths: 1,
      dayOfTheMonth: '1st',
      days: [],
      startDate: TODAY,
      startTime: roundToNextFive(new Date(TODAY)),
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const backLink = editable ? `/${ROUTING.CAMPAIGN_DETAILS}/${id}` : ROUTING.ROOT;

  return {
    basicInfoForm,
    sourceSettingsForm,
    activeStep,
    handleNext,
    handleBack,
    completedSteps,
    inCompleteStep,
    completeStep,
    filtersForm,
    scraperSubtitleLocale,
    submitButtonLocale,
    backLink,
    breadCrumbsLocale,
    isEdit: editable,
    schedulingForm,
    mobileShowSettings,
    setMobileShowSettings,
  };
};

export const useLeadScraperContext = () => {
  const context = useContext(LeadScraperContext);

  if (!context) throw new Error('useLeadScraperContext must be inside LeadScraperProvider');

  return context;
};

export const LeadScraperProvider: FC<
  PropsWithChildren<{ campaignId: string | null } & Omit<ScraperProps<string>, 'title'>>
> = ({ children, campaignId, scraperSubtitleLocale, submitButtonLocale, breadCrumbsLocale, editable }) => {
  const value = useLeadScraperContextValue({
    campaignId,
    scraperSubtitleLocale,
    submitButtonLocale,
    breadCrumbsLocale,
    editable,
  });

  return <LeadScraperContext.Provider value={value}>{children}</LeadScraperContext.Provider>;
};
