/* eslint-disable @typescript-eslint/no-shadow */
import { createTheme } from '@mui/material';

import { colors, error, primary, secondary, success } from './colors';
import InterBold from './fonts/Inter-Bold.ttf'; // 700
import Inter from './fonts/Inter-Medium.ttf'; // 500
import InterRegular from './fonts/Inter-Regular.ttf'; // 400
import InterSemiBold from './fonts/Inter-SemiBold.ttf'; // 600
import { MuiAppBar } from './MuiAppBar';
import { MuiButton } from './MuiButton';
import { MuiTextField } from './MuiTextField';
import { typography } from './typography';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

export const important = (cssValue: string | number) => `${cssValue} !important`;

export const theme = createTheme({
  colors,
  palette: {
    // blue
    primary: {
      main: primary.main,
      dark: primary.dark,
      light: primary.light,
      contrastText: colors.white,
    },
    // dark
    secondary: {
      main: secondary.main,
      dark: secondary.dark,
      light: secondary.light,
      contrastText: colors.white,
    },
    success: {
      main: success.main,
      dark: success.dark,
      light: success.light,
    },
    error: {
      main: error.main,
      dark: error.dark,
      light: error.light,
    },
  },
  typography,
  breakpoints: {
    values: {
      xs: 576,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 2160,
      xxl: 2880,
    },
  },
  components: {
    MuiAppBar,
    MuiButton,
    MuiTextField,
    MuiCssBaseline: {
      styleOverrides: theme => `
        @font-face {
          font-family: 'InterRegular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter'), local('InterRegular'), url(${InterRegular}) format('opentype');
        }

        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Inter'), local('Inter'), url(${Inter}) format('opentype');
        }
        
        @font-face {
          font-family: 'InterSemiBold';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Inter'), local('InterSemiBold'), url(${InterSemiBold}) format('opentype');
        }

        @font-face {
          font-family: 'InterBold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Inter'), local('InterBold'), url(${InterBold}) format('opentype');
        }

        body {
          font-family: 'Inter', 'Helvetica', 'Arial', 'sans-serif';
          color: ${theme.colors.text}
        }
      `,
    },
  },
});

theme.shadows.unshift('0px 26px 58px 0px rgba(0,0,0,0.22), 0px 5px 14px 0px rgba(0,0,0,0.18);');
