import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLeadScraperContext } from '@pages/LeadScraper/context';
import { formattedDate, formattedTime } from '@utils/formatTime';

import { Box, Typography } from '@mui/material';

import { StyledAreaBox } from './styled';

const SchedulingSummary: FC = () => {
  const { t } = useTranslation();
  const { schedulingForm, activeStep } = useLeadScraperContext();
  const formData = schedulingForm.watch();
  const repeatTitle = Object.entries(formData.repeat).find(([, value]) => value)?.[0];
  const selectedDays = formData.days
    .flatMap(dayObj =>
      Object.entries(dayObj)
        .filter(([, value]) => value)
        .map(([key]) => key),
    )
    .join(', ');

  return (
    <>
      {activeStep > 2 && !formData.repeat.now && (
        <StyledAreaBox>
          <Typography variant="subtitle2">{t('dashboard.newScraper.scheduling.title')}</Typography>
          <Box display="flex" flexWrap="wrap">
            <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
              <Typography variant="caption" color="text.secondary">
                {t('dashboard.newScraper.scheduling.repeat')}
              </Typography>
              <Typography variant="body2">{t(`dashboard.newScraper.scheduling.${String(repeatTitle)}`)}</Typography>
            </Box>

            {formData.repeat.weekly && (
              <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
                <Typography variant="caption" color="text.secondary">
                  {t('dashboard.newScraper.scheduling.numberOfWeeks')}
                </Typography>
                <Typography variant="body2">{formData.numberOfWeeks}</Typography>
              </Box>
            )}

            {formData.repeat.monthly && (
              <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
                <Typography variant="caption" color="text.secondary">
                  {t('dashboard.newScraper.scheduling.numberOfMonths')}
                </Typography>
                <Typography variant="body2">{formData.numberOfMonths}</Typography>
              </Box>
            )}

            {!formData.repeat.monthly && (
              <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
                <Typography variant="caption" color="text.secondary">
                  {t('dashboard.newScraper.scheduling.startDate')}
                </Typography>
                <Typography variant="body2">{formattedDate(formData.startDate as Date)}</Typography>
              </Box>
            )}

            {formData.repeat.monthly && (
              <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
                <Typography variant="caption" color="text.secondary">
                  {t('dashboard.newScraper.scheduling.dateOfMonth')}
                </Typography>
                <Typography variant="body2">{formData.dayOfTheMonth}</Typography>
              </Box>
            )}

            <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
              <Typography variant="caption" color="text.secondary">
                {t('dashboard.newScraper.scheduling.startTime')}
              </Typography>
              <Typography variant="body2">{formattedTime(formData.startTime as Date)}</Typography>
            </Box>

            {formData.repeat.weekly && selectedDays.length > 0 && (
              <Box display="flex" flexDirection="column" flexBasis="50%" maxWidth="50%" pt={1}>
                <Typography variant="caption" color="text.secondary">
                  {t('dashboard.newScraper.scheduling.days.title')}
                </Typography>
                <Typography variant="body2">{selectedDays}</Typography>
              </Box>
            )}
          </Box>
        </StyledAreaBox>
      )}
    </>
  );
};

export default memo(SchedulingSummary);
