import { FC, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Typography } from '@mui/material';

import { StyledBox, StyledButton } from './styled';

interface BarProps {
  daysLeft: number;
  redirectLink: string;
  isMobile: boolean;
}

const TrialNotificationBar: FC<BarProps> = ({ daysLeft, redirectLink, isMobile }) => {
  const { t } = useTranslation();

  return (
    <StyledBox display="flex" justifyContent="center" alignItems="center" gap={isMobile ? 1 : 3} mt={1.5}>
      {daysLeft > 1 && (
        <Typography variant="h6" flex={isMobile ? 1 : 'inherit'}>
          <Trans i18nKey={t('billing.trialEnds')} components={{ daysLeft: <>{daysLeft}</> }} />
        </Typography>
      )}
      {daysLeft < 1 && (
        <Typography variant="h6" flex={isMobile ? 1 : 'inherit'}>
          {t('billing.trialEnded')}
        </Typography>
      )}
      <StyledButton
        disableRipple
        disableFocusRipple
        disableTouchRipple
        component={RouterLink}
        to={redirectLink}
        style={{ flex: isMobile ? 0.5 : 'inherit' }}
      >
        {t('billing.pricingPackages')}
      </StyledButton>
    </StyledBox>
  );
};

export default memo(TrialNotificationBar);
