import { NO, NOT_AVAILABLE, YES } from '@constants/common';
import { withTooltip } from '@hooks/common/useDynamicColumns/utils/withTooltip';
import { GoodsLeadEntity } from '@hooks/types';
import { formatCurrency } from '@utils/formatCurrency';
import { get } from 'lodash-es';

import { Typography } from '@mui/material';

export const createGoodsColumns = () => [
  {
    field: 'asin',
    headerName: 'ASIN',
    headerClassName: 'super-bold--header',
    width: 150,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: GoodsLeadEntity }) => withTooltip(get(row, 'data.asin', NOT_AVAILABLE)),
  },
  {
    field: 'availability',
    headerName: 'Availability',
    headerClassName: 'super-bold--header',
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: GoodsLeadEntity }) => {
      const result = get(row, 'data.availability');
      return <Typography variant="body2">{result ? YES : NO}</Typography>;
    },
  },
  {
    field: 'category',
    headerName: 'Category',
    headerClassName: 'super-bold--header',
    width: 200,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: GoodsLeadEntity }) => withTooltip(get(row, 'data.category', NOT_AVAILABLE)),
  },
  {
    field: 'price',
    headerName: 'Price',
    headerClassName: 'super-bold--header',
    width: 200,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: GoodsLeadEntity }) => {
      const result = get(row, 'data.price', NOT_AVAILABLE);
      return withTooltip(result === NOT_AVAILABLE ? result : formatCurrency(result));
    },
  },
  {
    field: 'title',
    headerName: 'Title',
    headerClassName: 'super-bold--header',
    width: 800,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: GoodsLeadEntity }) => withTooltip(get(row, 'data.title', NOT_AVAILABLE)),
  },
];
