import { COUNTDOWN_DATE, TRIAL_DAYS } from '@constants/common';
import { addSeconds, differenceInDays, format, isSameDay, parseISO } from 'date-fns';

export const TODAY = new Date();

export const formatCountdownTime = (countdown: number, message: string) => {
  if (countdown === 0) {
    return '0';
  }

  const endDate = addSeconds(COUNTDOWN_DATE, countdown);
  const formattedCountdown = format(endDate, 'mm:ss');

  return `(${message} ${formattedCountdown})`;
};

export const convertIsoToReadable = (isoDate: string) => {
  const dateObject = parseISO(isoDate);
  return format(dateObject, 'dd MMM, yy');
};

export const convertIsoTrialDaysLeft = (isoDate: string) => {
  const dateObject = parseISO(isoDate);
  const daysPassed = differenceInDays(TODAY, dateObject);
  const daysLeft = TRIAL_DAYS - daysPassed;
  return daysLeft >= 0 ? daysLeft : 0;
};

export const formattedDate = (date: Date) => format(date, 'dd MMMM yyyy');
export const formattedTime = (date: Date) => format(date, 'hh:mmaaa');
export const formattedDateTime = (date: Date) => format(date, 'dd MMMM yyyy hh:mmaaa');

export const formatDate = (date: Date | null) => {
  if (date && isSameDay(date, TODAY)) {
    return 'Today';
  }
  return date ? format(date, 'MM/dd/yyyy') : '';
};

export const roundToNextFive = (date: Date): Date => {
  const minutes = date.getMinutes();
  const roundedMinutes = Math.ceil(minutes / 5) * 5;
  if (roundedMinutes === 60) {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0);
  } else {
    date.setMinutes(roundedMinutes);
  }
  date.setSeconds(0);
  return date;
};
