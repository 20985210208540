import styled from '@mui/system/styled';

const SmallLogo = styled(props => (
  <svg width="33" height="18" viewBox="0 0 33 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.04 8.48V1.688L3.824 0.079999H9.896L12.68 1.688V4.88H9.32V2.96H4.4V6.968H9.896L12.68 8.576V15.512L9.896 17.12H3.584L0.8 15.512V12.32H4.16V14.24H9.32V10.088H3.824L1.04 8.48ZM17.7666 17V0.199999H21.1266V7.76H21.1746L27.4386 0.199999H31.4226L24.7986 8.072L32.1666 17H28.1346L22.7826 10.472L21.1266 12.416V17H17.7666Z"
      fill="#212121"
    />
  </svg>
))({});

export default SmallLogo;
