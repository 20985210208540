import { useSearchParams } from 'react-router-dom';

export const useCustomFiltersQueryParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dynamicParam = searchParams.get('custom') || '';

  return {
    dynamicParam,
    setDynamicParam: (value: string | number) => {
      searchParams.set('custom', String(value));
      setSearchParams(searchParams);
    },
    removeDynamicParam: () => {
      searchParams.delete('custom');
      setSearchParams(searchParams);
    },
  };
};
