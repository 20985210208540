import { FC, memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useCampaignFilters } from '@hooks/api/useCampaignFilters';
import { useLeadScraperContext } from '@pages/LeadScraper/context';
import { StepContentProps } from '@pages/LeadScraper/types';
import { StyledAreaBox } from '@pages/styled';

import EditableBlock from '../EditableBlock';

import FiltersForm from './FiltersForm';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const ScraperFilters: FC<StepContentProps> = ({ isActive, isCompleted, handleBack, handleNext, campaignId }) => {
  const { filtersForm } = useLeadScraperContext();
  const { data: filtersData, isLoading: isFiltersLoading } = useCampaignFilters(campaignId);
  const { isLargerScreen } = useScreenSizeContext();

  return (
    <StyledAreaBox ml="8px" active={isActive} sx={{ minWidth: isLargerScreen ? '580px' : 'inherit' }}>
      <EditableBlock
        isCompleted={isCompleted}
        handleClick={handleBack}
        title="dashboard.newScraper.settings.filtersTitle"
        subtitle="dashboard.newScraper.settings.filtersSubtitle"
      />

      {isActive && (
        <FormProvider {...filtersForm}>
          <form onSubmit={filtersForm.handleSubmit(handleNext)}>
            <FiltersForm data={filtersData} isLoading={isFiltersLoading} />
          </form>
        </FormProvider>
      )}
    </StyledAreaBox>
  );
};

export default memo(ScraperFilters);
