import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CampaignFiltersDto,
  UpdateCampaignApiArg,
  useDeleteCampaignMutation,
  useGetCampaignQuery,
  useUpdateCampaignMutation,
} from '@api/api';
import { ERROR, SUCCESS } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { ROUTING } from '@constants/routing';
import { useCampaignFilters } from '@hooks/api/useCampaignFilters';
import { findPricedFilters } from '@pages/CampaignDetails/utils';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';

import AppliedFilters from './components/AppliedFilters';
import BasicInformation from './components/BasicInformation';
import ScraperSources from './components/ScraperSources';
import Title from './components/Title';

const GeneralInfo: FC = () => {
  const { campaignId } = useParams();
  const { t } = useTranslation();

  const { data: campaignData, isLoading: isCampaignsLoading } = useGetCampaignQuery({ id: campaignId as string });
  const { data: filtersData, isLoading: isFiltersLoading } = useCampaignFilters(
    String(campaignData?.campaign_type_id),
    !campaignData?.campaign_type_id,
  );

  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [updateCampaignMutation, { isLoading: isLoadingCampaignUpdate }] = useUpdateCampaignMutation();
  const [deleteCampaignMutation, { isLoading: isLoadingCampaignDelete }] = useDeleteCampaignMutation();
  const pricedFilters = findPricedFilters(campaignData?.camp_filters, filtersData as CampaignFiltersDto[], true);

  const handleToggleScraper = async () => {
    try {
      if (campaignData) {
        const payload: UpdateCampaignApiArg = {
          id: String(campaignId),
          updateCampaignDto: {
            name: campaignData.name,
            status: campaignData.status > 0 ? 0 : 1,
            description: campaignData.description,
          },
        };
        await updateCampaignMutation(payload);
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const handleDeleteScraper = async () => {
    try {
      if (campaignId) {
        await deleteCampaignMutation({ id: campaignId }).unwrap();
        snackbar.enqueueSnackbar(t('common.campaignDeleted'), { variant: SUCCESS });
        navigate(ROUTING.ROOT);
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const isDisabledActions = [
    isCampaignsLoading,
    isFiltersLoading,
    isLoadingCampaignUpdate,
    isLoadingCampaignDelete,
  ].some(Boolean);

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Title
        handleToggleScraper={handleToggleScraper}
        campaignData={campaignData}
        isDisabledActions={isDisabledActions}
        handleDelete={handleDeleteScraper}
      />
      <BasicInformation campaignData={campaignData} />
      {pricedFilters.length ? <AppliedFilters pricedFilters={pricedFilters} /> : null}
      <ScraperSources campaignData={campaignData} filtersData={filtersData} pricedFilters={pricedFilters} />
    </Box>
  );
};

export default memo(GeneralInfo);
