import { FC, Fragment, memo } from 'react';
import { BalanceDto, PaginatedCampaignsDto } from '@api/api';

import { Box } from '@mui/material';

import InfoBlock from './InfoBlock';

const ScrapersInfo: FC<{ balanceData?: BalanceDto; campaigns?: PaginatedCampaignsDto; isLargerScreen: boolean }> = ({
  balanceData,
  campaigns,
  isLargerScreen,
}) => {
  const prefillConfig = [
    {
      id: 1,
      amount: balanceData?.token || 0,
      title: 'dashboard.scraperDetails.tokensAvailable',
      isPricingRedirect: true,
    },
    {
      id: 2,
      amount: balanceData?.reserved || 0,
      title: 'dashboard.scraperDetails.tokensInScrapers',
    },
    {
      id: 3,
      amount: campaigns?.data.filter(c => c.status > 0).length || 0,
      title: 'dashboard.scraperDetails.activeScrapers',
    },
  ];

  return (
    <Box display="flex" gap={3} width="100%" flexDirection={isLargerScreen ? 'row' : 'column'}>
      {prefillConfig.map(block => (
        <Fragment key={block.id}>
          <InfoBlock {...block} />
        </Fragment>
      ))}
    </Box>
  );
};

export default memo(ScrapersInfo);
