import { FC, memo } from 'react';
import { useCampaignDetailsContext } from '@pages/CampaignDetails/context';

import { Box } from '@mui/material';

import { Indicators } from '../enums';

import GeneralInfo from './GeneralInfo';
import Leads from './Leads';

const ActiveTab: FC = () => {
  const { tab } = useCampaignDetailsContext();
  return (
    <Box>
      {tab === Indicators.GENERAL_INFO ? <GeneralInfo /> : null}
      {tab === Indicators.LEADS ? <Leads /> : null}
    </Box>
  );
};

export default memo(ActiveTab);
