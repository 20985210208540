import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';

import DownloadIcon from '@mui/icons-material/Download';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Button, Collapse, Divider, Typography } from '@mui/material';
import CustomFilters from '@components/CustomFilters';
import { ScrapekingDbDynamicDataGrid } from '@components/DynamicDataGrid';
import { StyledIconButton } from '@components/LeadsTable/styled';
import { MobileMyLeadsTableProps } from '@components/LeadsTable/types';
import LoadingIconButton from '@components/LoadingIconButton';

import { useProtectedRoutesContext } from '@/shared/ProtectedRoutesContext';

const MobileMyLeadsTable: FC<MobileMyLeadsTableProps> = ({
  handlePaginationModelChange,
  handleSelectionChange,
  totalLeadsFound,
  totalLeadsSelected = 0,
  refetch,
  leadsData,
  campaignType,
  isLoadingLeads,
  isLoadingCsv,
  handleDownloadCsv,
}) => {
  const { t } = useTranslation();
  const { expandedFilters, toggleFilters } = useProtectedRoutesContext();
  const { page, limit } = usePageFilterUrlParams();

  return (
    <>
      <Box>
        <Box p={2}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="subtitle2" mr={1} display="inline" color="text.secondary">
                {totalLeadsFound || 0}
              </Typography>
              <Typography variant="body2" display="inline" color="text.secondary">
                {t('dashboard.campaignDetails.leadsFound')}
              </Typography>
            </Box>
            {totalLeadsSelected > 0 && (
              <Box>
                <Typography variant="body2" display="inline" ml={1}>
                  {t('dashboard.leads.selected')}
                </Typography>
                <Typography variant="subtitle2" display="inline" ml={1}>
                  {`${totalLeadsSelected} ${t('dashboard.newScraper.leadsLabel')}`}
                </Typography>
              </Box>
            )}
          </Box>

          <Box display="flex" gap={2} my={2} justifyContent="space-between">
            <Button disableRipple variant="contained" color="primary" size="medium">
              <Typography variant="subtitle2" minWidth="140px">
                {t('dashboard.campaignDetails.addToCampaign')}
              </Typography>
            </Button>
            <LoadingIconButton
              loaderSize="1.5rem"
              onClick={handleDownloadCsv}
              loading={isLoadingCsv}
              loaderColor="secondary"
              size="medium"
              color="primary"
              disabled={!totalLeadsFound}
            >
              <DownloadIcon />
            </LoadingIconButton>
          </Box>
        </Box>

        <Divider />

        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" p={2}>
            <Typography variant="subtitle2" mr={1}>
              {t('common.filtersTitle')}
            </Typography>

            <StyledIconButton onClick={toggleFilters} disableRipple>
              {expandedFilters ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </StyledIconButton>
          </Box>

          {expandedFilters && (
            <Collapse in={expandedFilters}>
              <Box p={2}>
                <CustomFilters campaignType={campaignType} refetch={refetch} />
              </Box>
            </Collapse>
          )}
        </Box>
        <Divider />
      </Box>

      <Box display="flex" height="100%">
        <ScrapekingDbDynamicDataGrid
          data={leadsData}
          handlePageChange={handlePaginationModelChange}
          paginationModel={{ page, pageSize: limit }}
          isDataLoading={isLoadingLeads}
          expandedFilters={expandedFilters}
          campaignType={campaignType}
          handleCheckboxSelection={handleSelectionChange}
        />
      </Box>
    </>
  );
};

export default memo(MobileMyLeadsTable);
