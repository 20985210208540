import { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CAMPAIGN_ID } from '@constants/common';
import { useCampaignSources } from '@hooks/api/useCampaignSources';
import { useGetSearchParam } from '@hooks/common/useGetSearchParams';
import { useLeadScraperContext } from '@pages/LeadScraper/context';
import { compareCampaignType } from '@pages/LeadScraper/utils';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Box, Step, Stepper, Typography } from '@mui/material';
import StepperStepIcon from '@components/StepperStepIcon/StepperStepIcon';

import BasicInformation from './components/BasicInformation';
import ScraperFilters from './components/ScraperFilters';
import ScraperScheduling from './components/ScraperScheduling';
import SourceSettings from './components/SourceSettings';
import { StyledLink, StyledStepConnector, StyledStepContent, StyledStepLabel } from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const steps = [
  {
    id: 1,
    Component: BasicInformation,
  },
  {
    id: 2,
    Component: SourceSettings,
  },
  {
    id: 3,
    Component: ScraperFilters,
  },
  {
    id: 4,
    Component: ScraperScheduling,
  },
];

const ScraperFlow: FC = () => {
  const { t } = useTranslation();
  const {
    activeStep,
    handleNext,
    handleBack,
    completedSteps,
    filtersForm,
    sourceSettingsForm,
    scraperSubtitleLocale,
    backLink,
    breadCrumbsLocale,
  } = useLeadScraperContext();
  const campaignId: string | null = useGetSearchParam(CAMPAIGN_ID);
  const { data: sourcesData } = useCampaignSources(campaignId as string);
  const { autocomplete } = sourceSettingsForm.watch();
  const { isLargerScreen } = useScreenSizeContext();

  useEffect(() => {
    if (campaignId && autocomplete?.length > 0) {
      const isEqual = compareCampaignType(sourcesData, autocomplete);

      if (!isEqual) {
        sourceSettingsForm.reset();
        filtersForm.reset();
      }
    }
  }, [autocomplete, filtersForm, campaignId, sourceSettingsForm, sourcesData]);

  return (
    <Box ml="70px">
      <Box display="flex" mb={3} ml="-70px">
        <StyledLink variant="body2" mr={1} color="text.secondary" fontFamily="Inter" to={backLink} component={Link}>
          {t(breadCrumbsLocale)}
        </StyledLink>
        <ChevronRightRoundedIcon fontSize="small" />
        <Typography variant="body2" ml={1} color="text.primary" fontFamily="Inter">
          {t(scraperSubtitleLocale)}
        </Typography>
      </Box>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={isLargerScreen ? <StyledStepConnector /> : null}
      >
        {steps.map((step, index) => {
          const { Component, id } = step;
          return (
            <Step key={id} expanded>
              <StyledStepLabel
                StepIconComponent={StepperStepIcon}
                isActive={activeStep >= index}
                isMobile={!isLargerScreen}
              >{`${t('dashboard.newScraper.step').toUpperCase()} ${id}`}</StyledStepLabel>
              <StyledStepContent
                isActive={activeStep > index}
                isLast={steps.length - 1 === index}
                isMobile={!isLargerScreen}
              >
                <Component
                  isActive={activeStep + 1 === id}
                  isCompleted={completedSteps.includes(index)}
                  handleNext={handleNext}
                  handleBack={() => handleBack(activeStep - index)}
                  campaignId={campaignId as string}
                />
              </StyledStepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default memo(ScraperFlow);
