import { Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledMenu = styled(Menu)<MenuProps & { theme?: Theme; width?: string }>(({ theme, width }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 200,
    width: width || 'inherit',
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export const StyledMenuItemSelectableText = styled(MenuItem)<MenuItemProps>({
  cursor: 'text',
  pointerEvents: 'stroke',
  userSelect: 'text',
  WebkitUserSelect: 'text', // Use camelCase for vendor prefixes
  MozUserSelect: 'text', // Use camelCase for vendor prefixes
  '&:hover': {
    backgroundColor: 'transparent',
  },
});
