import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { useTabs } from '@hooks/common/useTabs';
import { Indicators } from '@pages/CampaignDetails/enums';

const CampaignDetailsContext = createContext<ReturnType<typeof useCampaignDetailsContextValue> | null>(null);

const useCampaignDetailsContextValue = () => {
  const { tab, handleChange, setTab } = useTabs(Indicators.GENERAL_INFO, Indicators);
  const [sourceIndex, setSourceIndex] = useState(0);
  return { tab, handleChange, setTab, sourceIndex, setSourceIndex };
};

export const useCampaignDetailsContext = () => {
  const context = useContext(CampaignDetailsContext);

  if (!context) throw new Error('useCampaignDetailsContext must be inside CampaignDetailsProvider');

  return context;
};

export const CampaignDetailsProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useCampaignDetailsContextValue();

  return <CampaignDetailsContext.Provider value={value}>{children}</CampaignDetailsContext.Provider>;
};
