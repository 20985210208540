import { BaseQueryApi, BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { clearConfirm, logoutSuccess, confirmSuccess } from '@store/authSlice';
import { asyncDebounce } from '@utils/asyncDebounce';
import Tag from './tag';
import { REFRESH_TOKEN_URL, USER_ACCESS_TOKEN, USER_REFRESH_TOKEN } from '@constants/auth';
import { tokenStorage } from '@utils/tokenStorage';

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_APP_API_URL,
  prepareHeaders: headers => {
    const token = tokenStorage.getToken(USER_ACCESS_TOKEN);
    if (token) headers.set('Authorization', `Bearer ${token}`);

    return headers;
  },
});

/** It may happen that refresh token function will be called multiple times in parallel, for example
 * when we open new page and several API calls are executed simultaneously.
 * We do not want to make refresh token API call several times, therefore we debounce this function.
 * It will be executed only once within 5 seconds, only the first call will be executed.
 */
const refreshJwtTokenDebounced = asyncDebounce(
  async (api: BaseQueryApi, extraOptions: {}) =>
    baseQuery(
      {
        url: `${import.meta.env.VITE_APP_API_URL}/${REFRESH_TOKEN_URL}`,
        method: 'POST',
        body: {
          refreshToken: tokenStorage.getToken(USER_REFRESH_TOKEN),
        } as any,
      },
      api,
      extraOptions,
    ),
  5000,
  { leading: true, trailing: false },
);

const baseQueryWithRefreshToken: BaseQueryFn = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    api.dispatch(clearConfirm());
  } else {
    api.dispatch(confirmSuccess());
  }

  if (result?.error) {
    if (
      result.error.status === 401 ||
      (result.error as any).originalStatus === 401 // in case API response with text instead of json
    ) {
      const refreshResult = await refreshJwtTokenDebounced(api, extraOptions);
      const data = refreshResult?.data as any | undefined;
      if (data) {
        tokenStorage.setToken(USER_ACCESS_TOKEN, data.accessToken);
        tokenStorage.setToken(USER_REFRESH_TOKEN, data.refreshToken);
        result = await baseQuery(args, api, extraOptions);
      } else {
        tokenStorage.cleanup();
        api.dispatch(logoutSuccess());
      }
    }
  }

  return result;
};

export const emptyBaseSplitApi = createApi({
  baseQuery: baseQueryWithRefreshToken,
  tagTypes: Object.values(Tag),
  endpoints: () => ({}),
});

export const invalidateAllTags = () => emptyBaseSplitApi.util.invalidateTags(Object.values(Tag));
