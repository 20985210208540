import { blueGrey } from '@mui/material/colors';
import { styled, Theme } from '@mui/material/styles';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)<DataGridProps & { expandedFilters?: boolean; theme?: Theme }>(
  ({ expandedFilters, theme }) => ({
    '--unstable_DataGrid-radius': 0,
    borderRight: 'transparent',
    borderTop: 'transparent',
    borderLeft: expandedFilters ? `1px solid ${theme.colors.background.disabledButton}` : 'transparent',
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: blueGrey[50],
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-virtualScroller': {
      minHeight: '200px',
    },
  }),
);
