import { FC, memo } from 'react';
import { useLeadsDatabaseContext } from '@pages/LeadsDatabase/context';

import { Box } from '@mui/material';

import { Tabs } from '../enums';

import MyLeads from './MyLeads';
import ScrapekingDb from './ScrapekingDb';

const ActiveTab: FC = () => {
  const { tab } = useLeadsDatabaseContext();
  return (
    <Box>
      {tab === Tabs.MY_LEADS ? <MyLeads /> : null}
      {tab === Tabs.SCRAPEKING_DB ? <ScrapekingDb /> : null}
    </Box>
  );
};

export default memo(ActiveTab);
