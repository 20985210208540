import { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { useUser } from '@hooks/api/useUser';
import { RootState } from '@store/store';

import { Box } from '@mui/material';

const PrivatePreviewLayout: FC<PropsWithChildren> = ({ children }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const { data: user } = useUser();

  return (
    <Box style={authState.isConfirmed === null ? { visibility: 'hidden' } : undefined}>{user ? children : null}</Box>
  );
};

export default PrivatePreviewLayout;
