import { User } from '@api/api';

export const formatInitials = (name: string): string =>
  name
    .split(' ')
    .map(word => word.charAt(0))
    .join('')
    .toUpperCase();

export const getFullName = (userData: User | undefined) => `${userData?.firstname || ''} ${userData?.lastname || ''}`;
export const capitalize = (str?: string | string[]) => {
  if (!str) return '';

  const capitalizeWord = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

  if (Array.isArray(str)) {
    return str.map(word => capitalizeWord(word)).join(' ');
  }

  return str
    .split(' ')
    .map(word => capitalizeWord(word))
    .join(' ');
};
