import { FC, memo } from 'react';
import { UpdateCampaignApiArg, useUpdateCampaignMutation } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import PlayDisabledIcon from '@mui/icons-material/PlayDisabled';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { Box, IconButton } from '@mui/material';

import { ConfirmModalId } from '@/shared/ConfirmModal/ConfirmModal';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

interface ControlsProps {
  status: number;
  campaignId: number;
  name: string;
  description: string;
  campaignStart: string;
}

const Controls: FC<ControlsProps> = ({ status, campaignId, name, description, campaignStart }) => {
  const snackbar = useSnackbar();
  const { isLargerScreen } = useScreenSizeContext();

  const [updateCampaignMutation] = useUpdateCampaignMutation();

  const handleDelete = () => {}; // ToDo integrate deleteCampaign later

  const handleStop = async () => {
    try {
      const payload: UpdateCampaignApiArg = {
        id: String(campaignId),
        updateCampaignDto: {
          name,
          status: status > 0 ? 0 : 1,
          description,
        },
      };
      await updateCampaignMutation(payload);
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const isScheduled = new Date(campaignStart) > new Date();

  return (
    <Box display="flex" justifyContent={isLargerScreen ? 'center' : 'start'} gap={1} flex={1}>
      {status < 2 ? (
        <IconButton
          onClick={async event => {
            event.stopPropagation();
            const result = await NiceModal.show(ConfirmModalId, {
              title: 'dashboard.campaignDetails.stopCampaignModalTitle',
            });
            if (result) handleStop();
          }}
          disabled={!status || isScheduled}
        >
          {isScheduled ? (
            <ScheduleRoundedIcon color="secondary" />
          ) : status ? (
            <StopCircleIcon color="error" />
          ) : (
            <PlayDisabledIcon color="secondary" />
          )}
        </IconButton>
      ) : (
        <Box minWidth={40} />
      )}
      <IconButton
        onClick={async event => {
          event.stopPropagation();
          const result = await NiceModal.show(ConfirmModalId, {
            title: 'dashboard.campaignDetails.deleteCampaignModalTitle',
          });
          if (result) handleDelete();
        }}
      >
        <DeleteForeverRoundedIcon color="error" />
      </IconButton>
    </Box>
  );
};

export default memo(Controls);
