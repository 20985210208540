import { FC, memo } from 'react';
import { StyledOverflowHiddenTypography } from '@pages/styled';

import { Tooltip } from '@mui/material';

const LabelWithTooltip: FC<{ title: string }> = ({ title }) => (
  <Tooltip title={title}>
    <StyledOverflowHiddenTypography variant="body2">{title}</StyledOverflowHiddenTypography>
  </Tooltip>
);

export default memo(LabelWithTooltip);
