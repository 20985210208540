import { FC, memo, MouseEvent, useEffect } from 'react';
import { CampaignTypesDto, useGetMyLeadsQuery } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { useCampaignTypes } from '@hooks/api/useCampaignTypes';
import { useCustomFiltersQueryParam } from '@hooks/common/useDynamicFilters/useCustomFiltersQueryParam';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';
import { transformQuery } from '@pages/CampaignDetails/utils';
import ScrapeTypesList from '@pages/LeadsDatabase/components/ScrapeTypesList';
import { useCampaignTypeQueryParams } from '@pages/LeadsDatabase/hooks/useCampaignTypeQueryParams';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';
import qs from 'qs';

import { MyLeadsTable } from '@components/LeadsTable';
import { ExtendedPaginatedLeadsData } from '@components/LeadsTable/types';

const MyLeads: FC = () => {
  const { page, limit } = usePageFilterUrlParams();
  const { dynamicParam } = useCustomFiltersQueryParam();
  const { data: campaignTypesData } = useCampaignTypes();
  const { campaignTypeId, handleChangeCampaignType } = useCampaignTypeQueryParams();
  const snackbar = useSnackbar();

  const serializedFilters =
    dynamicParam && encodeURIComponent(qs.stringify(transformQuery(dynamicParam), { encode: false }));

  const handleChange = (_: MouseEvent<HTMLElement>, newAlignment: CampaignTypesDto) => {
    handleChangeCampaignType(newAlignment);
  };

  const {
    data: myLeadsData,
    isLoading: isLoadingMyLeads,
    isFetching: isFetchingMyLeads,
    refetch: refetchMyLeads,
    error: myLeadsError,
  } = useGetMyLeadsQuery(
    {
      page,
      limit,
      ...(campaignTypeId && { campaignType: +campaignTypeId }),
      ...(dynamicParam && { filters: serializedFilters }),
    },
    { refetchOnMountOrArgChange: true, skip: !campaignTypeId },
  );

  const errorArray = [myLeadsError];
  const isLoading = [isLoadingMyLeads, isFetchingMyLeads].some(Boolean);

  if (errorArray.some(Boolean)) {
    const error = errorArray.filter(err => err);
    snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
  }

  useEffect(() => {
    if (campaignTypesData) {
      handleChangeCampaignType(campaignTypesData[0]);
    }
  }, []);

  return (
    <>
      <ScrapeTypesList handleChange={handleChange} active={campaignTypeId as string} data={campaignTypesData} />
      <MyLeadsTable
        totalLeadsFound={myLeadsData?.total}
        leadsData={myLeadsData as ExtendedPaginatedLeadsData}
        isLoadingLeads={isLoading}
        refetch={refetchMyLeads}
        campaignTypeId={campaignTypeId}
        campaignType={campaignTypesData?.find(type => type.campaign_type_id === +(campaignTypeId ?? 1))?.type_name}
        serializedFilters={serializedFilters}
      />
    </>
  );
};

export default memo(MyLeads);
