import { StepIcon, StepIconProps } from '@mui/material';
import styled from '@mui/system/styled';

export const StyledStepIcon = styled(StepIcon)<StepIconProps>(() => ({
  '&.MuiStepIcon-root .MuiStepIcon-text': {
    display: 'none',
  },
  '&.MuiSvgIcon-root': {
    height: '20px',
    width: '20px',
  },
}));
