import { FC, memo, PropsWithChildren } from 'react';

import { Container } from '@mui/material';
import Logo from '@components/icons/Logo';

import { StyledAppBar, StyledBox, StyledDivider, StyledInnerBox, StyledWrapperContent } from './styled';

const RegistrationView: FC<PropsWithChildren> = ({ children }) => (
  <>
    <StyledAppBar position="static">
      <Container>
        <StyledInnerBox>
          <StyledBox display="flex">
            <Logo />
          </StyledBox>
          <StyledDivider />
        </StyledInnerBox>
      </Container>
    </StyledAppBar>
    <StyledWrapperContent>
      <Container>{children}</Container>
    </StyledWrapperContent>
  </>
);

export default memo(RegistrationView);
