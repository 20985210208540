import { FC, Fragment, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Campaign } from '@api/api';
import { StyledAreaBox } from '@pages/styled';

import { Box, Typography } from '@mui/material';

import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const BasicInformation: FC<{ campaignData?: Campaign }> = ({ campaignData }) => {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenSizeContext();

  const infoFields = useMemo(
    () => [
      { id: 1, title: 'dashboard.newScraper.settings.scraperTypeLabel', value: campaignData?.campaign_type.type_name },
      { id: 2, title: 'dashboard.newScraper.settings.scraperNameLabel', value: campaignData?.name },
      { id: 3, title: 'dashboard.newScraper.settings.scraperDescription', value: campaignData?.description || '' },
    ],
    [campaignData?.campaign_type.type_name, campaignData?.description, campaignData?.name],
  );

  return (
    <Box display="flex" flexDirection="column" gap={1.5}>
      <Typography variant="subtitle2">{t('dashboard.campaignDetails.basicInformation')}</Typography>
      <StyledAreaBox display="flex" gap={3} flexDirection={isLargerScreen ? 'row' : 'column'}>
        {infoFields.map(({ id, title, value }) => (
          <Fragment key={id}>
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" color="text.secondary">
                {t(title)}
              </Typography>
              <Typography variant="body2">{value}</Typography>
            </Box>
          </Fragment>
        ))}
      </StyledAreaBox>
    </Box>
  );
};

export default memo(BasicInformation);
