import { FC, Fragment, memo, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CampaignFiltersDto, useGetCampaignQuery } from '@api/api';
import { useLeadScraperContext } from '@pages/LeadScraper/context';

import { Box, Button } from '@mui/material';

import { transformType } from '../../utils/dataToTypes';

import { StyledTextField } from './styled';

const FiltersForm: FC<{
  data: CampaignFiltersDto[];
  isLoading: boolean;
}> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const { control, formState, setValue, trigger } = useFormContext();
  const { isEdit } = useLeadScraperContext();
  const { campaignId } = useParams();
  const { data: campaignData } = useGetCampaignQuery(
    { id: campaignId as string },
    { refetchOnMountOrArgChange: true, skip: !campaignId },
  );

  const filterFields = useWatch({ name: 'filters.paidFilters', control });

  const isEmpty =
    Object.values((filterFields || {}) as Record<string, string>).every((value: string) => value?.length < 1) ||
    !filterFields;

  useEffect(() => {
    if (isEdit && campaignData) {
      const matchingFields = campaignData?.camp_filters?.filter(f =>
        data.find(option => option.price && option.key.toLowerCase() === f.filter_name.toLowerCase()),
      );
      matchingFields?.forEach(match => {
        setValue(`filters.paidFilters.${match.filter_name}`, match.filter_val);
      });
      trigger();
    }
  }, [campaignData, setValue]);

  return (
    <Box display="flex" flexDirection="column" width="100%" pt={2} pb={1} gap={2}>
      {data &&
        !isLoading &&
        data
          ?.filter(f => f.price)
          .map(filter => (
            <Fragment key={filter.campaign_filters_id}>
              <Controller
                control={control}
                name={`filters.paidFilters.${filter.key}`}
                render={({ field, fieldState }) => (
                  <StyledTextField
                    {...field}
                    fullWidth
                    label={filter.key}
                    error={Boolean(fieldState.error)}
                    variant="outlined"
                    type={transformType(filter.type)}
                    helperText={
                      fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                    }
                    InputLabelProps={{ shrink: field.value }}
                  />
                )}
              />
            </Fragment>
          ))}

      <Button fullWidth variant="contained" color="primary" type="submit" disabled={!formState.isValid || isLoading}>
        {isEmpty ? t('dashboard.newScraper.settings.skip') : t('dashboard.newScraper.settings.applyFilters')}
      </Button>
    </Box>
  );
};

export default memo(FiltersForm);
