import { Box, BoxProps } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

export const StyledAreaBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  maxWidth: '580px',
  padding: '16px',
  borderRadius: '12px',
  border: 'none',
  backgroundColor: theme.colors.secondary.background,
}));

export const StyledInfoBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  padding: '6px 16px',
  borderRadius: '12px',
  border: `1px solid ${theme.colors.info.main}`,
  color: theme.colors.info.dark,
}));
