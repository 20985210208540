import { FC, memo, MouseEvent } from 'react';
import { CampaignScrapersDto } from '@api/api';
import { capitalize } from '@utils/formatInitials';

import { ToggleButton } from '@mui/material';

import { StyledSourcesWrapper, StyledToggleButtonGroup } from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const SourcesList: FC<{
  data?: CampaignScrapersDto[];
  handleChange: (event: MouseEvent<HTMLElement>, newAlignment: CampaignScrapersDto) => void;
  active?: string;
}> = ({ data, handleChange, active }) => {
  const { isLargerScreen } = useScreenSizeContext();

  return (
    <StyledSourcesWrapper display="flex">
      <StyledToggleButtonGroup
        onChange={handleChange}
        exclusive
        value={data?.find(item => item.scrapers.scraper_name.trim() === active)}
        isMobile={!isLargerScreen}
      >
        {data?.map(item => (
          <ToggleButton key={item.id} value={item} disableRipple>
            {capitalize(item.scrapers.scraper_name.trim())}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </StyledSourcesWrapper>
  );
};

export default memo(SourcesList);
