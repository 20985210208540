import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useGetCampaignFiltersQuery, useGetCampaignQuery } from '@api/api';
import { ROUTING } from '@constants/routing';
import ActiveTab from '@pages/CampaignDetails/components/ActiveTab';
import ToggleTabs from '@pages/CampaignDetails/components/ToggleTabs';
import { CampaignDetailsProvider } from '@pages/CampaignDetails/context';
import AppLayout from '@providers/layouts/AppLayout/AppLayout';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Box, Typography } from '@mui/material';

import { StyledLink } from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const CampaignDetails: FC = () => {
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const { isLargerScreen } = useScreenSizeContext();

  const { data: campaignData } = useGetCampaignQuery({ id: campaignId as string }, { refetchOnMountOrArgChange: true });

  useGetCampaignFiltersQuery(
    { campaignId: String(campaignData?.campaign_type_id) },
    { refetchOnMountOrArgChange: true, skip: !campaignData?.campaign_type_id },
  );

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.campaignDetails')}</title>
      </Helmet>
      <CampaignDetailsProvider>
        <AppLayout showSidebar={isLargerScreen}>
          <Box
            px={isLargerScreen ? 3 : 0}
            pt={3}
            display="flex"
            flexDirection="column"
            gap={3}
            minWidth={isLargerScreen ? '800px' : '360px'}
          >
            <Box display="flex" alignItems="center" mb={0}>
              <StyledLink
                variant="body2"
                mr={1}
                color="text.secondary"
                fontFamily="Inter"
                to={ROUTING.ROOT}
                component={RouterLink}
              >
                {t('dashboard.navigation.scrapers')}
              </StyledLink>
              <ChevronRightRoundedIcon fontSize="small" />
              <Typography variant="body2" ml={1} color="text.primary" fontFamily="Inter">
                {campaignData?.name}
              </Typography>
            </Box>
            <ToggleTabs />
            <ActiveTab />
          </Box>
        </AppLayout>
      </CampaignDetailsProvider>
    </>
  );
};

export default memo(CampaignDetails);
