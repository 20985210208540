import { ChangeEvent, FC, Fragment, memo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CampaignFiltersDto } from '@api/api';
import { TOKENS } from '@constants/common';
import { transformType } from '@pages/LeadScraper/components/ScraperSettings/components/ScraperFlow/utils/dataToTypes';
import { NUMBER_OF_LEADS } from '@pages/LeadScraper/constants';
import { capitalize } from '@utils/formatInitials';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';

import { StyledPaper, StyledTextField } from './styled';

const SelectedSourceCard: FC<{
  name: string;
  price: number;
  onRemove: (id: number) => void;
  index: number;
  control: Control;
  renderData: CampaignFiltersDto[] | null;
  scraperId: number;
}> = ({ name, price, onRemove, index, control, renderData }) => {
  const { t } = useTranslation();

  return (
    <StyledPaper>
      <Box display="flex" mb={2} justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Typography variant="subtitle2" mr={1}>
            {capitalize(name)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {`${price} ${TOKENS}`} / {t('dashboard.newScraper.leadLabel')}
          </Typography>
        </Box>
        <IconButton onClick={() => onRemove(index)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box display="flex" flexDirection="column" gap={2} justifyContent="center">
        {renderData
          ?.filter(item => !item.price)
          .map(obj => (
            <Fragment key={obj.campaign_filters_id}>
              <Controller
                name={`autocomplete.${index}.filters.${obj.key.toLowerCase()}`}
                control={control}
                render={({ field, fieldState }) => (
                  <StyledTextField
                    {...field}
                    fullWidth
                    label={obj.key}
                    error={Boolean(fieldState.error)}
                    variant="outlined"
                    type={transformType(obj.type)}
                    helperText={
                      fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                    }
                  />
                )}
              />
            </Fragment>
          ))}

        <Controller
          name={`autocomplete.${index}.filters.${NUMBER_OF_LEADS}`}
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <StyledTextField
              {...field}
              fullWidth
              type="number"
              label={t('dashboard.newScraper.settings.numberOfLeadsLabel')}
              error={Boolean(fieldState.error)}
              variant="outlined"
              onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (e.target.value.length > 6) return;
                field.onChange(e);
              }}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
            />
          )}
        />
      </Box>
    </StyledPaper>
  );
};

export default memo(SelectedSourceCard);
