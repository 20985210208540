import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { User } from '@api/api';
import { ROUTING } from '@constants/routing';
import { useMenuActions } from '@hooks/common/useMenuActions';
import { formatInitials, getFullName } from '@utils/formatInitials';

import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { Avatar, Box, ClickAwayListener, Divider, MenuItem, Tooltip, Typography } from '@mui/material';

import { StyledProfileChipContainer } from './styled';
import { StyledMenu, StyledMenuItemSelectableText } from '@/shared/styled';

const ProfileChip: FC<{ userData?: User; handleLogout: () => void; isLargerScreen: boolean }> = ({
  handleLogout,
  userData,
  isLargerScreen,
}) => {
  const { anchorEl, open, handleOpen, handleClose } = useMenuActions();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const fullName = getFullName(userData);

  const navigateToProfile = () => {
    handleClose();
    navigate(`/${ROUTING.SETTINGS}`);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Tooltip title={fullName} placement="bottom">
          <StyledProfileChipContainer
            clickable
            avatar={<Avatar>{formatInitials(fullName)}</Avatar>}
            label={isLargerScreen ? fullName : ''}
            variant="filled"
            onClick={handleOpen}
            isLabelVisible={isLargerScreen}
          />
        </Tooltip>
      </ClickAwayListener>

      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={8}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItemSelectableText disableRipple>
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">{fullName || ''}</Typography>
            <Typography variant="caption">{userData?.email || ''}</Typography>
          </Box>
        </StyledMenuItemSelectableText>
        <Divider />
        <MenuItem onClick={navigateToProfile} disableRipple>
          <SettingsIcon />
          {t('profile.settings')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} disableRipple>
          <LogoutIcon />
          {t('profile.signOut')}
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default memo(ProfileChip);
