import { NO, NOT_AVAILABLE, YES } from '@constants/common';
import { withTooltip } from '@hooks/common/useDynamicColumns/utils/withTooltip';
import { JobLeadEntity } from '@hooks/types';
import { capitalize } from '@utils/formatInitials';
import { get } from 'lodash-es';

export const createJobsColumns = () => [
  {
    field: 'email',
    headerName: 'Email',
    headerClassName: 'super-bold--header',
    width: 300,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: JobLeadEntity }) => withTooltip(get(row, 'data.email', NOT_AVAILABLE)),
  },
  {
    field: 'contact_phone',
    headerName: 'Contact phone',
    headerClassName: 'super-bold--header',
    width: 150,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: JobLeadEntity }) => withTooltip(get(row, 'data.phone', NOT_AVAILABLE)),
  },
  {
    field: 'company_name',
    headerName: 'Company Name',
    headerClassName: 'super-bold--header',
    width: 275,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: JobLeadEntity }) =>
      withTooltip(capitalize(get(row, 'data.company_name', NOT_AVAILABLE))),
  },
  {
    field: 'company_location',
    headerName: 'Company Location',
    headerClassName: 'super-bold--header',
    width: 400,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: JobLeadEntity }) =>
      withTooltip(capitalize(get(row, 'data.company_location', NOT_AVAILABLE))),
  },
  {
    field: 'job_type',
    headerName: 'Job type',
    headerClassName: 'super-bold--header',
    width: 100,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: JobLeadEntity }) => withTooltip(get(row, 'data.job_type', NOT_AVAILABLE)),
  },
  {
    field: 'job_title',
    headerName: 'Job title',
    headerClassName: 'super-bold--header',
    width: 400,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: JobLeadEntity }) => withTooltip(capitalize(get(row, 'data.job_title', NOT_AVAILABLE))),
  },
  {
    field: 'job_shift',
    headerName: 'Job shift',
    headerClassName: 'super-bold--header',
    width: 200,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: JobLeadEntity }) => withTooltip(get(row, 'data.job_shift', NOT_AVAILABLE)),
  },
  {
    field: 'vacancy_expired',
    headerName: 'Vacant',
    headerClassName: 'super-bold--header',
    width: 100,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: JobLeadEntity }) =>
      withTooltip(capitalize(get(row, 'data.vacancy_expired') ? NO : YES)),
  },
  {
    field: 'rating',
    headerName: 'Rating',
    headerClassName: 'super-bold--header',
    width: 100,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: JobLeadEntity }) => withTooltip(get(row, 'data.rating', NOT_AVAILABLE)),
  },
];
