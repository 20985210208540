import { FC, forwardRef, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaginatedBillingTransactionsDto } from '@api/api';
import { ROUTING } from '@constants/routing';
import { Page } from '@pages/LeadScrapers/types';
import { StyledDataGrid } from '@pages/Settings/components/styled';
import NoTransactionsMessage from '@pages/Settings/components/TransactionsTab/components/NoTransactionsMessage';
import { useTransactionsColumns } from '@pages/Settings/hooks/useTransactionsColumns';

import { Box, LinearProgress } from '@mui/material';
import { GridEventListener, GridPaginationModel, GridRowSpacingParams } from '@mui/x-data-grid';

const noHeaders = forwardRef(() => null);

const TransactionsList: FC<{
  transactions?: PaginatedBillingTransactionsDto;
  handlePageChange: (newPaginationModel: GridPaginationModel) => void;
  paginationModel: Page;
  isLoading: boolean;
}> = ({ transactions, handlePageChange, paginationModel, isLoading: isLoadingTransactions }) => {
  const columns = useTransactionsColumns();
  const transactionsData = useMemo(() => transactions?.data || [], [transactions?.data]);
  const navigate = useNavigate();

  const props = useMemo(
    () => ({
      rowCount: transactions?.total,
      rows: transactionsData?.map(v => ({
        ...v,
      })),
      columns,
    }),
    [columns, transactions?.total, transactionsData],
  );

  const handleRowClick: GridEventListener<'rowClick'> = ({ row: { campaign_id_campaign } }) => {
    if (campaign_id_campaign) {
      navigate(`/${ROUTING.CAMPAIGN_DETAILS}/${campaign_id_campaign}`);
    }
  };

  return (
    <Box>
      <StyledDataGrid
        autoHeight
        paginationMode="client"
        pagination={transactionsData && transactionsData.length > 0 ? true : undefined}
        hideFooter={transactionsData?.length === 0}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        rowSelection={false}
        rowHeight={68}
        showCellVerticalBorder={false}
        getRowSpacing={(params: GridRowSpacingParams) => ({
          top: params.isFirstVisible ? 0 : 5,
          bottom: params.isLastVisible ? 0 : 5,
        })}
        pageSizeOptions={[10, 25]}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: NoTransactionsMessage,
          ...(!transactions?.total && { columnHeaders: noHeaders }),
        }}
        getRowId={row => row.billing_transactions_id}
        onPaginationModelChange={handlePageChange}
        paginationModel={paginationModel}
        onRowClick={handleRowClick}
        loading={isLoadingTransactions}
        {...props}
      />
    </Box>
  );
};

export default memo(TransactionsList);
