import { FC, memo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Autocomplete, Box } from '@mui/material';
import AutocompletePaper from '@components/AutocompletePaper/AutocompletePaper';

import { StyledTextField } from '@/shared/SearchFilters/styled';
import { SearchFilter } from '@/shared/SearchFilters/types';

const DropdownSearchFilter: FC<SearchFilter> = ({ name, label, onChange, dependencies, options }) => {
  const { control, trigger } = useFormContext();
  const minOptionValue = useWatch({
    control,
    name: dependencies!.min,
  });
  const maxOptionValue = useWatch({
    control,
    name: dependencies!.max,
  });

  return (
    <Box>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <Autocomplete
              {...field}
              onChange={(e, v) => {
                field.onChange(v);
                trigger(dependencies!.min);
                trigger(dependencies!.max);
                onChange();
              }}
              onInputChange={(_, data) => {
                if (data) {
                  field.onChange(data);
                  trigger(dependencies!.min);
                  trigger(dependencies!.max);
                  onChange();
                }
              }}
              freeSolo
              filterOptions={currentOptions => {
                if (!minOptionValue && !maxOptionValue) return currentOptions;

                if (!maxOptionValue) {
                  if (name === dependencies!.min) {
                    return currentOptions;
                  }
                  return currentOptions.filter(option => +option > +minOptionValue);
                }

                if (maxOptionValue && minOptionValue) {
                  if (name === dependencies!.min) {
                    return currentOptions.filter(option => +option < +maxOptionValue);
                  }
                  if (name === dependencies!.max) {
                    return currentOptions.filter(option => +option > +minOptionValue);
                  }
                }

                if (maxOptionValue && !minOptionValue) {
                  if (name === dependencies!.max) {
                    return currentOptions;
                  }
                  return currentOptions.filter(option => +option < +maxOptionValue);
                }
                return currentOptions;
              }}
              options={options as string[]}
              PaperComponent={AutocompletePaper}
              renderOption={(props, option) => {
                const numberValue = +option;
                let renderValue = option;

                if (numberValue >= 1_000_000) {
                  renderValue = `${numberValue / 1_000_000}M`;
                } else if (numberValue >= 1_000) {
                  renderValue = `${numberValue / 1_000}K`;
                }

                return <li {...props}>{renderValue}</li>;
              }}
              value={field.value ?? ''}
              renderInput={params => <StyledTextField type="number" {...params} label={label} variant="outlined" />}
            />
          </>
        )}
      />
    </Box>
  );
};

export default memo(DropdownSearchFilter);
