import { FC, forwardRef, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import Filters from '@pages/LeadScrapers/components/Filters';
import { StyledDataGrid } from '@pages/LeadScrapers/components/ScrapersList/styled';
import { useListColumns } from '@pages/LeadScrapers/hooks/useListColumns';
import { ScrapersListProps } from '@pages/LeadScrapers/types';

import { Box, LinearProgress, Typography } from '@mui/material';
import { GridEventListener, GridRowSpacingParams } from '@mui/x-data-grid';
import AddScraperButton from '@components/AddScraperButton/AddScraperButton';

import NoCampaignsMessage from './NoCampaignsMessage';

const noHeaders = forwardRef(() => null);

const ScrapersList: FC<ScrapersListProps> = ({ campaigns, handlePageChange, paginationModel, isLargerScreen }) => {
  const { t } = useTranslation();
  const columns = useListColumns();
  const campaignsData = useMemo(() => campaigns?.data || [], [campaigns?.data]);
  const navigate = useNavigate();

  const props = useMemo(
    () => ({
      rowCount: campaigns?.total,
      rows: campaignsData?.map((v, index) => ({
        id: index,
        ...v,
      })),
      columns,
    }),
    [campaigns?.total, campaignsData, columns],
  );

  const handleRowClick: GridEventListener<'rowClick'> = ({ row: { campaign_id } }) => {
    navigate(`/${ROUTING.CAMPAIGN_DETAILS}/${campaign_id}`);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} my={2}>
        <Typography variant="h6">{t('dashboard.navigation.scrapers')}</Typography>
        <AddScraperButton />
      </Box>

      <Filters isLargerScreen={isLargerScreen} />

      <Box display="flex" flexDirection="column">
        <StyledDataGrid
          autoHeight
          paginationMode="client"
          pagination={campaignsData.length > 0 ? true : undefined}
          hideFooter={campaignsData.length === 0}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          rowSelection={false}
          rowHeight={68}
          showCellVerticalBorder={false}
          getRowSpacing={(params: GridRowSpacingParams) => ({
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
          })}
          pageSizeOptions={[5, 10]}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: NoCampaignsMessage,
            ...(!campaigns?.total && { columnHeaders: noHeaders }),
          }}
          onPaginationModelChange={handlePageChange}
          paginationModel={paginationModel}
          onRowClick={handleRowClick}
          {...props}
        />
      </Box>
    </>
  );
};

export default memo(ScrapersList);
