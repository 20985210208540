import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { StyledInfoBox } from './styled';

const NoDetailsMessage: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledInfoBox display="flex" gap={1}>
      <InfoOutlined color="info" />
      <Typography variant="body2" maxWidth="330px">
        {t('dashboard.campaignDetails.noDetailsMessage')}
      </Typography>
    </StyledInfoBox>
  );
};

export default memo(NoDetailsMessage);
