import { FC, memo } from 'react';
import { Trans } from 'react-i18next';

import { Box, Typography } from '@mui/material';

const NoLeadsAvailableScreen: FC = () => (
  <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
    <Typography variant="h6" px={2}>
      <Trans i18nKey="common.noLeadsAvailable" />
    </Typography>
  </Box>
);

export default memo(NoLeadsAvailableScreen);
