import { FC, memo } from 'react';

import { LoadingButton as LoadingButtonMui, LoadingButtonProps } from '@mui/lab';
import { CircularProgress } from '@mui/material';

import { loadingSx } from './styled';

const ButtonLoading: FC<LoadingButtonProps> = ({ variant = 'contained', ...props }) => (
  <LoadingButtonMui variant={variant} loadingIndicator={<CircularProgress sx={loadingSx} />} {...props} />
);

export default memo(ButtonLoading);
