import { FC, memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { CampaignTypesDto, useGetCampaignQuery } from '@api/api';
import { BASIC_INFO } from '@constants/formFields';
import { useLeadScraperContext } from '@pages/LeadScraper/context';

import { Skeleton } from '@mui/lab';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import AutocompletePaper from '@components/AutocompletePaper/AutocompletePaper';

import { transformData } from '../../utils/dataToOptions';

const BasicInformationForm: FC<{ campaignTypes?: CampaignTypesDto[] }> = ({ campaignTypes }) => {
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { control, formState, setValue, trigger } = useFormContext();
  const { isEdit } = useLeadScraperContext();
  const typeOptions = transformData(campaignTypes);

  const { campaignId } = useParams();
  const { data: campaignData } = useGetCampaignQuery(
    { id: campaignId as string },
    { refetchOnMountOrArgChange: true, skip: !campaignId },
  );

  useEffect(() => {
    if (isEdit && campaignData) {
      setSearchParams({ campaignId: String(campaignData.campaign_type_id) });
      setValue(BASIC_INFO.TYPE, typeOptions.find(opt => +opt.campaignId === campaignData.campaign_type_id)?.campaignId);
      setValue(BASIC_INFO.CAMPAIGN_NAME, campaignData?.name);
      setValue(BASIC_INFO.CAMPAIGN_DESCRIPTION, campaignData?.description);
      trigger();
    }
  }, [campaignData, setValue]);

  return (
    <Box display="flex" flexDirection="column" width="100%" pt={2} pb={1} gap={2}>
      <Controller
        control={control}
        name={BASIC_INFO.TYPE}
        render={({ field: { onChange, ...field }, fieldState }) => {
          const selectedOption = typeOptions?.find(opt => +opt.campaignId === +field.value);
          const defaultValue =
            isEdit && campaignData
              ? typeOptions.find(opt => +opt.campaignId === campaignData.campaign_type_id)
              : undefined;

          return typeOptions ? (
            <Autocomplete
              {...field}
              disabled={isEdit}
              defaultValue={defaultValue}
              disableClearable
              getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
              disablePortal
              PaperComponent={AutocompletePaper}
              options={typeOptions}
              onChange={(_, value) => {
                if (value?.campaignId) {
                  onChange(value.campaignId);
                  setSearchParams({ campaignId: String(value.campaignId) });
                }
              }}
              value={selectedOption}
              fullWidth
              renderInput={params => (
                <TextField
                  label={t('dashboard.newScraper.settings.scraperTypeLabel')}
                  error={Boolean(fieldState.error)}
                  variant="outlined"
                  helperText={
                    fieldState.error &&
                    t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
                  }
                  {...params}
                />
              )}
            />
          ) : (
            <Skeleton height={40} />
          );
        }}
      />

      <Controller
        control={control}
        name={BASIC_INFO.CAMPAIGN_NAME}
        render={({ field, fieldState }) => (
          <TextField
            fullWidth
            variant="outlined"
            label={t('dashboard.newScraper.settings.scraperNameLabel')}
            type="text"
            error={Boolean(fieldState.error)}
            helperText={
              fieldState.error &&
              t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
            }
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name={BASIC_INFO.CAMPAIGN_DESCRIPTION}
        render={({ field, fieldState }) => (
          <TextField
            fullWidth
            variant="outlined"
            label={t('dashboard.newScraper.settings.scraperDescription')}
            type="text"
            error={Boolean(fieldState.error)}
            helperText={
              fieldState.error &&
              t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
            }
            {...field}
          />
        )}
      />

      <Button fullWidth variant="contained" color="primary" type="submit" disabled={!formState.isValid}>
        {t('dashboard.newScraper.nextStep')}
      </Button>
    </Box>
  );
};

export default memo(BasicInformationForm);
