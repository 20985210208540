import { SyntheticEvent, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type EnumObject = Record<string, string>;

type ChangeHandler<EnumType extends EnumObject, EnumKey extends keyof EnumType> = (
  e: SyntheticEvent<Element, Event>,
  newValue: EnumKey,
) => void;

export const useTabs = <EnumType extends EnumObject>(defaultTab: string, Indicator: EnumType) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get('tab') || defaultTab;
  const setTab = useCallback(
    (val: string) => {
      searchParams.set('tab', val);
      searchParams.delete('source');
      searchParams.delete('custom');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleChange: ChangeHandler<EnumType, keyof EnumType> = useCallback(
    (e: SyntheticEvent<Element, Event>, newValue) => {
      e.preventDefault();
      if (newValue !== null) {
        const indicatorScreenValue = Indicator[newValue];
        if (indicatorScreenValue) {
          setTab(indicatorScreenValue);
        }
      }
    },
    [setTab, Indicator],
  );

  return { tab, setTab, handleChange };
};
