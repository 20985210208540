import { HEADER_HEIGHT_DESKTOP } from '@constants/common';

import { AppBar, Box, Divider, DividerProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledWrapperContent = styled(Box)({
  minHeight: `calc(100vh - ${HEADER_HEIGHT_DESKTOP}px)`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: `${HEADER_HEIGHT_DESKTOP}px`,
});

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: `${HEADER_HEIGHT_DESKTOP}px`,
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  boxShadow: 'none',
}));

export const StyledBox = styled(Box)({
  flexGrow: 1,
});

export const StyledInnerBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '1200px',
  flexDirection: 'column',
});

export const StyledDivider = styled(Divider)<DividerProps>({
  paddingTop: '24px',
  width: '100%',
});
