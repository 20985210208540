import { FC, Fragment, memo, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ScrapersDto, useGetCampaignQuery } from '@api/api';
import { SOURCE_INFO } from '@constants/formFields';
import { useCampaignFilters } from '@hooks/api/useCampaignFilters';
import { useCampaignSources } from '@hooks/api/useCampaignSources';
import { prefillMatchedSources } from '@pages/LeadScraper/components/ScraperSettings/components/ScraperFlow/utils/prefill';
import { useLeadScraperContext } from '@pages/LeadScraper/context';

import { Box, Button, CircularProgress } from '@mui/material';

import ScraperSettingsAutocomplete from './ScraperSettingsAutocomplete';
import SelectedSourceCard from './SelectedSourceCard';

const SourceSettingsForm: FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation();
  const { control, formState, unregister, trigger, setValue } = useFormContext();
  const { data: sourcesData, isLoading: isLoadingSources } = useCampaignSources(id);

  const autocompleteFields: ScrapersDto[] = useWatch({ name: SOURCE_INFO.AUTOCOMPLETE, control });

  const { data: filtersData, isLoading: isFiltersLoading } = useCampaignFilters(id);

  const { isEdit } = useLeadScraperContext();
  const { campaignId } = useParams();

  const { data: campaignData } = useGetCampaignQuery(
    { id: campaignId as string },
    { refetchOnMountOrArgChange: true, skip: !campaignId },
  );

  const handleRemove = (idx: number) => {
    unregister(`${SOURCE_INFO.AUTOCOMPLETE}.${idx}`);
    trigger(SOURCE_INFO.AUTOCOMPLETE);
  };

  useEffect(() => {
    if (isEdit && campaignId && campaignData && sourcesData && filtersData) {
      const matchedSources = prefillMatchedSources({ sourcesData, campaignData, filtersData });
      setValue(SOURCE_INFO.AUTOCOMPLETE, matchedSources);
      trigger();
    }
  }, [campaignData, setValue]);

  return (
    <Box display="flex" flexDirection="column" width="100%" pt={2} pb={1} gap={2}>
      <ScraperSettingsAutocomplete
        options={sourcesData}
        isLoading={isLoadingSources}
        autocompleteFields={autocompleteFields}
      />

      {isFiltersLoading ? (
        <Box width="100%" height="50px" display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}

      {filtersData &&
        !isFiltersLoading &&
        autocompleteFields?.map((field, index) => (
          <Fragment key={field.scrapers_id}>
            <SelectedSourceCard
              name={field.scraper_name}
              price={field.price}
              onRemove={handleRemove}
              index={index}
              control={control}
              renderData={filtersData}
              scraperId={field.scrapers_id}
            />
          </Fragment>
        ))}

      <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        disabled={!formState.isValid || isFiltersLoading}
      >
        {t('dashboard.newScraper.nextStep')}
      </Button>
    </Box>
  );
};

export default memo(SourceSettingsForm);
