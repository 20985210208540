import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionsDto, useGetInvoiceDetailsQuery, useGetPackagesQuery } from '@api/api';
import { GetPackagesResponse } from '@pages/Settings/types';

import { Box, Typography } from '@mui/material';

type InvoicePurpose = 'topup' | 'purchase_leads' | 'subscription';

const PurposeName = ({ row, purpose, icon }: { row: TransactionsDto; purpose: InvoicePurpose; icon: ReactNode }) => {
  const { t } = useTranslation();
  const { data: invoiceData } = useGetInvoiceDetailsQuery({ id: row.invoice_id }, { skip: !row.invoice_id });
  const { data: packagesData } = useGetPackagesQuery();

  const packageName = (packagesData as GetPackagesResponse)?.data.filter(
    pkg =>
      // @ts-ignore
      pkg.default_price === invoiceData?.lines?.data?.[1]?.metadata?.original_id,
  )?.[0]?.name;

  return (
    <>
      {purpose === 'topup' && (
        <Box pl={2} display="flex" gap={1} alignItems="center" color="grey">
          {icon}
          <Typography variant="subtitle2" textAlign="center" color="text.secondary">
            {t('billing.transactions.topupTitle')}
          </Typography>
        </Box>
      )}
      {purpose === 'purchase_leads' && (
        <Box pl={2} display="flex" gap={1} alignItems="center" color="grey">
          {icon}
          <Typography variant="subtitle2" textAlign="center" color="text.secondary">
            {t('billing.transactions.leadsDbTitle')}
          </Typography>
        </Box>
      )}
      {purpose === 'subscription' && packageName && (
        <Box pl={2} display="flex" gap={1} alignItems="center" color="grey">
          {icon}
          <Typography variant="subtitle2" textAlign="center" color="text.secondary">
            {packageName}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default memo(PurposeName);
