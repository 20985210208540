import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { StyledTextField } from '@/shared/SearchFilters/styled';
import { SearchFilter } from '@/shared/SearchFilters/types';

const TextFieldSearchFilter: FC<SearchFilter> = ({ name, type = 'text', label, onChange }) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <StyledTextField
            {...field}
            label={label}
            fullWidth
            variant="outlined"
            type={type}
            onChange={e => {
              field.onChange(e);
              onChange();
            }}
            value={field.value ?? ''}
          />
        )}
      />
    </>
  );
};

export default memo(TextFieldSearchFilter);
