import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';

import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const ProtectedRoutesContext = createContext<ReturnType<typeof useProtectedRoutesContextValue> | null>(null);

const useProtectedRoutesContextValue = () => {
  const { isLargerScreen } = useScreenSizeContext();
  const [expandedMenu, setExpandedMenu] = useState(isLargerScreen);
  const [expandedFilters, setExpandedFilters] = useState(true);

  const toggleMenu = useCallback(() => setExpandedMenu(!expandedMenu), [expandedMenu]);
  const toggleFilters = useCallback(() => setExpandedFilters(!expandedFilters), [expandedFilters]);

  return { expandedMenu, toggleMenu, expandedFilters, toggleFilters };
};

export const useProtectedRoutesContext = () => {
  const context = useContext(ProtectedRoutesContext);

  if (!context) throw new Error('useProtectedRoutesContext must be inside ProtectedRoutesProvider');

  return context;
};

export const ProtectedRoutesProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useProtectedRoutesContextValue();

  return <ProtectedRoutesContext.Provider value={value}>{children}</ProtectedRoutesContext.Provider>;
};
