import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@mui/material';

import DaysOfTheWeek from './components/DaysOfTheWeek';
import SelectDayAndTime from './components/SelectDayAndTime';
import SelectRepeatPeriod from './components/SelectRepeatPeriod';

const SchedulingForm: FC = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const isNow = watch('repeat.now');
  const isWeekly = watch('repeat.weekly');

  return (
    <Box display="flex" flexDirection="column" width="100%" ml={1} pt={2} pb={1} gap={2}>
      <SelectRepeatPeriod />
      {!isNow && <SelectDayAndTime />}
      {isWeekly && <DaysOfTheWeek />}

      <Button fullWidth variant="contained" color="primary" type="submit">
        {t('dashboard.newScraper.nextStep')}
      </Button>
    </Box>
  );
};

export default memo(SchedulingForm);
