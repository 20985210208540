import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignTypesDto } from '@api/api';
import { useCampaignTypes } from '@hooks/api/useCampaignTypes';
import { useLeadScrapersContext } from '@pages/LeadScrapers/context';
import { useScraperFiltersUrlParams } from '@pages/LeadScrapers/hooks/useScraperFiltersUrlParams';
import { StyledInputTextField } from '@pages/LeadScrapers/styled';
import { NullableCampaignTypesDto, Status } from '@pages/LeadScrapers/types';

import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Box } from '@mui/material';
import AutocompletePaper from '@components/AutocompletePaper/AutocompletePaper';
import ScheduleToggler from '@components/ScheduleToggler';

const defaultTypeOption: NullableCampaignTypesDto = {
  type_name: 'All',
  campaign_type_id: null,
  price: null,
  status: '',
};

const statusOptions: Status[] = [
  { label: 'All', value: null },
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

const Filters: FC<{ isLargerScreen: boolean }> = ({ isLargerScreen }) => {
  const { data: typeOptions } = useCampaignTypes();
  const { t } = useTranslation();
  const { type, status, setType, setStatus } = useScraperFiltersUrlParams();
  const { handleChangeScheduled } = useLeadScrapersContext();

  const withSearchParamsTypeOption: NullableCampaignTypesDto = {
    campaign_type_id: type,
    type_name: typeOptions?.find(o => o?.campaign_type_id === +type)?.type_name || defaultTypeOption.type_name,
    price: defaultTypeOption.price,
    status: defaultTypeOption.status,
  };

  const updatedTypeOptions = [defaultTypeOption, ...(typeOptions ?? ([] as CampaignTypesDto[]))];

  return (
    <Box display="flex" gap={2} flexDirection={isLargerScreen ? 'row' : 'column'}>
      <Autocomplete
        disablePortal
        getOptionLabel={option => (typeof option === 'string' ? option : option.type_name)}
        options={updatedTypeOptions ?? []}
        value={type === -1 ? defaultTypeOption : withSearchParamsTypeOption}
        clearIcon={type !== -1 && <ClearIcon fontSize="small" />}
        PaperComponent={AutocompletePaper}
        onChange={(_, value, reason) => {
          if (reason === 'clear') {
            setType(-1);
            return defaultTypeOption;
          }
          if (value) {
            !value.campaign_type_id ? setType(-1) : setType(value.campaign_type_id);
          }
          return value;
        }}
        renderInput={params => (
          <StyledInputTextField
            label={t('dashboard.newScraper.settings.scraperTypeLabel')}
            variant="outlined"
            isLargerScreen={isLargerScreen}
            {...params}
          />
        )}
      />

      <Autocomplete
        disablePortal
        getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
        options={statusOptions ?? []}
        value={status === -1 ? statusOptions[0] : statusOptions.find(opt => opt.value === status)}
        clearIcon={status !== -1 && <ClearIcon fontSize="small" />}
        onChange={(_, value, reason) => {
          if (reason === 'clear') {
            setStatus(-1);
            return statusOptions[0];
          }
          if (value) {
            value.value === null ? setStatus(-1) : setStatus(value.value as number);
          }
          return value;
        }}
        PaperComponent={AutocompletePaper}
        renderInput={params => (
          <StyledInputTextField
            label={t('dashboard.scraperDetails.status')}
            variant="outlined"
            isLargerScreen={isLargerScreen}
            {...params}
          />
        )}
      />

      <ScheduleToggler onChange={handleChangeScheduled} />
    </Box>
  );
};

export default memo(Filters);
