import { FC, memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SLIDER_MAX_VAL, SLIDER_MIN_VAL } from '@constants/common';
import { useDynamicFilters } from '@hooks/common/useDynamicFilters';
import { useCustomFiltersQueryParam } from '@hooks/common/useDynamicFilters/useCustomFiltersQueryParam';
import { useSelectedFilters } from '@hooks/common/useSelectedFilters';
import { hasTruthyValue } from '@utils/hasTruthy';
import { debounce, pickBy } from 'lodash-es';

import { Box, Chip, Stack, Typography } from '@mui/material';

import { SearchFilterTypeProp } from '@/shared/SearchFilters/types';

const CustomFilters: FC<{ campaignType?: string; refetch: () => void }> = ({ campaignType, refetch }) => {
  const { t } = useTranslation();
  const { dynamicParam, setDynamicParam, removeDynamicParam } = useCustomFiltersQueryParam();
  const { filters } = useDynamicFilters(campaignType);
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: dynamicParam && JSON.parse(dynamicParam),
  });
  const { handleUpdateAppliedFilters, appliedFiltersCount } = useSelectedFilters(form.watch());

  const handleGetFormValues = debounce(() => {
    const formData = form.getValues();

    if (hasTruthyValue(formData)) {
      setDynamicParam(
        JSON.stringify(
          pickBy(formData, value => {
            if (value === SLIDER_MIN_VAL || value === SLIDER_MAX_VAL) return;
            return !!value;
          }),
        ),
      );
    } else {
      removeDynamicParam();
    }
    handleUpdateAppliedFilters();
  }, 1000);

  const handleClear = () => {
    removeDynamicParam();
    Object.keys(form.getValues()).forEach(formField => {
      form.setValue(formField, '');
      if (formField.includes('gte+description')) {
        form.setValue(formField, SLIDER_MIN_VAL);
      }
      if (formField.includes('lte+description')) {
        form.setValue(formField, SLIDER_MAX_VAL);
      }
    });
    refetch();
  };

  useEffect(() => {
    if (appliedFiltersCount > 0) {
      handleUpdateAppliedFilters();
    }
  }, [appliedFiltersCount, handleUpdateAppliedFilters]);

  return (
    <Box display="flex" flexDirection="column" pb={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle2">{t('dashboard.customFilters')}</Typography>
        {appliedFiltersCount > 0 && (
          <Chip label={appliedFiltersCount} color="primary" size="small" variant="filled" onDelete={handleClear} />
        )}
      </Box>
      <FormProvider {...form}>
        <form>
          {filters?.map((filterSet, index) => {
            if (Array.isArray(filterSet)) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Stack key={index} direction="row" mb={1}>
                  {filterSet.map(innerField => (
                    <Box flex="1 1 50%" key={`${innerField.field}${innerField.label}`}>
                      <innerField.Component
                        name={innerField.field}
                        label={innerField.label}
                        onChange={handleGetFormValues}
                        dependencies={innerField.dependencies}
                        options={innerField.options}
                      />
                    </Box>
                  ))}
                </Stack>
              );
            }

            // @ts-ignore
            const { Component, field, label, type, options, minValue, maxValue, dependencies } = filterSet;
            return (
              <Box key={`${field}${label}`} mb={1}>
                <Component
                  name={field}
                  label={label}
                  type={type as SearchFilterTypeProp}
                  options={options}
                  minValue={minValue}
                  maxValue={maxValue}
                  onChange={handleGetFormValues}
                  dependencies={dependencies}
                />
              </Box>
            );
          })}
        </form>
      </FormProvider>
    </Box>
  );
};

export default memo(CustomFilters);
