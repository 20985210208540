import { NOT_AVAILABLE } from '@constants/common';
import { withTooltip } from '@hooks/common/useDynamicColumns/utils/withTooltip';
import { PropertyLeadEntity } from '@hooks/types';
import { formatCurrency } from '@utils/formatCurrency';
import { capitalize } from '@utils/formatInitials';
import { get } from 'lodash-es';

export const createPropertyColumns = () => [
  {
    field: 'address',
    headerName: 'Address',
    headerClassName: 'super-bold--header',
    width: 300,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: PropertyLeadEntity }) => withTooltip(get(row, 'data.address', NOT_AVAILABLE)),
  },
  {
    field: 'price',
    headerName: 'Price',
    headerClassName: 'super-bold--header',
    width: 150,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: PropertyLeadEntity }) => {
      const result = get(row, 'data.price');
      return withTooltip(formatCurrency(result as unknown as number) || NOT_AVAILABLE);
    },
  },
  {
    field: 'property_type',
    headerName: 'Property Type',
    headerClassName: 'super-bold--header',
    width: 150,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: PropertyLeadEntity }) =>
      withTooltip(capitalize(get(row, 'data.property_type')).replaceAll('_', ' ') || NOT_AVAILABLE),
  },
  {
    field: 'bedrooms',
    headerName: 'Bedrooms',
    headerClassName: 'super-bold--header',
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: PropertyLeadEntity }) => withTooltip(get(row, 'data.bedrooms', NOT_AVAILABLE)),
  },
  {
    field: 'bathrooms',
    headerName: 'Bathrooms',
    headerClassName: 'super-bold--header',
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: PropertyLeadEntity }) => withTooltip(get(row, 'data.bathrooms', NOT_AVAILABLE)),
  },
  {
    field: 'size',
    headerName: 'Size, sqft',
    headerClassName: 'super-bold--header',
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: PropertyLeadEntity }) => withTooltip(get(row, 'data.size', NOT_AVAILABLE)),
  },
  {
    field: 'status',
    headerName: 'Status',
    headerClassName: 'super-bold--header',
    hideable: false,
    width: 150,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: PropertyLeadEntity }) => {
      const result = get(row, 'data.status', NOT_AVAILABLE);
      return withTooltip(capitalize(result).replaceAll('_', ' '));
    },
  },
  {
    field: 'contact_phone',
    headerName: 'Contact phone',
    headerClassName: 'super-bold--header',
    width: 150,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: ({ row }: { row: PropertyLeadEntity }) => withTooltip(get(row, 'data.contact_phone', NOT_AVAILABLE)),
  },
];
