import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Campaign } from '@api/api';
import { ROUTING } from '@constants/routing';
import NiceModal from '@ebay/nice-modal-react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Chip, Typography } from '@mui/material';

import { ConfirmModalId } from '@/shared/ConfirmModal/ConfirmModal';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const chipStatusMap: Record<number, Record<string, any>> = {
  0: {
    label: 'dashboard.scraperDetails.stopped',
    color: 'default',
  },
  1: {
    label: 'dashboard.scraperDetails.active',
    color: 'success',
  },
  2: {
    label: 'dashboard.scraperDetails.finished',
    color: 'default',
  },
};

const Title: FC<{
  handleToggleScraper: () => void;
  handleDelete: () => void;
  campaignData?: Campaign;
  isDisabledActions: boolean;
}> = ({ handleToggleScraper, campaignData, isDisabledActions, handleDelete }) => {
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const { isLargerScreen } = useScreenSizeContext();

  const handleRedirectToEdit = () => {
    navigate(`/${ROUTING.EDIT_SCRAPER}/${campaignId}`);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {campaignData && (
        <Box display="flex" gap={2} alignItems="center">
          <Typography variant="h6" py="4px">
            {campaignData.name}
          </Typography>
          <Chip
            variant="filled"
            size="small"
            label={t(chipStatusMap[campaignData.status].label)}
            color={chipStatusMap[campaignData.status].color}
          />
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" flexDirection={isLargerScreen ? 'row' : 'column'} gap={2}>
        <Box
          display="flex"
          gap={2}
          alignItems={isLargerScreen ? 'center' : 'start'}
          flexDirection={isLargerScreen ? 'row' : 'column'}
        >
          <LoadingButton
            fullWidth={!isLargerScreen}
            variant="contained"
            color={campaignData?.status ? 'error' : 'secondary'}
            size="medium"
            disabled={isDisabledActions || campaignData?.status === 0 || campaignData?.status === 2}
            startIcon={campaignData?.status ? <StopCircleIcon /> : null}
            onClick={handleToggleScraper}
          >
            {campaignData?.status
              ? t('dashboard.campaignDetails.stopCampaign')
              : t('dashboard.campaignDetails.campaignStopped')}
          </LoadingButton>
          <Button
            fullWidth={!isLargerScreen}
            variant="contained"
            color="secondary"
            size="medium"
            disabled
            startIcon={<EmailRoundedIcon />}
          >
            {t('dashboard.campaignDetails.startCampaignBtnTxt')}
          </Button>
        </Box>

        <Box display="flex" gap={2} flexDirection={isLargerScreen ? 'row' : 'column'}>
          <Button
            startIcon={<EditIcon />}
            color="primary"
            size="medium"
            variant="contained"
            onClick={handleRedirectToEdit}
            disabled={campaignData?.status === 2}
          >
            {t('common.editCampaign')}
          </Button>

          <LoadingButton
            variant="contained"
            color="inherit"
            size="medium"
            disabled={isDisabledActions}
            startIcon={<DeleteForeverIcon />}
            onClick={async e => {
              e.stopPropagation();
              const result = await NiceModal.show(ConfirmModalId, {
                title: 'dashboard.campaignDetails.deleteCampaignModalTitle',
              });
              if (result) handleDelete();
            }}
          >
            {t('dashboard.campaignDetails.deleteCampaignBtnTxt')}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(Title);
