import { createContext, FC, PropsWithChildren, useCallback, useContext, useState } from 'react';

const LeadScrapersContext = createContext<ReturnType<typeof useLeadScrapersContextValue> | null>(null);

const useLeadScrapersContextValue = () => {
  const [showScheduled, setShowScheduled] = useState(false);
  const handleChangeScheduled = useCallback(() => setShowScheduled(!showScheduled), [showScheduled]);

  return { showScheduled, handleChangeScheduled };
};

export const useLeadScrapersContext = () => {
  const context = useContext(LeadScrapersContext);

  if (!context) throw new Error('useLeadScrapersContext must be inside LeadScrapersProvider');

  return context;
};

export const LeadScrapersProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useLeadScrapersContextValue();

  return <LeadScrapersContext.Provider value={value}>{children}</LeadScrapersContext.Provider>;
};
