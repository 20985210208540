import { FC, memo, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

const SelectRepeatPeriod: FC = () => {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext();

  const resetGroup = useCallback(
    (fieldName: string, isChecked: boolean) => {
      if (isChecked) {
        const repeatFields = getValues('repeat');
        Object.keys(repeatFields).forEach(key => {
          if (key !== fieldName.split('.')[1]) {
            setValue(`repeat.${key}`, false);
          }
        });
        setValue(fieldName, true);
      }
    },
    [getValues, setValue],
  );

  return (
    <Box>
      <Typography variant="caption" color="text.secondary">
        {t('dashboard.newScraper.scheduling.repeatSubtitle')}
      </Typography>
      <RadioGroup row>
        <Controller
          control={control}
          name="repeat.now"
          render={({ field }) => (
            <FormControlLabel
              value={field.value}
              label={t('dashboard.newScraper.scheduling.now')}
              labelPlacement="end"
              control={
                <Radio
                  {...field}
                  disableRipple
                  checked={!!field.value}
                  onChange={(_, checked) => {
                    resetGroup(field.name, checked);
                  }}
                  className="control"
                  size="small"
                  color="primary"
                />
              }
            />
          )}
        />
        <Controller
          control={control}
          name="repeat.once"
          render={({ field }) => (
            <FormControlLabel
              value={field.value}
              label={t('dashboard.newScraper.scheduling.once')}
              labelPlacement="end"
              control={
                <Radio
                  {...field}
                  disableRipple
                  checked={!!field.value}
                  onChange={(_, checked) => {
                    resetGroup(field.name, checked);
                  }}
                  className="control"
                  size="small"
                  color="primary"
                />
              }
            />
          )}
        />
        <Controller
          control={control}
          name="repeat.weekly"
          render={({ field }) => (
            <FormControlLabel
              value={field.value}
              label={t('dashboard.newScraper.scheduling.weekly')}
              labelPlacement="end"
              control={
                <Radio
                  {...field}
                  disableRipple
                  checked={!!field.value}
                  onChange={(_, checked) => {
                    resetGroup(field.name, checked);
                  }}
                  className="control"
                  size="small"
                  color="primary"
                />
              }
            />
          )}
        />
        <Controller
          control={control}
          name="repeat.monthly"
          render={({ field }) => (
            <FormControlLabel
              value={field.value}
              label={t('dashboard.newScraper.scheduling.monthly')}
              labelPlacement="end"
              control={
                <Radio
                  {...field}
                  disableRipple
                  checked={!!field.value}
                  onChange={(_, checked) => {
                    resetGroup(field.name, checked);
                  }}
                  className="control"
                  size="small"
                  color="primary"
                />
              }
            />
          )}
        />
      </RadioGroup>
    </Box>
  );
};

export default memo(SelectRepeatPeriod);
