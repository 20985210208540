import { useMemo } from 'react';
import { useGetCampaignFiltersQuery } from '@api/api';
import { CAMPAIGN_ID } from '@constants/common';

export const useCampaignFilters = (id: string, skip?: boolean) => {
  const { data, isLoading, error } = useGetCampaignFiltersQuery(
    { [CAMPAIGN_ID]: id },
    {
      refetchOnMountOrArgChange: true,
      skip: skip || id === null,
    },
  );
  const info = useMemo(() => data ?? [], [data]);
  return { data: info, isLoading, error };
};
