export const footerConfig = [
  {
    title: 'What is Scrapeking and how does it work?',
    details:
      'Scrapeking is a powerful tool designed to extract leads from various online sources such as websites, directories, and social media platforms. It utilizes advanced scraping technology to gather contact information such as email addresses, phone numbers, and more. Once the leads are collected, Scrapeking allows you to organize them and launch targeted email campaigns to reach your audience effectively.',
  },
  {
    title: 'Is Scrapeking legal to use?',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    title: 'How accurate are the leads collected by Scrapeking?',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    title: 'Can I use Scrapeking to send email campaigns?',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    title: 'Is my data secure with Scrapeking?',
    details:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];
