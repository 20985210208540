import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Autocomplete, TextField } from '@mui/material';
import AutocompletePaper from '@components/AutocompletePaper/AutocompletePaper';

import { SearchFilter } from '@/shared/SearchFilters/types';

const AutocompleteSearchFilter: FC<SearchFilter> = ({ name, type = 'text', options, onChange, label = '' }) => {
  const { control, setValue } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            defaultValue={[]}
            value={field.value ? field.value : []}
            filterSelectedOptions
            getOptionLabel={option => option.label}
            disablePortal
            PaperComponent={AutocompletePaper}
            options={options as Array<Record<string, string>>}
            onChange={(e, val, reason) => {
              if (reason === 'clear') {
                setValue(name, []);
              }
              field.onChange(val);
              onChange();
            }}
            fullWidth
            renderInput={params => (
              <TextField
                type={type}
                label={label}
                variant="outlined"
                {...params}
                value={params.inputProps.value ?? ''}
              />
            )}
          />
        )}
      />
    </>
  );
};

export default memo(AutocompleteSearchFilter);
