import { useMemo } from 'react';

import { createGoodsFilters, createJobsFilters, createPropertyFilters } from '@/shared/SearchFilters';

const sourceTypes = {
  Jobs: createJobsFilters(),
  Goods: createGoodsFilters(),
  Properties: createPropertyFilters(),
};

export const useDynamicFilters = (campaignType?: string) => {
  const filters = useMemo(() => sourceTypes[campaignType as keyof typeof sourceTypes], [campaignType]);

  return { filters };
};
