import { FC, ReactNode } from 'react';
import { StyledBox } from '@providers/styled';
import { SnackbarProvider as NotistackProvider } from 'notistack';

const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledBox>
    <NotistackProvider
      maxSnack={2}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      classes={{ containerAnchorOriginTopRight: 'topRight' }}
    >
      {children}
    </NotistackProvider>
  </StyledBox>
);

export default SnackbarProvider;
