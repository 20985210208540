import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledModal } from '@pages/CampaignDetails/styled';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Divider, Typography } from '@mui/material';
import { StyledIconBackButton } from '@components/PurchaseLeadsConfirmModal/styled';

const PurchaseLeadsConfirmModal = NiceModal.create(
  ({ totalPrice = 0, totalOwned = 0 }: { totalPrice: number; totalOwned: number }) => {
    const { t } = useTranslation();
    const modal = useModal();

    const handleConfirmClick = () => {
      modal.resolve(true);
      modal.remove();
    };
    const handleCancelClick = async () => {
      modal.resolve(false);
      modal.remove();
    };

    return (
      <StyledModal open={modal.visible} size="small" onTouchCancel={handleCancelClick} onClose={handleCancelClick}>
        <Box padding="12px 24px">
          <Box display="flex" justifyContent="center" alignItems="center" mb={5}>
            <StyledIconBackButton disableRipple onClick={handleCancelClick}>
              <ArrowBackIcon />
            </StyledIconBackButton>
            <Typography variant="h6" textAlign="center">
              {t('billing.orderPreview.title')}
            </Typography>
          </Box>

          <Box
            width="100%"
            px={2}
            py={1}
            my={2}
            display="flex"
            justifyContent="space-between"
            aria-label="total-price-in-tokens"
          >
            <Typography variant="subtitle1">{t('billing.totalPrice')}</Typography>
            <Typography variant="subtitle1">{`${totalPrice} ${t('common.tokens')}`}</Typography>
          </Box>

          <Box
            width="100%"
            px={2}
            py={1}
            my={2}
            display="flex"
            justifyContent="space-between"
            aria-label="total-price-in-tokens"
          >
            <Typography variant="subtitle1">{t('billing.totalOwned')}</Typography>
            <Typography variant="subtitle1">{`${totalOwned} ${t('common.titles.leads').toLowerCase()}`}</Typography>
          </Box>

          <Divider />

          <Box mt={5}>
            <Button fullWidth variant="contained" color="primary" onClick={handleConfirmClick}>
              {t('common.confirm')}
            </Button>
          </Box>
        </Box>
      </StyledModal>
    );
  },
);

export const PurchaseLeadsConfirmModalId = 'PurchaseLeadsConfirmModal';

NiceModal.register(PurchaseLeadsConfirmModalId, PurchaseLeadsConfirmModal);
