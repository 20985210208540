import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { CAMPAIGN_ID } from '@constants/common';
import { useCampaignTypes } from '@hooks/api/useCampaignTypes';
import { useUser } from '@hooks/api/useUser';
import { useGetSearchParam } from '@hooks/common/useGetSearchParams';
import ScraperSettings from '@pages/LeadScraper/components/ScraperSettings';
import ScrapperSummary from '@pages/LeadScraper/components/ScrapperSummary';
import { ScraperProps } from '@pages/LeadScraper/types';
import AppLayout from '@providers/layouts/AppLayout/AppLayout';

import { Box } from '@mui/material';
import Loading from '@components/Loading/Loading';

import { LeadScraperProvider } from './context';

const LeadScraper: FC<ScraperProps<string>> = ({
  title,
  scraperSubtitleLocale,
  submitButtonLocale,
  breadCrumbsLocale,
  editable = false,
}) => {
  const { t } = useTranslation();
  const { isLoading: isUserLoading } = useUser();
  const { isLoading: isCampaignTypesLoading } = useCampaignTypes();
  const campaignId: string | null = useGetSearchParam(CAMPAIGN_ID);

  const isLoading = [isUserLoading, isCampaignTypesLoading].some(Boolean);

  if (isLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{t(title)}</title>
      </Helmet>
      <LeadScraperProvider
        campaignId={campaignId}
        scraperSubtitleLocale={scraperSubtitleLocale}
        submitButtonLocale={submitButtonLocale}
        breadCrumbsLocale={breadCrumbsLocale}
        editable={editable}
      >
        <AppLayout showSidebar={false}>
          <Box height="100%" display="flex" justifyContent="center">
            <ScraperSettings />
            <ScrapperSummary />
          </Box>
        </AppLayout>
      </LeadScraperProvider>
    </>
  );
};

export default memo(LeadScraper);
