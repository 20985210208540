import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import AddScraperButton from '@components/AddScraperButton/AddScraperButton';

const NoCampaignsMessage: FC = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" flexDirection="column" gap={2}>
      <Typography variant="h6">{t('dashboard.campaignsMessage')}</Typography>
      <AddScraperButton />
    </Box>
  );
};

export default memo(NoCampaignsMessage);
