import { skipForwardProp } from '@utils/styled';

import {
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
  ListItemProps,
  ListItemText,
  ListItemTextProps,
  ListProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledList = styled(List)<ListProps>({
  margin: '12px',
});

export const StyledListItem = styled(ListItem, { shouldForwardProp: skipForwardProp(['activePage', 'route']) })<
  ListItemProps & { activePage: string; route: string; theme?: Theme }
>(({ theme, activePage, route }) => ({
  backgroundColor: activePage === route ? theme.colors.primary.list : 'transparent',
  color: activePage === route ? theme.colors.primary.main : theme.colors.secondary.main,
  borderRadius: '15px',
  margin: '10px 0',
  height: '48px',
  maxWidth: '225px',
}));

export const StyledListItemButton = styled(ListItemButton, { shouldForwardProp: skipForwardProp('expanded') })<
  ListItemButtonProps & { expanded: boolean }
>(({ expanded }) => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  width: expanded ? 'inherit' : '24px',
}));

export const StyledListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: skipForwardProp('activePage'),
})<ListItemIconProps & { activePage: string; route: string; theme?: Theme }>(({ theme, activePage, route }) => ({
  color: activePage === route ? theme.colors.primary.main : theme.colors.secondary.outline,
}));

export const StyledListItemText = styled(ListItemText, { shouldForwardProp: skipForwardProp('expanded') })<
  ListItemTextProps & { expanded: boolean }
>(({ expanded }) => ({
  '& .MuiTypography-root': {
    fontWeight: 600,
    fontSize: '16px',
  },
  minWidth: expanded ? '150px' : 0,
}));

export const StyledIconButton = styled(IconButton)<IconButtonProps>(() => ({
  '&.MuiButtonBase-root': {
    marginTop: '35px',
  },
  '&.MuiIconButton-root:hover': {
    backgroundColor: 'transparent',
  },
  marginRight: '-23px',
}));
