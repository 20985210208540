import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CAMPAIGN_ID } from '@constants/common';
import { ROUTING } from '@constants/routing';
import CampaignDetails from '@pages/CampaignDetails';
import Dashboard from '@pages/Dashboard';
import LeadScrapers from '@pages/LeadScrapers';
import { LeadScrapersProvider } from '@pages/LeadScrapers/context';
import LeadsDatabase from '@pages/LeadsDatabase';
import Pricing from '@pages/Pricing';
import Settings from '@pages/Settings';
import PrivatePreviewLayout from '@providers/layouts/PrivatePreviewLayout/PrivatePreviewLayout';
import PublicRegistrationGuard from '@providers/PublicRegistrationGuard';
import PrivateRoutes from '@providers/routes/PrivateRoutes';
import LeadScraper from 'src/pages/LeadScraper';

import Loading from '@components/Loading/Loading';
import RegistrationLayout from '@components/RegistrationView';

import { ProtectedRoutesProvider } from './shared/ProtectedRoutesContext';

const Login = lazy(() => import('./pages/Login'));
const Signup = lazy(() => import('./pages/Signup'));
const ConfirmEmail = lazy(() => import('./pages/ConfirmEmail'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const CheckEmail = lazy(() => import('./pages/CheckEmail'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const NotFound = lazy(() => import('./pages/NotFound'));

const PUBLIC_ROUTES = [
  { path: ROUTING.LOGIN, Component: Login },
  { path: ROUTING.SIGNUP, Component: Signup },
  { path: ROUTING.FORGOT_PASSWORD, Component: ForgotPassword },
  { path: ROUTING.CHECK_EMAIL, Component: CheckEmail },
  { path: ROUTING.RESET_PASSWORD, Component: ResetPassword },
];

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      {
        element: (
          <PrivatePreviewLayout>
            <ProtectedRoutesProvider>
              <Dashboard />
            </ProtectedRoutesProvider>
          </PrivatePreviewLayout>
        ),
        children: [
          {
            index: true,
            element: (
              <LeadScrapersProvider>
                <LeadScrapers />
              </LeadScrapersProvider>
            ),
          },
          {
            path: ROUTING.LEADS_DATABASE,
            element: <LeadsDatabase />,
          },
          {
            path: ROUTING.SETTINGS,
            element: <Settings />,
          },
          {
            path: ROUTING.NEW_SCRAPER,
            element: (
              <LeadScraper
                title="common.helmetTitles.newScraper"
                scraperSubtitleLocale="dashboard.navigation.startScraper"
                submitButtonLocale="dashboard.newScraper.createCampaign"
                breadCrumbsLocale="dashboard.navigation.scrapers"
              />
            ),
          },
          {
            path: `${ROUTING.CAMPAIGN_DETAILS}/:${CAMPAIGN_ID}`,
            element: <CampaignDetails />,
          },
          {
            path: `${ROUTING.EDIT_SCRAPER}/:${CAMPAIGN_ID}`,
            element: (
              <LeadScraper
                editable
                title="common.helmetTitles.editScraper"
                scraperSubtitleLocale="dashboard.navigation.editScraper"
                submitButtonLocale="dashboard.editScraper.editCampaign"
                breadCrumbsLocale="dashboard.navigation.campaignDetails"
              />
            ),
          },
          {
            path: `${ROUTING.PRICING}`,
            element: <Pricing />,
          },
        ],
      },
      {
        path: ROUTING.CONFIRM_EMAIL,
        element: (
          <Suspense fallback={<Loading />}>
            <RegistrationLayout>
              <ConfirmEmail />
            </RegistrationLayout>
          </Suspense>
        ),
      },
    ],
  },
  ...PUBLIC_ROUTES.map(({ path, Component }) => ({
    path,
    element: (
      <Suspense fallback={<Loading />}>
        <PublicRegistrationGuard>
          <RegistrationLayout>
            <Component key={path} />
          </RegistrationLayout>
        </PublicRegistrationGuard>
      </Suspense>
    ),
  })),
  {
    path: '*',
    element: (
      <Suspense>
        <NotFound />
      </Suspense>
    ),
  },
]);

const Routing = () => <RouterProvider router={router} />;

export default Routing;
