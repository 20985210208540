import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useGetBalanceQuery, useGetCampaignsQuery } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';
import MobileScrapersList from '@pages/LeadScrapers/components/ScrapersList/MobileScrapersList';
import { useLeadScrapersContext } from '@pages/LeadScrapers/context';
import { useScraperFiltersUrlParams } from '@pages/LeadScrapers/hooks/useScraperFiltersUrlParams';
import AppLayout from '@providers/layouts/AppLayout/AppLayout';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';

import ScrapersInfo from './components/ScrapersInfo';
import ScrapersList from './components/ScrapersList';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const LeadScrappers: FC = () => {
  const { t } = useTranslation();
  const { page, limit, setPage, setLimit } = usePageFilterUrlParams();
  const { type, status } = useScraperFiltersUrlParams();
  const snackbar = useSnackbar();
  const { isLargerScreen } = useScreenSizeContext();
  const { showScheduled } = useLeadScrapersContext();

  const {
    data: campaignsData,
    error: campaignsError,
    isFetching: isFetchingCampaigns,
  } = useGetCampaignsQuery(
    {
      page,
      limit,
      includeFuture: showScheduled,
      ...(type > 0 && { campaignType: type }),
      ...(status >= 0 && { status }),
    },
    { refetchOnMountOrArgChange: true },
  );
  const { data: balanceData } = useGetBalanceQuery();

  if (campaignsError) {
    snackbar.enqueueSnackbar(getErrorMessage(campaignsError, API_ERROR_MSG_PATH), { variant: ERROR });
  }

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.leadScrappers')}</title>
      </Helmet>
      <AppLayout showSidebar={isLargerScreen}>
        <Box py={3} display="flex" flexDirection="column" gap={3} height="100%">
          <ScrapersInfo balanceData={balanceData} campaigns={campaignsData} isLargerScreen={isLargerScreen} />
          {isLargerScreen && (
            <ScrapersList
              campaigns={campaignsData}
              handlePageChange={handlePaginationModelChange}
              paginationModel={{ page, pageSize: limit }}
              isLargerScreen={isLargerScreen}
            />
          )}
          {!isLargerScreen && (
            <MobileScrapersList
              campaigns={campaignsData}
              isLargerScreen={isLargerScreen}
              isLoading={isFetchingCampaigns}
              setPage={setPage}
              page={page}
            />
          )}
        </Box>
      </AppLayout>
    </>
  );
};

export default memo(LeadScrappers);
