import { CountryCode } from 'libphonenumber-js/types';

export const getCountryCode = (): CountryCode => {
  const { language } = navigator;
  // Split the language code by '-' and take the first part which represents the country code
  const countryCode = language.split('-')[1]?.toUpperCase();

  if (countryCode && countryCode.length === 2 && /^[A-Z]+$/.test(countryCode)) {
    return countryCode as CountryCode;
  }
  return 'US';
};
