export const API_ERROR_MSG_PATH = 'data.error';
export const COUNTDOWN_DATE = new Date(0);
export const HEADER_HEIGHT_DESKTOP = 80;
export const CAMPAIGN_ID = 'campaignId';
export const TOKENS = 'tokens';
export const YES = 'Yes';
export const NO = 'No';
export const NOT_AVAILABLE = 'N/A';
export const DATAGRID_FOOTER_HEIGHT = 52;
export const LEADS_TABLE_ADJUSTED_HEIGHT = 240;
export const SLIDER_MIN_VAL = 0;
export const SLIDER_MAX_VAL = 30;
export const BILLING_FREE_TOKENS_AMOUNT = 500;
export const BILLING_ESSENTIALS_TOKENS_AMOUNT = 1000;
export const BILLING_PRO_TOKENS_AMOUNT = 4500;
export const MAX_PROPERTY_PRICE = 20_000_000;
export const MAX_GOODS_PRICE = 1_000_000;
export const MAX_SQFT_VALUE = 10_000;
export const TOOLTIP_MAX_CHARS = 15;
export const PRIORITY_KEYS = ['name', 'companyName', 'jobTitle', 'title', 'email', 'phone', 'address', 'location'];
export const CONTACT_US_EMAIL = 'info@scrapeking.com';
export const TRIAL_DAYS = 14;
export const DEFAULT_PAGE_LIMIT = 25;
export const MAX_DAYS_IN_MONTH = 31;
export const MONTHS_IN_YEAR = 12;
export const PROCESSING_FEE = 0.03;
export const PLUS_SIGN = '+';
export const MINUS_SIGN = '-';
