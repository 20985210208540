import { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TOKENS } from '@constants/common';
import { NUMBER_OF_LEADS } from '@pages/LeadScraper/constants';
import { useLeadScraperContext } from '@pages/LeadScraper/context';
import { ScraperData } from '@pages/LeadScraper/types';
import { isFormFilled } from '@pages/LeadScraper/utils';
import { calculatePerLeadPrice } from '@pages/LeadScraper/utils/calculatePrice';
import { capitalize } from '@utils/formatInitials';

import { Box, Typography } from '@mui/material';

import { StyledAreaBox } from './styled';

const ScraperSettingsSummary: FC = () => {
  const { t } = useTranslation();
  const { sourceSettingsForm } = useLeadScraperContext();
  const { autocomplete } = sourceSettingsForm.watch();
  const isFilled = autocomplete?.length > 0 && autocomplete.some(isFormFilled);

  return (
    <>
      {isFilled && (
        <StyledAreaBox>
          <Typography variant="subtitle2">{t('dashboard.newScraper.settings.sourceSettingsTitle')}</Typography>
          <Box display="flex" flexDirection="column" gap={1} mt={1}>
            {autocomplete?.map((entry: ScraperData) => {
              const settings = Object.entries(entry.filters)
                .filter(([k]) => k !== NUMBER_OF_LEADS)
                .map(([key, val]) => (
                  <Fragment key={`${key}.${val}`}>
                    {val && (
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption" color="text.secondary">
                          {capitalize(key)}
                        </Typography>
                        <Typography variant="body2">{String(val)}</Typography>
                      </Box>
                    )}
                  </Fragment>
                ));

              return (
                <Fragment key={entry.scrapers_id}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle2">{capitalize(entry.site)}</Typography>
                    <Typography variant="caption" color="text.secondary" ml={1}>
                      {entry.filters.numberOfLeads
                        ? `${entry.filters.numberOfLeads} ${t('dashboard.newScraper.leadsLabel')} / ${calculatePerLeadPrice(entry)} ${TOKENS}`
                        : ''}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    {settings}
                  </Box>
                </Fragment>
              );
            })}
          </Box>
        </StyledAreaBox>
      )}
    </>
  );
};

export default memo(ScraperSettingsSummary);
