import { ReactTagManager } from 'react-gtm-ts';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import { PricingProvider } from '@pages/Pricing/context';
import { DataPickerLocalizationProvider } from '@providers/dataPicker';
import WebsocketProvider from '@providers/ws/WebsocketProvider';
import * as Sentry from '@sentry/react';
import { store } from '@store/store';

import Routing from './Routing';
import { ScreenSizeProvider } from '@/shared/context/ScreenSizeContext';

ReactTagManager.init({
  code: import.meta.env.VITE_GTM, // GTM Code
  debug: false, // debug mode (default false)
  performance: false, // starts GTM only after user interaction (improve initial page load)
});

Sentry.init({
  dsn: 'https://6b4b0e53e0e8efcca4fabc26966d5057@o4507493369708544.ingest.us.sentry.io/4507493372002304',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  enabled: import.meta.env.VITE_NODE_ENV !== 'development',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/app.scrapeking\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: event => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PricingProvider>
          <ScreenSizeProvider>
            <DataPickerLocalizationProvider>
              <NiceModal.Provider>
                <WebsocketProvider>
                  <Routing />
                </WebsocketProvider>
              </NiceModal.Provider>
            </DataPickerLocalizationProvider>
          </ScreenSizeProvider>
        </PricingProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
