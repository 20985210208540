import { FC, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useUser } from '@hooks/api/useUser';
import { useBilling } from '@hooks/common/useBilling';
import VerticalPlanCard from '@pages/Pricing/components/VerticalPlanCard';
import { usePricingContext } from '@pages/Pricing/context';
import { StyledFreeButton, StyledPricingBox } from '@pages/Pricing/styled';
import AppLayout from '@providers/layouts/AppLayout/AppLayout';

import { Box, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';
import Toggle from '@components/ToggleButtonGroup';

import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const Pricing: FC = () => {
  const { isLoading } = useUser();
  const { t } = useTranslation();
  const { period, setPeriod } = usePricingContext();
  const { config } = useBilling(period);
  const { isLargerScreen } = useScreenSizeContext();

  if (isLoading) return <Loading />;

  return (
    <>
      <Helmet>
        <title>{t('common.helmetTitles.pricing')}</title>
      </Helmet>
      <AppLayout showSidebar={false} showFooter>
        <Box m={isLargerScreen ? 4 : 1}>
          <StyledPricingBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={3}
            px={isLargerScreen ? 1 : 3}
          >
            <Typography variant="h4" color="white" textAlign={!isLargerScreen ? 'center' : 'start'}>
              {t('billing.pricingPage.title')}
            </Typography>
            <Typography variant="body2" color="grey" textAlign={!isLargerScreen ? 'center' : 'start'}>
              {t('billing.pricingPage.subtitle')}
            </Typography>
            <StyledFreeButton disableRipple variant="contained" color="primary" size="large">
              {t('billing.pricingPage.btnText')}
            </StyledFreeButton>
          </StyledPricingBox>

          <Box display="flex" justifyContent="center" mt={3}>
            <Toggle active={period} setActive={setPeriod} isMobile={!isLargerScreen} />
          </Box>

          <Box display="flex" gap={3} mt={3} justifyContent="center" flexDirection={isLargerScreen ? 'row' : 'column'}>
            {config.map((card: any) => (
              <VerticalPlanCard key={card.id} {...card} isMobile={!isLargerScreen} />
            ))}
          </Box>
        </Box>
      </AppLayout>
    </>
  );
};

export default memo(Pricing);
