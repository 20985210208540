import { FC, memo } from 'react';
import { StyledDrawer } from '@providers/layouts/AppLayout/components/MobileDrawer/styled';
import Navigation from '@providers/layouts/AppLayout/components/Navigation/Navigation';

import { useProtectedRoutesContext } from '@/shared/ProtectedRoutesContext';

const MobileDrawer: FC = () => {
  const { expandedMenu, toggleMenu } = useProtectedRoutesContext();

  return (
    <StyledDrawer open={expandedMenu} onClose={toggleMenu}>
      <Navigation />
    </StyledDrawer>
  );
};

export default memo(MobileDrawer);
