import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { footerConfig } from '@providers/layouts/AppLayout/constants';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, Typography } from '@mui/material';

import { StyledAccordion, StyledAccordionDetails, StyledFooterBox } from './styled';

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFooterBox
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py={6}
      px={2}
    >
      <Typography variant="h5" mb={3}>
        {t('billing.footer.title')}
      </Typography>
      {footerConfig.map(item => (
        <StyledAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id={item.title}>
            <Typography variant="h6">{item.title}</Typography>
          </AccordionSummary>
          <StyledAccordionDetails>
            <Typography variant="body2">{item.details}</Typography>
          </StyledAccordionDetails>
        </StyledAccordion>
      ))}
    </StyledFooterBox>
  );
};

export default memo(Footer);
