import { matchIsValidTel } from 'mui-tel-input';
import * as yup from 'yup';

export const profileSchema = yup.object().shape(
  {
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email(),
    phone: yup
      .string()
      .required()
      .test('isValid', 'Please provide a valid phone number', val => matchIsValidTel(val)),
    oldPassword: yup.string().when(['newPassword', 'confirmNewPassword'], {
      is: (val: string) => val,
      then: schema =>
        schema
          .minMax(6, 25)
          .containsLowercase()
          .containsDigit()
          .containsSpecialCharacter()
          .containsUppercase()
          .required(),
      otherwise: schema => schema.min(0),
    }),
    newPassword: yup.string().when(['oldPassword', 'confirmNewPassword'], {
      is: (val: string) => val,
      then: schema =>
        schema
          .minMax(6, 25)
          .containsLowercase()
          .containsDigit()
          .containsSpecialCharacter()
          .containsUppercase()
          .required(),
      otherwise: schema => schema.min(0),
    }),
    confirmNewPassword: yup.string().when(['oldPassword', 'newPassword'], {
      is: (val: string) => val,
      then: schema =>
        schema.required('Please retype your password.').oneOf([yup.ref('newPassword')], 'Your passwords do not match.'),
      otherwise: schema => schema.min(0),
    }),
  },
  [
    ['oldPassword', 'newPassword'],
    ['oldPassword', 'confirmNewPassword'],
    ['newPassword', 'confirmNewPassword'],
  ],
);
