import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Box, IconButton, Typography } from '@mui/material';

const EditableBlock: FC<{
  handleClick: () => void;
  title: string;
  subtitle: string;
  isCompleted: boolean;
}> = ({ handleClick, title, subtitle, isCompleted }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex">
        {isCompleted && <CheckCircleRoundedIcon color="success" />}
        <Box display="flex" flexDirection="column" pl={1}>
          <Typography color="text.primary" variant="subtitle2">
            {t(title)}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            {t(subtitle)}
          </Typography>
        </Box>
      </Box>
      {isCompleted && (
        <Box display="flex" alignItems="center" justifySelf="end">
          <IconButton onClick={handleClick}>
            <EditRoundedIcon color="primary" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default memo(EditableBlock);
