import { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TOKENS } from '@constants/common';
import { FiltersSummaryProps } from '@pages/LeadScraper/types';
import { calcPaidFiltersPrice } from '@pages/LeadScraper/utils/calculatePrice';

import { Box, Typography } from '@mui/material';

import { StyledAreaBox } from './styled';

const FiltersSummary: FC<FiltersSummaryProps> = ({
  filtersData,
  isFormChanged,
  selectedFilters,
  formData,
  totalNumberOfLeads,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isFormChanged && (
        <StyledAreaBox>
          <Typography variant="subtitle2">{t('dashboard.newScraper.settings.filtersTitle')}</Typography>
          <Box display="flex" flexWrap="wrap">
            <Box display="flex" justifyContent="space-between" width="100%">
              {Object.entries(formData?.filters?.paidFilters).map(([key, val]) => (
                <Box display="flex" flexDirection="column" pt={1}>
                  <Typography variant="caption" color="text.secondary">
                    {key}
                  </Typography>
                  <Typography variant="body2">{val as string}</Typography>
                </Box>
              ))}
              {filtersData
                ?.filter(v => v.price)
                .map(item => (
                  <Fragment key={item.campaign_filters_id}>
                    <Box display="flex" pt={1}>
                      <Typography variant="caption" color="text.secondary">
                        {calcPaidFiltersPrice(filtersData, selectedFilters) *
                          (totalNumberOfLeads > 0 ? totalNumberOfLeads : 1)}{' '}
                        {TOKENS}
                      </Typography>
                    </Box>
                  </Fragment>
                ))}
            </Box>
          </Box>
        </StyledAreaBox>
      )}
    </>
  );
};

export default memo(FiltersSummary);
