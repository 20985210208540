import { FC, memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useCampaignTypes } from '@hooks/api/useCampaignTypes';
import { useLeadScraperContext } from '@pages/LeadScraper/context';
import { StepContentProps } from '@pages/LeadScraper/types';
import { StyledAreaBox } from '@pages/styled';

import EditableBlock from '../EditableBlock';

import BasicInformationForm from './BasicInformationForm';

const BasicInformation: FC<StepContentProps> = ({ handleNext, handleBack, isActive, isCompleted }) => {
  const { basicInfoForm } = useLeadScraperContext();
  const { data } = useCampaignTypes();

  return (
    <StyledAreaBox ml="8px" active={isActive}>
      <EditableBlock
        isCompleted={isCompleted}
        handleClick={handleBack}
        title="dashboard.newScraper.settings.basicInfoTitle"
        subtitle="dashboard.newScraper.settings.basicInfoSubtitle"
      />

      {isActive && (
        <FormProvider {...basicInfoForm}>
          <form onSubmit={basicInfoForm.handleSubmit(handleNext)}>
            <BasicInformationForm campaignTypes={data} />
          </form>
        </FormProvider>
      )}
    </StyledAreaBox>
  );
};

export default memo(BasicInformation);
