import { FC, memo, MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CampaignScrapersDto, useGetCampaignQuery, useGetLeadsQuery } from '@api/api';
import { ERROR } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import { useCampaignFilters } from '@hooks/api/useCampaignFilters';
import { useCustomFiltersQueryParam } from '@hooks/common/useDynamicFilters/useCustomFiltersQueryParam';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';
import { useCampaignDetailsContext } from '@pages/CampaignDetails/context';
import { useSourceNameQueryParams } from '@pages/CampaignDetails/hooks/useSourceNameQueryParams';
import { transformQuery } from '@pages/CampaignDetails/utils';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';
import qs from 'qs';

import { CampaignLeadsTable } from '@components/LeadsTable';
import { ExtendedPaginatedLeadsData } from '@components/LeadsTable/types';

import SourcesList from './components/SourcesList';

const Leads: FC = () => {
  const { campaignId } = useParams();
  const { sourceName, handleChangeSourceName } = useSourceNameQueryParams();
  const { dynamicParam } = useCustomFiltersQueryParam();
  const { page, limit } = usePageFilterUrlParams();
  const { sourceIndex } = useCampaignDetailsContext();
  const snackbar = useSnackbar();

  const serializedFilters =
    dynamicParam && encodeURIComponent(qs.stringify(transformQuery(dynamicParam), { encode: false }));

  const {
    data: campaignData,
    isLoading: campaignIsLoading,
    isFetching: isFetchingCampaign,
    error: campaignError,
  } = useGetCampaignQuery({ id: campaignId as string }, { refetchOnMountOrArgChange: true });

  const {
    data: filtersData,
    isLoading: isFiltersLoading,
    error: campaignFilterError,
  } = useCampaignFilters(String(campaignData?.campaign_type_id), !campaignData?.campaign_type_id);

  const handleChange = (_: MouseEvent<HTMLElement>, newAlignment: CampaignScrapersDto) => {
    handleChangeSourceName(newAlignment);
  };

  const uniqueScrapersList = campaignData?.campaign_scrapers.filter(
    (item, index, arr) =>
      arr.findIndex(t => t.scrapers.scraper_name.trim() === item.scrapers.scraper_name.trim()) === index,
  );

  const scrapersId = campaignData?.campaign_scrapers.find(
    scraper => scraper.scrapers.scraper_name.trim() === sourceName,
  )?.scrapers.scrapers_id;

  const {
    data: leadsData,
    isLoading: isLoadingLeads,
    isFetching: isFetchingLeads,
    refetch: refetchLeads,
    error: leadsError,
  } = useGetLeadsQuery(
    {
      page,
      limit,
      campaignId: +campaignId!,
      scraperId: +scrapersId!,
      campaignType: campaignData?.campaign_type_id,
      ...(dynamicParam && { filters: serializedFilters }),
    },
    { refetchOnMountOrArgChange: true, skip: !scrapersId || !campaignData?.campaign_type_id },
  );

  const campaignLeadsRequested =
    campaignData?.campaign_scrapers.find(campaign => campaign.scrapers.scraper_name.trim() === sourceName)
      ?.leads_count || 0;

  const isLoading = [
    campaignIsLoading,
    isLoadingLeads,
    isFetchingLeads,
    isFetchingCampaign,
    !scrapersId,
    isFiltersLoading,
  ].some(Boolean);

  const errorArray = [campaignError, campaignFilterError, leadsError];

  if (errorArray.some(Boolean)) {
    const error = errorArray.filter(err => err);
    snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
  }

  useEffect(() => {
    if (uniqueScrapersList?.length) {
      handleChangeSourceName(uniqueScrapersList?.[sourceIndex], { replace: true });
    }
  }, []);

  return (
    <>
      <SourcesList data={uniqueScrapersList} handleChange={handleChange} active={sourceName as string} />
      <CampaignLeadsTable
        totalLeadsFound={leadsData?.total}
        totalLeadsRequested={campaignLeadsRequested}
        campaign={campaignData}
        leadsData={leadsData as ExtendedPaginatedLeadsData}
        autocompleteSx={{ minWidth: '200px', margin: '16px 0' }}
        isLoadingLeads={isLoading}
        filtersData={filtersData}
        refetch={refetchLeads}
        serializedFilters={serializedFilters}
        showSelected
      />
    </>
  );
};

export default memo(Leads);
