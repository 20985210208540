import styled from '@mui/system/styled';

const CollapseMenu = styled(props => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H20C24.4183 0 28 3.58172 28 8V20C28 24.4183 24.4183 28 20 28H8C3.58172 28 0 24.4183 0 20V8Z"
      fill="white"
    />
    <path
      d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H20C24.1421 0.5 27.5 3.85786 27.5 8V20C27.5 24.1421 24.1421 27.5 20 27.5H8C3.85786 27.5 0.5 24.1421 0.5 20V8Z"
      stroke="black"
      strokeOpacity="0.12"
    />
    <path
      d="M8.16667 21.5C7.70833 21.5 7.31597 21.3368 6.98958 21.0104C6.66319 20.684 6.5 20.2917 6.5 19.8333V8.16667C6.5 7.70833 6.66319 7.31597 6.98958 6.98958C7.31597 6.66319 7.70833 6.5 8.16667 6.5H19.8333C20.2917 6.5 20.684 6.66319 21.0104 6.98958C21.3368 7.31597 21.5 7.70833 21.5 8.16667V19.8333C21.5 20.2917 21.3368 20.684 21.0104 21.0104C20.684 21.3368 20.2917 21.5 19.8333 21.5H8.16667ZM10.6667 19.8333V8.16667H8.16667V19.8333H10.6667ZM12.3333 19.8333H19.8333V8.16667H12.3333V19.8333Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5059 10.9107C17.1805 10.5853 16.6528 10.5853 16.3274 10.9107L13.8274 13.4107C13.502 13.7362 13.502 14.2638 13.8274 14.5893L16.3274 17.0893C16.6528 17.4147 17.1805 17.4147 17.5059 17.0893C17.8314 16.7638 17.8314 16.2362 17.5059 15.9107L15.5952 14L17.5059 12.0893C17.8314 11.7638 17.8314 11.2362 17.5059 10.9107Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
))({});

export default CollapseMenu;
