import { FieldValues, UseFormGetValues } from 'react-hook-form';

export function hasTruthyValue(obj: UseFormGetValues<FieldValues>) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (acc) return true;

    if (
      // @ts-ignore
      (key === 'data_filter_+gte+description$beds' && value === 0 && obj['data_filter_+lte+description$beds'] === 30) ||
      // @ts-ignore
      (key === 'data_filter_+gte+description$baths' && value === 0 && obj['data_filter_+lte+description$baths'] === 30)
    ) {
      return false;
    }

    return (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      !(typeof value === 'object' && Array.isArray(value) && Object.keys(value).length === 0)
    );
  }, false);
}
