import { Box, BoxProps, IconButton, IconButtonProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

export const StyledIconBackButton = styled(IconButton)<IconButtonProps>({
  position: 'absolute',
  left: '20px',
});

export const StyledSubtitleBox = styled(Box)<BoxProps>(() => ({
  backgroundColor: grey[100],
  borderRadius: '12px',
}));
