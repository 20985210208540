/* eslint-disable no-param-reassign */
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { PRIORITY_KEYS } from '@constants/common';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { isObject, mapKeys, upperFirst } from 'lodash-es';

import { Box, Drawer, Grid, Typography } from '@mui/material';

import { StyledData, StyledList, StyledOkButton } from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

interface FullDetailsDrawerProps {
  row: {
    data: Record<string, any>;
    raw: Record<string, any>;
  };
}

const FullDetailsDrawer = NiceModal.create<FullDetailsDrawerProps>(({ row }) => {
  const modal = useModal();
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenSizeContext();

  const handleConfirmClick = () => {
    modal.resolve(true);
    modal.remove();
  };

  const filterData = (data: any) =>
    Object.entries(data)
      .filter(
        ([, value]) =>
          value !== null &&
          value !== true &&
          value !== false &&
          value !== 'UNKNOWN' &&
          (typeof value !== 'number' || value.toString().length !== 1) &&
          (typeof value !== 'string' || value.toUpperCase() !== 'UNKNOWN'),
      )
      .reduce(
        (acc, [key, value]) => {
          if (isObject(value)) {
            const filteredValue = filterData(value);
            if (Object.keys(filteredValue).length > 0) {
              acc[key] = filteredValue;
            }
          } else {
            acc[key] = value;
          }
          return acc;
        },
        {} as Record<string, any>,
      );

  const removeDuplicates = (data: Record<string, any>) => {
    const seenValues = new Set();
    return Object.entries(data).reduce(
      (acc, [key, value]) => {
        const stringValue = JSON.stringify(value);
        if (!seenValues.has(stringValue)) {
          seenValues.add(stringValue);
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, any>,
    );
  };

  const prioritizeData = (data: Record<string, any>) => {
    const prioritizedData: Record<string, any> = {};

    const recursiveSearch = (info: any) => {
      Object.entries(info).forEach(([key, value]) => {
        const lowerKey = key.toLowerCase();

        PRIORITY_KEYS.forEach(prioritizedKey => {
          if (lowerKey === prioritizedKey.toLowerCase()) {
            prioritizedData[prioritizedKey] = value;
          }
        });

        if (isObject(value)) {
          recursiveSearch(value);
        }
      });
    };

    recursiveSearch(data);

    return { ...prioritizedData, ...data };
  };

  const renderContent = (inputData: any) => {
    const result: JSX.Element[] = [];

    const traverseData = (data: any) => {
      Object.entries(data).forEach(([key, value]) => {
        if (value === undefined) return;

        const displayKey = upperFirst(key.replaceAll('_', ' '));
        const isNestedObj = isObject(value) || Array.isArray(value);

        if (isNestedObj && Object.keys(value).length === 0) return;

        if (isNestedObj) {
          traverseData(value);
        } else {
          result.push(
            <Fragment key={`${displayKey}-${value}`}>
              <Grid container spacing={2} alignItems="flex-start" my={1}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" whiteSpace="wrap">
                    {displayKey}:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <StyledData variant="subtitle1">
                    {Array.isArray(value)
                      ? value.join(', ').replace(/"/g, '')
                      : JSON.stringify(value).replace(/%20/g, '').replace(/"/g, '')}
                  </StyledData>
                </Grid>
              </Grid>
            </Fragment>,
          );
        }
      });
    };

    traverseData(inputData);
    return result;
  };

  const filteredData = filterData(row.raw ? row.raw : row);
  const deduplicatedData = removeDuplicates(filteredData);
  const prioritizedData = prioritizeData(deduplicatedData);
  const converted = mapKeys(prioritizedData, (_, key) => upperFirst(key).replaceAll('_', ' '));

  return (
    <Drawer anchor="right" open={modal.visible} onClose={handleConfirmClick} ModalProps={{ keepMounted: true }}>
      <Box maxWidth={isLargerScreen ? '1000px' : '400px'} p={2}>
        <Box mb={2} pl={2}>
          <Typography variant="h6" mb={3} textAlign="center">
            {t('common.fullDetailsModalTitle')}
          </Typography>
          <StyledList>{renderContent(converted)}</StyledList>
        </Box>
        <StyledOkButton fullWidth variant="contained" color="primary" onClick={handleConfirmClick}>
          {t('common.okBtn')}
        </StyledOkButton>
      </Box>
    </Drawer>
  );
});

export const FullDetailsModalId = 'FullDetailsModal';
NiceModal.register('FullDetailsModal', FullDetailsDrawer);
