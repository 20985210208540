import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PERIOD as period } from '@constants/billing';

import { Chip, Stack } from '@mui/material';

import { StyledBox, StyledStack, StyledStackContainer, StyledTypography } from './styled';
import { ToggleProps } from '@/shared/types';

const Toggle: FC<ToggleProps> = ({ active, setActive, isMobile }) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="center" mt={4}>
      <StyledStackContainer width={isMobile ? '360px' : '440px'} height="50px" direction="row">
        <StyledStack width="50%" justifyContent="center" alignItems="center" onClick={() => setActive(period.month)}>
          <StyledTypography fontWeight="bold" fontSize="15px" lineHeight="26px" isActive={active === period.month}>
            {t('billing.period.month')}
          </StyledTypography>
        </StyledStack>
        <StyledStack
          width="50%"
          justifyContent="center"
          alignItems="center"
          direction="row"
          gap={isMobile ? '5px' : '10px'}
          onClick={() => setActive(period.year)}
        >
          <StyledTypography fontWeight="bold" fontSize="15px" lineHeight="26px" isActive={active === period.year}>
            {t('billing.period.year')}
          </StyledTypography>
          <Chip label="- 20%" color="warning" />
        </StyledStack>
        {/* @ts-ignore */}
        <StyledBox width={isMobile ? '180px' : '210px'} height="42px" active={active} isMobile={isMobile} />
      </StyledStackContainer>
    </Stack>
  );
};

export default memo(Toggle);
