import { Accordion, AccordionDetails, AccordionDetailsProps, AccordionProps, Box, BoxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import styled from '@mui/system/styled';

export const StyledFooterBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.background.paperElevation0,
}));

export const StyledAccordion = styled(Accordion)<AccordionProps & { theme?: Theme }>(({ theme }) => ({
  maxWidth: '800px',
  margin: '8px 0',
  '&::before': { backgroundColor: 'transparent' },
  '&.MuiPaper-root': {
    borderRadius: '12px',
    border: `1px solid ${theme.colors.secondary.border}`,
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)<AccordionDetailsProps & { theme?: Theme }>(
  ({ theme }) => ({
    borderTop: `1px solid ${theme.colors.secondary.border}`,
  }),
);
