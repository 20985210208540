import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useTabs } from '@hooks/common/useTabs';
import { Tabs } from '@pages/LeadsDatabase/enums';

const LeadsDatabaseContext = createContext<ReturnType<typeof useLeadsDatabaseContextValue> | null>(null);

const useLeadsDatabaseContextValue = () => {
  const { tab, handleChange, setTab } = useTabs(Tabs.MY_LEADS, Tabs);

  return { tab, handleChange, setTab };
};

export const useLeadsDatabaseContext = () => {
  const context = useContext(LeadsDatabaseContext);

  if (!context) throw new Error('useLeadsDatabaseContext must be inside LeadsDatabaseProvider');

  return context;
};

export const LeadsDatabaseProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useLeadsDatabaseContextValue();

  return <LeadsDatabaseContext.Provider value={value}>{children}</LeadsDatabaseContext.Provider>;
};
