/* eslint-disable no-param-reassign */
import { Campaign, CampaignFiltersDto, CampFilterDto } from '@api/api';
import { InputObject, LabelValueObject, TransformedObject } from '@pages/CampaignDetails/types';

export const createUniqueKey = (obj: CampFilterDto) => `${obj.filter_name}|${obj.filter_val}`;

export const removeDuplicatesFromFiltersArray = (arr: CampFilterDto[] = []) =>
  arr.filter((obj, index, self) => {
    const uniqueKey = createUniqueKey(obj);

    return self.findIndex(item => createUniqueKey(item) === uniqueKey) === index;
  });

export const findPricedFilters = (
  campaignData: Campaign['camp_filters'] = [],
  filtersData: CampaignFiltersDto[] = [],
  removeDuplicates?: boolean,
) => {
  if (removeDuplicates) {
    return removeDuplicatesFromFiltersArray(
      campaignData?.filter(obj1 =>
        filtersData.some(obj2 => obj1.filter_name.toLowerCase() === obj2.key.toLowerCase() && obj2.price !== null),
      ) ?? [],
    );
  }
  return (
    campaignData?.filter(obj1 =>
      filtersData.some(obj2 => obj1.filter_name.toLowerCase() === obj2.key.toLowerCase() && obj2.price !== null),
    ) ?? []
  );
};

export const findFreeFilters = (campaignData: Campaign['camp_filters'] = [], filtersData: CampaignFiltersDto[] = []) =>
  campaignData?.filter(obj1 =>
    filtersData.some(obj2 => obj1.filter_name.toLowerCase() === obj2.key.toLowerCase() && obj2.price === null),
  ) ?? [];

export const extendArrayWithFreeFilters = (campaignData: Campaign, filtersData: CampaignFiltersDto[] = []) => {
  const freeFiltersMap = new Map();
  const freeFilters = findFreeFilters(campaignData?.camp_filters, filtersData);

  freeFilters.forEach(item => {
    if (!freeFiltersMap.has(item.scraper_id)) {
      freeFiltersMap.set(item.scraper_id, []);
    }
    freeFiltersMap.get(item.scraper_id).push({ filter_name: item.filter_name, filter_val: item.filter_val });
  });

  return campaignData?.campaign_scrapers.map(item => {
    const filters = freeFiltersMap.get(item.scraper_id) || [];
    return {
      ...item,
      free_filters: filters,
    };
  });
};

export const getSumOfPricedFilters = (allFilters: CampaignFiltersDto[] = [], pricedFiltersOnly: CampFilterDto[] = []) =>
  allFilters.reduce((total, nextFilters) => {
    const matchingItems = pricedFiltersOnly.filter(pricedObj => pricedObj.filter_name === nextFilters.key);

    const priceSum = matchingItems.reduce((sum, item) => {
      const matchingPrice = allFilters.find(filter => filter.key === item.filter_name)?.price || 0;
      return sum + matchingPrice;
    }, 0);

    return total + priceSum;
  }, 0);

export const calculateUsedTokens = ({ leads, basePrice, additionalPrice }: Record<string, number>) =>
  leads * (basePrice + additionalPrice);

export const transformQuery = (str: string): TransformedObject => {
  const parsed: InputObject = JSON.parse(str);

  return Object.keys(parsed).reduce<TransformedObject>((transformed, key) => {
    const value = parsed[key];

    if (Array.isArray(value) && value.every((item: any): item is LabelValueObject => item.label && item.value)) {
      transformed[key] = value.map((item: LabelValueObject) => item.value).join(', ');
    } else {
      transformed[key] = value;
    }

    return transformed;
  }, {});
};
