import { styled, Theme } from '@mui/material/styles';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)<DataGridProps & { theme?: Theme }>(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-cell': {
    padding: '10px 0',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '&.MuiDataGrid-root': {
    '--DataGrid-rowBorderColor': 'transparent',
    height: '100%',
  },
  '& .MuiDataGrid-footerContainer': {
    border: 'transparent',
  },
  '& .MuiDataGrid-row': {
    position: 'relative',
    borderRadius: '12px',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'block',
      border: `1px solid ${theme.colors.action.disabledBackground}`,
      pointerEvents: 'none',
      borderRadius: '12px',
      zIndex: 1,
    },
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
  '& .MuiDataGrid-cell--withRenderer': {
    border: 'transparent',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '&.MuiDataGrid-root .MuiDataGrid-withBorderColor': {
    border: 'transparent',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
}));
