import { Divider, DividerProps, Tabs, TabsProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled(Tabs)<TabsProps>(() => ({
  width: '100%',
}));

export const StyledDivider = styled(Divider)<DividerProps>({
  marginTop: '-1px',
});
