import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileDataFieldsNames } from '@pages/Settings/enums';
import { profileSchema } from '@pages/Settings/schema';
import { ProfileData } from '@pages/Settings/types';

const SettingsContext = createContext<ReturnType<typeof useSettingsContextValue> | null>(null);

const useSettingsContextValue = () => {
  const [editPassword, setEditPassword] = useState(false);

  const profileForm = useForm<ProfileData>({
    resolver: yupResolver(profileSchema) as Resolver<ProfileData>,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [ProfileDataFieldsNames.firstname]: '',
      [ProfileDataFieldsNames.lastname]: '',
      [ProfileDataFieldsNames.email]: '',
      [ProfileDataFieldsNames.phone]: '',
      [ProfileDataFieldsNames.oldPassword]: '',
      [ProfileDataFieldsNames.newPassword]: '',
      [ProfileDataFieldsNames.confirmNewPassword]: '',
    },
  });

  return {
    profileForm,
    editPassword,
    setEditPassword,
  };
};

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) throw new Error('useSettingsContext must be inside SettingsProvider');
  return context;
};

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useSettingsContextValue();
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
