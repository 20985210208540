import { FC, forwardRef, memo } from 'react';
import { getCountryCode } from '@utils/getCountryCode';
import { MuiTelInput } from 'mui-tel-input';

import { Box } from '@mui/material';

interface TextFieldPhoneProps {
  value?: string;
  onChange: (v: string) => void;
  label?: string;
  error?: boolean;
  helperText?: string;
  country?: string;
  disabled?: boolean;
}

const TextFieldPhone: FC<TextFieldPhoneProps> = forwardRef(
  ({ value = '', label = '', onChange, error, helperText, country, disabled, ...props }, ref) => (
    <Box width="100%" {...props}>
      <MuiTelInput
        fullWidth
        defaultCountry={getCountryCode()}
        label={label}
        value={value}
        onChange={onChange}
        variant="outlined"
        inputRef={ref}
        disabled={disabled}
        error={error}
        helperText={helperText}
      />
    </Box>
  ),
);

export default memo(TextFieldPhone);
