import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTabs } from '@hooks/common/useTabs';

import { Divider, Tab } from '@mui/material';

import { Indicators } from '../enums';

import { StyledTabs } from './styled';

const config = [
  { label: 'common.titles.profile', value: Indicators.PROFILE },
  { label: 'common.titles.billing', value: Indicators.BILLING },
  { label: 'common.titles.transactions', value: Indicators.TRANSACTIONS },
];

const dividerSx = {
  marginTop: '-9px',
};

const ToggleTabs: FC = () => {
  const { t } = useTranslation();
  const { tab, handleChange } = useTabs(Indicators.PROFILE, Indicators);

  return (
    <>
      <StyledTabs
        value={tab}
        onChange={handleChange}
        aria-label="settings tabs"
        textColor="primary"
        indicatorColor="primary"
      >
        {config.map(v => (
          <Tab value={v.value} label={t(v.label)} key={v.value} />
        ))}
      </StyledTabs>
      <Divider sx={dividerSx} />
    </>
  );
};

export default memo(ToggleTabs);
