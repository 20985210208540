import styled from '@mui/system/styled';

const Info = styled(props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10 20C12.7614 20 15.2614 18.8807 17.0711 17.0711C18.8807 15.2614 20 12.7614 20 10C20 7.2386 18.8807 4.7386 17.0711 2.92893C15.2614 1.11929 12.7614 0 10 0C7.2386 0 4.7386 1.11929 2.92893 2.92893C1.11929 4.7386 0 7.2386 0 10C0 12.7614 1.11929 15.2614 2.92893 17.0711C4.7386 18.8807 7.2386 20 10 20Z"
      fill="#2F88FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 16.5C10.6903 16.5 11.25 15.9403 11.25 15.25C11.25 14.5597 10.6903 14 10 14C9.30965 14 8.75 14.5597 8.75 15.25C8.75 15.9403 9.30965 16.5 10 16.5Z"
      fill="white"
    />
    <path d="M10 4V12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
))({});

export default Info;
