import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTING } from '@constants/routing';

export const useSteps = () => {
  const location = useLocation();
  const initialCompletedSteps = location.pathname.includes(ROUTING.EDIT_SCRAPER) ? [0, 1, 2, 3] : [];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>(initialCompletedSteps);
  const [mobileShowSettings, setMobileShowSettings] = useState<boolean>(true);

  const completeStep = useCallback((step: number) => {
    setCompletedSteps(prevState => {
      const newState = [...prevState];
      if (!prevState.includes(step)) {
        newState.push(step);
        return newState;
      }
      return prevState;
    });
  }, []);

  const inCompleteStep = useCallback((step: number) => {
    setCompletedSteps(prevState => prevState.filter(v => v !== step));
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    completeStep(activeStep);
    if (activeStep === 3) {
      setMobileShowSettings(false);
    }
  }, [activeStep, completeStep]);

  const handleBack = useCallback((numberOfSteps: number = 1) => {
    setActiveStep(prevActiveStep => prevActiveStep - numberOfSteps);
  }, []);

  return {
    activeStep,
    handleNext,
    handleBack,
    completedSteps,
    inCompleteStep,
    completeStep,
    mobileShowSettings,
    setMobileShowSettings,
  };
};
