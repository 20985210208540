import { skipForwardProp } from '@utils/styled';

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Chip,
  ChipProps,
  LinkProps,
  List,
  ListItem,
  ListItemProps,
  ListProps,
} from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { styled, Theme } from '@mui/material/styles';

export const StyledPopularChip = styled(Chip)<ChipProps>({
  backgroundColor: deepPurple[500],
  color: 'white',
});

export const StyledBlackButton = styled(Button)<ButtonProps & LinkProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: theme.colors.black,
  color: theme.colors.white,
  '&:hover': {
    backgroundColor: theme.colors.secondary.dark,
  },
}));

export const StyledColoredBox = styled(Box, { shouldForwardProp: skipForwardProp(['isMobile']) })<
  BoxProps & { isMobile?: boolean }
>(({ isMobile }) => ({
  borderTopLeftRadius: '12px',
  borderTopRightRadius: isMobile ? '12px' : '0px',
  borderBottomLeftRadius: isMobile ? '0px' : '12px',
  minWidth: '280px',
}));

export const StyledChip = styled(Chip)<ChipProps>({
  width: '170px',
});

export const StyledList = styled(List)<ListProps>({
  padding: '0',
});

export const StyledListItem = styled(ListItem)<ListItemProps>({
  padding: '4px 0',
});
