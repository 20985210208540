import { FC, memo } from 'react';
import { useLeadScraperContext } from '@pages/LeadScraper/context';

import ScraperFlow from './components/ScraperFlow';
import { StyledBox, StyledWrapper } from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const ScraperSettings: FC = () => {
  const { mobileShowSettings } = useLeadScraperContext();
  const { isLargerScreen } = useScreenSizeContext();

  if (!mobileShowSettings && !isLargerScreen) return null;

  return (
    <StyledBox py={3}>
      <StyledWrapper>
        <ScraperFlow />
      </StyledWrapper>
    </StyledBox>
  );
};

export default memo(ScraperSettings);
