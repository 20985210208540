import { LinkProps } from 'react-router-dom';

import { Dialog, DialogProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const mapSizes = {
  small: {
    '.MuiPaper-root': {
      maxWidth: '424px',
      width: '100%',
    },
  },
  medium: {
    '.MuiPaper-root': {
      maxWidth: '540px',
      width: '100%',
    },
  },
  xl: {
    '.MuiPaper-root': {
      maxWidth: '1080px',
      width: '100%',
    },
  },
};

export const StyledModal = styled(Dialog)<DialogProps & { size?: 'small' | 'medium' | 'xl' }>(({ size }) => ({
  ...(size && mapSizes[size]),
}));

export const StyledLink = styled(Typography)<TypographyProps & LinkProps>({
  textDecoration: 'none',
});
