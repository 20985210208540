import { MouseEvent, useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { createGoodsColumns } from '@hooks/common/useDynamicColumns/types/goodsColumns';
import { createJobsColumns } from '@hooks/common/useDynamicColumns/types/jobsColumns';
import { createPropertyColumns } from '@hooks/common/useDynamicColumns/types/propertyColumns';
import { AvailableCampaignTypes, AvailableResources, ScraperParams } from '@hooks/types';

import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { FullDetailsModalId } from '@components/FullDetailsModal';

const campaignColumnsHashMap: Record<AvailableResources, { columns: GridColDef[] }> = {
  indeed: { columns: createJobsColumns() },
  realtor: { columns: createPropertyColumns() },
  amazon: { columns: createGoodsColumns() },
  linkedin: { columns: createJobsColumns() },
  google: { columns: createJobsColumns() },
  zillow: { columns: createPropertyColumns() },
  renthop: { columns: createPropertyColumns() },
  realtyhop: { columns: createPropertyColumns() },
};

const campaignTypeColumnsHashMap: Record<AvailableCampaignTypes, { columns: GridColDef[] }> = {
  Jobs: { columns: createJobsColumns() },
  Goods: { columns: createGoodsColumns() },
  Properties: { columns: createPropertyColumns() },
};

const generateCampaignDetailsColumns = (resource: AvailableResources): GridColDef[] =>
  campaignColumnsHashMap[resource]?.columns || [];

const generateDbColumns = (type: AvailableCampaignTypes): GridColDef[] =>
  campaignTypeColumnsHashMap[type]?.columns || [];

export const useDynamicColumns = ({ source, campaignType }: { source?: string; campaignType?: string }) => {
  const campaignDetailsColumns: GridColDef[] = source
    ? generateCampaignDetailsColumns(source as AvailableResources)
    : [];

  const dbColumns: GridColDef[] = campaignType ? generateDbColumns(campaignType as AvailableCampaignTypes) : [];

  const onRowClick = useCallback((params: GridRowParams<ScraperParams>, event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const { row } = params;
    NiceModal.show(FullDetailsModalId, { row });
  }, []);

  return { campaignDetailsColumns, onRowClick, dbColumns };
};
