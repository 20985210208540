import { LinkProps } from 'react-router-dom';

import { Box, BoxProps, Button, ButtonProps } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import { styled } from '@mui/system';

export const StyledBox = styled(Box)<BoxProps & { theme?: Theme }>(({ theme }) => ({
  backgroundColor: blueGrey[900],
  width: '100%',
  color: theme.colors.white,
  padding: '10px',
}));

export const StyledButton = styled(Button)<ButtonProps & LinkProps & { theme?: Theme }>(({ theme }) => ({
  borderRadius: '12px',
  padding: '5px 10px',
  backgroundColor: theme.colors.white,
  color: theme.colors.text,
}));
