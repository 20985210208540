import { useTranslation } from 'react-i18next';
import { TransactionsDto } from '@api/api';
import { MINUS_SIGN, PLUS_SIGN } from '@constants/common';
import DownloadInvoiceButton from '@pages/Settings/components/TransactionsTab/components/DownloadInvoiceButton';
import PurposeName from '@pages/Settings/components/TransactionsTab/components/PurposeName';
import { OperatorType } from '@pages/Settings/types';
import { format, parseISO } from 'date-fns';

import { SvgIconComponent } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import FolderSharedRoundedIcon from '@mui/icons-material/FolderSharedRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import MoneyOffRoundedIcon from '@mui/icons-material/MoneyOffRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';

type TransactionMapType = {
  [key in TransactionsDto['transaction_type']]: {
    operator: OperatorType | string;
    typeIcon: SvgIconComponent;
    purposeIcon: SvgIconComponent;
    purposeLocale: string;
  };
};

const transactionMap: TransactionMapType = {
  campaign_start: {
    operator: MINUS_SIGN,
    typeIcon: PlayArrowRoundedIcon,
    purposeIcon: SearchIcon,
    purposeLocale: 'billing.transactions.campaignStartType',
  },
  campaign_refund: {
    operator: PLUS_SIGN,
    typeIcon: MoneyOffRoundedIcon,
    purposeIcon: SearchIcon,
    purposeLocale: 'billing.transactions.campaignRefundType',
  },
  subscription: {
    operator: PLUS_SIGN,
    typeIcon: AddRoundedIcon,
    purposeIcon: CheckCircleRoundedIcon,
    purposeLocale: 'billing.transactions.subscriptionType',
  },
  topup: {
    operator: PLUS_SIGN,
    typeIcon: AddRoundedIcon,
    purposeIcon: MonetizationOnRoundedIcon,
    purposeLocale: 'billing.transactions.topupType',
  },
  purchase_leads: {
    operator: '',
    typeIcon: MoneyOffRoundedIcon,
    purposeIcon: FolderSharedRoundedIcon,
    purposeLocale: 'billing.transactions.leadsDbType',
  },
  email_verification: {
    operator: '',
    typeIcon: MoneyOffRoundedIcon,
    purposeIcon: FolderSharedRoundedIcon,
    purposeLocale: 'billing.transactions.emailVerification',
  },
};

export const useTransactionsColumns = (): GridColDef[] => {
  const { t } = useTranslation();

  return [
    {
      field: 'purpose',
      headerName: 'Purpose',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams<TransactionsDto>) => {
        const PurposeIcon = transactionMap[row.transaction_type as TransactionsDto['transaction_type']].purposeIcon;
        const purpose = row.transaction_type;
        return (
          <>
            {row.campaign?.name && purpose.includes('campaign') && (
              <Box pl={2} display="flex" gap={1} alignItems="center" color="grey">
                <PurposeIcon />
                <Typography variant="subtitle2" textAlign="center" color="text.secondary">
                  {row.campaign.name}
                </Typography>
              </Box>
            )}
            {(purpose === 'topup' || purpose === 'purchase_leads' || purpose === 'subscription') && (
              <PurposeName row={row} purpose={purpose} icon={<PurposeIcon />} />
            )}
          </>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Transaction type',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      minWidth: 140,
      renderCell: ({ row }: GridCellParams<TransactionsDto>) => {
        const TransactionIcon = transactionMap[row.transaction_type as TransactionsDto['transaction_type']].typeIcon;
        const { purposeLocale } = transactionMap[row.transaction_type as TransactionsDto['transaction_type']];
        return (
          <Box pl={2} display="flex" gap={1} alignItems="center" color="grey">
            <TransactionIcon />
            <Typography variant="subtitle2" textAlign="center" color="text.secondary">
              {t(purposeLocale)}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'tokens',
      headerName: 'Tokens change',
      hideable: false,
      sortable: false,
      resizable: false,
      flex: 1,
      renderCell: ({ row }: GridCellParams<TransactionsDto>) => {
        const { operator } = transactionMap[row.transaction_type as TransactionsDto['transaction_type']];
        return (
          <Box pl={2}>
            <Typography variant="body2" component="span" color={operator === PLUS_SIGN ? 'green' : 'red'}>
              {` ${operator}${row.paid_tokens}`}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      hideable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams<TransactionsDto>) => (
        <Box pl={2} color="grey">
          <Typography variant="subtitle2" textAlign="center">
            {format(parseISO(row.created_at), 'dd MMMM yyyy HH:mm')}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'invoice',
      headerName: 'Invoice',
      width: 80,
      hideable: false,
      sortable: false,
      resizable: false,
      renderCell: ({ row }: GridCellParams<TransactionsDto>) => (
        <Box pl={2} color="grey">
          {row.invoice_id && <DownloadInvoiceButton row={row} />}
        </Box>
      ),
    },
  ];
};
