import { useTranslation } from 'react-i18next';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { StyledModal } from '@pages/Settings/components/styled';

import { Box, Button, Typography } from '@mui/material';

const ConfirmModal = NiceModal.create(() => {
  const modal = useModal();
  const { t } = useTranslation();

  const handleConfirmClick = () => {
    modal.resolve(true);
    modal.remove();
  };
  const handleCancelClick = async () => {
    modal.resolve(false);
    modal.remove();
  };

  return (
    <StyledModal open={modal.visible} size="small">
      <Box padding="24px 48px">
        <Typography variant="h6" marginBottom="24px" textAlign="center">
          {t('profile.logoutAllModalTitle')}
        </Typography>
        <Box display="flex" justifyContent="space-between" gap="12px">
          <Button fullWidth color="secondary" onClick={handleCancelClick}>
            {t('common.cancel')}
          </Button>
          <Button fullWidth variant="outlined" color="primary" onClick={handleConfirmClick}>
            {t('common.confirm')}
          </Button>
        </Box>
      </Box>
    </StyledModal>
  );
});

export const LogoutAllConfirmationModalId = 'LogoutAllConfirmationModal';

NiceModal.register(LogoutAllConfirmationModalId, ConfirmModal);
