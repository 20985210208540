import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { SearchFilter } from '@/shared/SearchFilters/types';

const CheckboxSearchFilter: FC<SearchFilter> = ({ name, label, onChange }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Box px={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!field.value}
                onChange={event => {
                  field.onChange(event.target.checked);
                  onChange();
                }}
              />
            }
            label={label}
            componentsProps={{ typography: { fontSize: '14px', fontWeight: 600 } }}
          />
        </Box>
      )}
    />
  );
};

export default memo(CheckboxSearchFilter);
