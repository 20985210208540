import { FC, memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SLIDER_MAX_VAL, SLIDER_MIN_VAL } from '@constants/common';

import { Box, Slider, Stack, Typography } from '@mui/material';

import { StyledTextField } from '@/shared/SearchFilters/styled';
import { SearchFilter } from '@/shared/SearchFilters/types';

const SliderSearchFilter: FC<SearchFilter> = ({
  name,
  maxValue = SLIDER_MAX_VAL,
  minValue = SLIDER_MIN_VAL,
  label,
  onChange,
}) => {
  const { control, setValue, watch, register } = useFormContext();
  const minField = `data_filter_+gte+${name}`;
  const maxField = `data_filter_+lte+${name}`;

  const sliderMin = watch(minField);
  const sliderMax = watch(maxField);

  const handleRangeChange = (_: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue(minField, newValue[0]);
    } else {
      setValue(maxField, newValue[1]);
    }
    onChange();
  };

  useEffect(() => {
    if (!sliderMin) {
      setValue(minField, Number(minValue));
    }
    if (!sliderMax) {
      setValue(maxField, Number(maxValue));
    }
  }, []);

  return (
    <>
      <Box>
        <Typography variant="subtitle2">{label}</Typography>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Slider
              {...field}
              disableSwap
              getAriaLabel={() => 'Price range'}
              value={[sliderMin, sliderMax]}
              onChange={handleRangeChange}
              valueLabelDisplay="auto"
              min={minValue}
              max={maxValue}
              sx={{ marginLeft: '25px', width: '80%' }}
            />
          )}
        />
        <Stack direction="row" justifyContent="space-between" gap={2} alignItems="center">
          <StyledTextField
            {...register(minField)}
            label="min"
            type="number"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: sliderMin } }}
            value={sliderMin}
            onChange={e => {
              setValue(minField, Number(e.target.value));
              onChange();
              return Number(e.target.value);
            }}
          />
          <StyledTextField
            {...register(maxField)}
            label="max"
            type="number"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { max: sliderMax } }}
            value={sliderMax}
            onChange={e => {
              setValue(maxField, Number(e.target.value));
              onChange();
              return Number(e.target.value);
            }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default memo(SliderSearchFilter);
