import { FC, memo, MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CampaignTypesDto,
  useGetLeadsDatabaseQuery,
  useGetMyLeadsQuery,
  useGetPurchasedLeadsQuery,
  usePurchaseAllLeadsMutation,
  usePurchaseLeadsMutation,
} from '@api/api';
import { ERROR, SUCCESS, WARNING } from '@constants/auth';
import { API_ERROR_MSG_PATH } from '@constants/common';
import NiceModal from '@ebay/nice-modal-react';
import { useCampaignTypes } from '@hooks/api/useCampaignTypes';
import { useCustomFiltersQueryParam } from '@hooks/common/useDynamicFilters/useCustomFiltersQueryParam';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';
import { transformQuery } from '@pages/CampaignDetails/utils';
import ScrapeTypesList from '@pages/LeadsDatabase/components/ScrapeTypesList';
import { useCampaignTypeQueryParams } from '@pages/LeadsDatabase/hooks/useCampaignTypeQueryParams';
import { getErrorMessage } from '@utils/getMessage';
import { useSnackbar } from 'notistack';
import qs from 'qs';

import { GridRowSelectionModel } from '@mui/x-data-grid';
import { ScrapekingLeadsDbTable } from '@components/LeadsTable';
import { ExtendedPaginatedLeadsData } from '@components/LeadsTable/types';
import { PurchaseLeadsConfirmModalId } from '@components/PurchaseLeadsConfirmModal';

const ScrapekingDb: FC = () => {
  const { page, limit } = usePageFilterUrlParams();
  const { dynamicParam } = useCustomFiltersQueryParam();
  const { campaignTypeId, handleChangeCampaignType } = useCampaignTypeQueryParams();
  const { data: campaignTypesData } = useCampaignTypes();
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const handleChange = (_: MouseEvent<HTMLElement>, newAlignment: CampaignTypesDto) => {
    handleChangeCampaignType(newAlignment);
  };

  const serializedFilters =
    dynamicParam && encodeURIComponent(qs.stringify(transformQuery(dynamicParam), { encode: false }));

  const {
    data: leadsDbData,
    isLoading: isLoadingLeads,
    isFetching: isFetchingLeads,
    refetch: refetchDbLeads,
    error: leadsDbError,
  } = useGetLeadsDatabaseQuery(
    {
      page,
      limit,
      ...(campaignTypeId && { campaignType: +campaignTypeId }),
      ...(dynamicParam && { filters: serializedFilters }),
    },
    { refetchOnMountOrArgChange: true, skip: !campaignTypeId },
  );

  const {
    data: myLeadsData,
    isLoading: isLoadingMyLeads,
    isFetching: isFetchingMyLeads,
    error: myLeadsError,
  } = useGetMyLeadsQuery(
    {
      page: 0,
      limit: 25,
      ...(campaignTypeId && { campaignType: +campaignTypeId }),
      ...(dynamicParam && { filters: serializedFilters }),
    },
    { skip: !campaignTypeId },
  );

  const { data: totalPurchasedData, isLoading: isLoadingTotalPurchased } = useGetPurchasedLeadsQuery(
    {
      campaignType: +campaignTypeId!,
    },
    { refetchOnMountOrArgChange: true, skip: !campaignTypeId },
  );

  const [purchaseLeadsMutation, { isLoading: isLoadingPurchaseMutation, error: purchaseError }] =
    usePurchaseLeadsMutation();
  const [purchaseAllLeadsMutation, { isLoading: isLoadingPurchaseAllMutation, error: purchaseAllError }] =
    usePurchaseAllLeadsMutation();

  const handlePurchase = async (ids: GridRowSelectionModel) => {
    try {
      const result = await NiceModal.show(PurchaseLeadsConfirmModalId, {
        totalPrice: ids.length,
        totalOwned: myLeadsData && myLeadsData.total,
      });

      if (result) {
        await purchaseLeadsMutation({
          purchaseLeads: {
            id: [...(ids as number[])],
          },
        });
        snackbar.enqueueSnackbar(t('dashboard.tokens.tokensUsed', { totalPrice: ids.length }), {
          variant: WARNING,
        });

        if (purchaseError) {
          return snackbar.enqueueSnackbar(getErrorMessage(purchaseError, API_ERROR_MSG_PATH), { variant: ERROR });
        }

        snackbar.enqueueSnackbar(t('dashboard.db.leadsPurchased'), { variant: SUCCESS });
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const handlePurchaseAll = async () => {
    try {
      const totalPrice =
        leadsDbData && totalPurchasedData ? leadsDbData.total - totalPurchasedData.totalPurchasedLeads : 0;
      const result = await NiceModal.show(PurchaseLeadsConfirmModalId, {
        totalPrice,
        totalOwned: myLeadsData && myLeadsData.total,
      });

      if (result) {
        await purchaseAllLeadsMutation({
          purchaseAllLeadsDto: {
            ...(campaignTypeId && { campaignType: +campaignTypeId }),
            ...(dynamicParam.length > 0 && { filters: serializedFilters }),
          },
        });
        snackbar.enqueueSnackbar(t('dashboard.tokens.tokensUsed', { totalPrice }), {
          variant: WARNING,
        });

        if (purchaseAllError) {
          return snackbar.enqueueSnackbar(getErrorMessage(purchaseAllError, API_ERROR_MSG_PATH), { variant: ERROR });
        }

        snackbar.enqueueSnackbar(t('dashboard.db.leadsPurchased'), { variant: SUCCESS });
      }
    } catch (err) {
      snackbar.enqueueSnackbar(getErrorMessage(err, API_ERROR_MSG_PATH), { variant: ERROR });
    }
  };

  const isLoading = [
    isLoadingLeads,
    isFetchingLeads,
    isLoadingTotalPurchased,
    isLoadingMyLeads,
    isFetchingMyLeads,
  ].some(Boolean);
  const isLoadingPurchase = [isLoadingPurchaseMutation, isLoadingPurchaseAllMutation].some(Boolean);

  const errorArray = [leadsDbError, myLeadsError];

  if (errorArray.some(Boolean)) {
    const error = errorArray.filter(err => err);
    snackbar.enqueueSnackbar(getErrorMessage(error, API_ERROR_MSG_PATH), { variant: ERROR });
  }

  useEffect(() => {
    if (campaignTypesData) {
      handleChangeCampaignType(campaignTypesData[0]);
    }
  }, []);

  return (
    <>
      <ScrapeTypesList handleChange={handleChange} active={campaignTypeId as string} data={campaignTypesData} />
      <ScrapekingLeadsDbTable
        totalLeadsFound={leadsDbData?.total}
        leadsData={leadsDbData as ExtendedPaginatedLeadsData}
        isLoadingLeads={isLoading}
        refetch={refetchDbLeads}
        campaignType={campaignTypesData?.find(type => type.campaign_type_id === +(campaignTypeId ?? 1))?.type_name}
        isLoadingPurchaseMutation={isLoadingPurchase}
        handlePurchase={handlePurchase}
        handlePurchaseAll={handlePurchaseAll}
        totalPurchasedLeads={totalPurchasedData?.totalPurchasedLeads}
      />
    </>
  );
};

export default memo(ScrapekingDb);
