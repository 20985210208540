import { useSearchParams } from 'react-router-dom';

export const useScraperFiltersUrlParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = +(searchParams.get('type') || '-1');
  const status = +(searchParams.get('status') || '-1');

  return {
    type,
    status,
    setType: (value?: number) => {
      value !== -1 ? searchParams.set('type', String(value)) : searchParams.delete('type');
      setSearchParams(searchParams);
    },
    setStatus: (value?: number) => {
      value !== -1 ? searchParams.set('status', String(value)) : searchParams.delete('status');
      setSearchParams(searchParams);
    },
  };
};
