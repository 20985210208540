import { FC, memo } from 'react';
import { CampFilterDto } from '@api/api';
import { capitalize } from '@utils/formatInitials';

import { Autocomplete, SxProps, TextField } from '@mui/material';
import AutocompletePaper from '@components/AutocompletePaper/AutocompletePaper';

const ScraperFilter: FC<{
  filter?: CampFilterDto;
  autocompleteSx?: SxProps;
}> = ({ autocompleteSx, filter }) => (
  <Autocomplete
    disablePortal
    disableClearable
    disabled
    options={[]}
    value={filter?.filter_val}
    sx={autocompleteSx}
    PaperComponent={AutocompletePaper}
    renderInput={params => <TextField variant="outlined" label={capitalize(filter?.filter_name)} {...params} />}
  />
);

export default memo(ScraperFilter);
