import { FC, memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ScrapersDto } from '@api/api';
import { TOKENS } from '@constants/common';
import { SOURCE_INFO } from '@constants/formFields';
import { capitalize } from '@utils/formatInitials';

import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import AutocompletePaper from '@components/AutocompletePaper/AutocompletePaper';

const ScraperSettingsAutocomplete: FC<{
  options: ScrapersDto[];
  isLoading: boolean;
  autocompleteFields: any;
}> = ({ options, isLoading, autocompleteFields }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      rules={{
        required: true,
      }}
      name={SOURCE_INFO.AUTOCOMPLETE}
      render={({ field: { onChange, ...field }, fieldState }) => (
        <Autocomplete
          {...field}
          multiple
          disablePortal
          getOptionKey={option => option.scraper_id}
          disabled={isLoading}
          getOptionLabel={option => capitalize(option.scraper_name)}
          onChange={(_, value) => {
            onChange(value.map((v, i) => ({ ...v, filters: autocompleteFields?.[i]?.filters ?? {} })));
          }}
          PaperComponent={AutocompletePaper}
          options={options || []}
          filterOptions={(currentOptions: ScrapersDto[], state) =>
            currentOptions
              .filter(v => !field?.value?.map((f: ScrapersDto) => f.scrapers_id).includes(v.scrapers_id))
              .filter(v =>
                state.inputValue.trim()
                  ? v.scraper_name.toLowerCase().includes(state.inputValue.trim().toLowerCase())
                  : true,
              )
          }
          renderOption={(props, option) => (
            <li {...props}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body2">{capitalize(option.scraper_name)}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {option.price} {TOKENS} / {t('dashboard.newScraper.leadLabel')}
                </Typography>
              </Box>
            </li>
          )}
          fullWidth
          renderInput={params => (
            <TextField
              label={t('dashboard.newScraper.settings.sourceLabel')}
              placeholder={t('dashboard.newScraper.settings.sourcePlaceholder', { num: options.length })}
              error={Boolean(fieldState.error?.type)}
              variant="outlined"
              helperText={
                fieldState.error?.type &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              {...params}
            />
          )}
        />
      )}
    />
  );
};

export default memo(ScraperSettingsAutocomplete);
