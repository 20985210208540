import { CampaignFiltersDto } from '@api/api';
import { ScraperData } from '@pages/LeadScraper/types';

export const calculateTotalPrice = (data?: ScraperData[], paidFiltersPrice?: number) => {
  if (!data) return 0;
  return (
    data.reduce(
      (totalPrice, item) =>
        totalPrice + item.price * (item.filters.numberOfLeads ? Number(item.filters.numberOfLeads) : 0),
      0,
    ) + (paidFiltersPrice || 0)
  );
};

export const calculateTotalLeads = (data: ScraperData[]) => {
  if (!data) return 0;
  return data.reduce((totalLeads, item) => totalLeads + Number(item.filters.numberOfLeads), 0);
};

export const calculatePerLeadPrice = (data: ScraperData) => {
  if (data) {
    return data.price * Number(data.filters.numberOfLeads);
  }
  return 0;
};

export const calcPaidFiltersPrice = (initialArray: CampaignFiltersDto[], selectedKeys?: string[]) => {
  if (!selectedKeys || selectedKeys.length === 0) {
    return 0;
  }

  return selectedKeys.reduce((total, key) => {
    const matchingObject = initialArray.find(obj => obj.key === key);
    if (matchingObject && matchingObject.price !== null) {
      return total + matchingObject.price;
    }

    return total;
  }, 0);
};
