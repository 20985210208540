import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignFiltersDto, useVerifyEmailsMutation } from '@api/api';
import useDownloadCsv from '@hooks/common/useDownloadCsv';
import { useHeight } from '@hooks/common/useHeight';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';
import { useSourceNameQueryParams } from '@pages/CampaignDetails/hooks/useSourceNameQueryParams';
import { findFreeFilters, findPricedFilters } from '@pages/CampaignDetails/utils';
import { useSnackbar } from 'notistack';
import { ScraperFilter } from 'src/components/Filter';

import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Button, CircularProgress, Collapse, Typography } from '@mui/material';
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid';
import CustomFilters from '@components/CustomFilters';
import { CampaignDetailsDynamicDataGrid } from '@components/DynamicDataGrid';
import { CampaignDetailsLeadsTableProps } from '@components/LeadsTable/types';
import LoadingButton from '@components/LoadingButton';
import LoadingIconButton from '@components/LoadingIconButton';

import MobileCampaignLeadsTable from './mobile/MobileCampaignLeadsTable';
import {
  StyledIconButton,
  StyledLeadsBox,
  StyledSmartHeightDataGridContainer,
  StyledSmartHeightFiltersContainer,
} from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';
import { useProtectedRoutesContext } from '@/shared/ProtectedRoutesContext';

const CampaignLeadsTable: FC<CampaignDetailsLeadsTableProps> = ({
  totalLeadsFound,
  campaign,
  leadsData,
  autocompleteSx,
  isLoadingLeads,
  totalLeadsRequested,
  filtersData,
  refetch,
  requestedLeads = true,
  showSelected,
  serializedFilters,
}) => {
  const [selectedLeadIds, setSelectedLeadIds] = useState<never[] | GridRowSelectionModel>([]);
  const { t } = useTranslation();
  const { setPage, setLimit, page, limit } = usePageFilterUrlParams();
  const { sourceName } = useSourceNameQueryParams();
  const { expandedFilters, toggleFilters } = useProtectedRoutesContext();
  const { isLargerScreen } = useScreenSizeContext();
  const { headerAppHeight } = useHeight();
  const { downloadCsv, isLoading: isLoadingCsv } = useDownloadCsv();
  const snackbar = useSnackbar();

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  const handleSelectionChange = (selectionModel: GridRowSelectionModel) => {
    setSelectedLeadIds([...selectionModel]);
  };

  const handleDownloadCsv = async () => {
    await downloadCsv({
      scraperId: leadsData?.data?.[0].scraper_id as number,
      campaignId: String(campaign?.campaign_id),
      selectedLeadIds: selectedLeadIds.length > 0 ? selectedLeadIds.map(item => +item) : [],
      ...(serializedFilters && { filters: serializedFilters }),
    });

    setSelectedLeadIds([]);
  };

  const selectedSourceScraperId = campaign?.campaign_scrapers.filter(
    scraper => scraper.scrapers.scraper_name.trim() === sourceName,
  )[0]?.scraper_id;

  const pricedFilters = findPricedFilters(campaign?.camp_filters, filtersData as CampaignFiltersDto[]).filter(
    f => f?.scraper_id === selectedSourceScraperId,
  );
  const freeFilters = findFreeFilters(campaign?.camp_filters, filtersData as CampaignFiltersDto[]).filter(
    f => f?.scraper_id === selectedSourceScraperId,
  );

  const [verifyEmailsMutation, { isLoading: emailVerificationIsInProgress }] = useVerifyEmailsMutation();

  const jobsSource = ['indeed', 'google', 'linkedin'].some(job => sourceName && job === sourceName.toLowerCase());

  if (!isLargerScreen) {
    return (
      <MobileCampaignLeadsTable
        pricedFilters={pricedFilters}
        freeFilters={freeFilters}
        requestedLeads={requestedLeads}
        totalLeadsRequested={totalLeadsRequested}
        totalLeadsFound={totalLeadsFound}
        handlePaginationModelChange={handlePaginationModelChange}
        refetch={refetch}
        showSelected={showSelected}
        leadsData={leadsData}
        campaign={campaign}
        totalLeadsSelected={selectedLeadIds.length}
        isLoadingLeads={isLoadingLeads}
        selectedLeadIds={selectedLeadIds}
        handleCheckboxSelection={handleSelectionChange}
        handleDownloadCsv={handleDownloadCsv}
        isLoadingCsv={isLoadingCsv}
      />
    );
  }

  return (
    <>
      <Box width="100%" display="flex">
        <Box display="flex" alignItems="center" width="100%">
          <Box display="flex" justifyContent="space-between" width="250px">
            <Box>
              <Typography variant="subtitle2" display="inline" mr={1}>
                {t('common.filtersTitle')}
              </Typography>
            </Box>

            <StyledIconButton onClick={toggleFilters} disableRipple>
              {expandedFilters ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </StyledIconButton>
          </Box>

          <StyledLeadsBox display="flex" justifyContent="space-between" maxHeight="72px">
            <Box pl={3} my={3}>
              {requestedLeads && (
                <>
                  <Typography variant="subtitle2" mr={1} display="inline">
                    {totalLeadsRequested || 0}
                  </Typography>
                  <Typography variant="body2" display="inline">
                    {t('dashboard.campaignDetails.leadsRequested')}
                  </Typography>
                  {' / '}
                </>
              )}
              <Typography variant="subtitle2" mr={1} display="inline">
                {totalLeadsFound || 0}
              </Typography>
              <Typography variant="body2" display="inline">
                {t('dashboard.campaignDetails.leadsFound')}
              </Typography>
              {showSelected && selectedLeadIds.length > 0 && (
                <>
                  <Typography variant="body2" display="inline" ml={1}>
                    {t('dashboard.leads.selected')}
                  </Typography>
                  <Typography variant="subtitle2" display="inline" ml={1}>
                    {selectedLeadIds.length}
                  </Typography>
                </>
              )}
            </Box>

            <Box display="flex" gap={3} my={2}>
              {jobsSource && (
                <LoadingButton
                  disableRipple
                  variant="contained"
                  color="primary"
                  size="medium"
                  loading={emailVerificationIsInProgress}
                  disabled={selectedLeadIds.length < 1 || emailVerificationIsInProgress}
                  onClick={async () => {
                    const emailToVerify = leadsData?.data
                      // @ts-ignore
                      .filter(d => selectedLeadIds.includes(d.leads_id))
                      .map(lead => {
                        // @ts-ignore
                        const emailString = lead.data.email;
                        const emailParts = emailString.split(',');
                        return emailParts[0].trim() ?? '';
                      })
                      .filter(Boolean);

                    try {
                      await verifyEmailsMutation({
                        verifyEmailDto: {
                          emails: emailToVerify as string[],
                        },
                      }).unwrap();
                    } catch (e: unknown) {
                      // @ts-ignore
                      if (e?.data?.error) {
                        // @ts-ignore
                        snackbar.enqueueSnackbar(e?.data?.message?.join() ?? 'Error');
                      }
                    }
                  }}
                  loadingIndicator={<CircularProgress color="inherit" size={22} />}
                  sx={{ flexBasis: 'calc(50% - 8px)' }}
                >
                  <Typography variant="subtitle2" minWidth="140px">
                    {t('dashboard.db.verifyEmail')}
                  </Typography>
                </LoadingButton>
              )}
              <Button disableRipple variant="contained" color="primary" size="medium" startIcon={<AddIcon />}>
                <Typography variant="subtitle2" minWidth="140px">
                  {t('dashboard.campaignDetails.addToCampaign')}
                </Typography>
              </Button>

              <LoadingIconButton
                loaderSize="1.5rem"
                onClick={handleDownloadCsv}
                loading={isLoadingCsv}
                loaderColor="secondary"
                size="medium"
                color="primary"
                disabled={!totalLeadsFound}
              >
                <DownloadIcon />
              </LoadingIconButton>
            </Box>
          </StyledLeadsBox>
        </Box>
      </Box>

      <Box display="flex" height="100%">
        {expandedFilters && (
          <Collapse in={expandedFilters}>
            <StyledSmartHeightFiltersContainer
              display="flex"
              flexDirection="column"
              gap={1}
              pr={2}
              width="250px"
              headerAppHeight={headerAppHeight}
            >
              {freeFilters.length ? (
                <Box>
                  <Typography variant="subtitle2" mb={1}>
                    {t('dashboard.freeFilters')}
                  </Typography>
                  {freeFilters.map((filter, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ScraperFilter key={index} filter={filter} autocompleteSx={autocompleteSx} />
                  ))}
                </Box>
              ) : null}
              {pricedFilters.length ? (
                <Box>
                  <Typography variant="subtitle2" mb={1}>
                    {t('dashboard.paidFilters')}
                  </Typography>
                  {pricedFilters.map((filter, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ScraperFilter key={index} filter={filter} autocompleteSx={autocompleteSx} />
                  ))}
                </Box>
              ) : null}
              <CustomFilters campaignType={campaign?.campaign_type?.type_name} refetch={refetch} />
            </StyledSmartHeightFiltersContainer>
          </Collapse>
        )}
        <StyledSmartHeightDataGridContainer expanded={expandedFilters} headerAppHeight={headerAppHeight}>
          <CampaignDetailsDynamicDataGrid
            data={leadsData}
            handlePageChange={handlePaginationModelChange}
            paginationModel={{ page, pageSize: limit }}
            isDataLoading={isLoadingLeads}
            sourceName={sourceName as string}
            expandedFilters={expandedFilters}
            selectedLeadIds={selectedLeadIds}
            handleCheckboxSelection={handleSelectionChange}
          />
        </StyledSmartHeightDataGridContainer>
      </Box>
    </>
  );
};

export default memo(CampaignLeadsTable);
