import { ChangeEvent, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Switch, Typography } from '@mui/material';
import { StyledGreyBox } from '@components/ScheduleToggler/styled';

interface ScheduleToggleProps {
  onChange: () => void;
}

const ScheduleToggler: FC<ScheduleToggleProps> = ({ onChange }) => {
  const { t } = useTranslation();

  return (
    <StyledGreyBox display="flex" alignItems="center" p={1} gap={1} minWidth="176px">
      <Switch
        size="small"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange();
          return e.target.value;
        }}
      />
      <Typography variant="body2">{t('dashboard.scheduledSwitchLabel')}</Typography>
    </StyledGreyBox>
  );
};

export default memo(ScheduleToggler);
