import { FC, memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MAX_DAYS_IN_MONTH } from '@constants/common';
import { generateMonthlyOptions } from '@pages/LeadScraper/components/ScraperSettings/components/ScraperFlow/utils/dataToOptions';
import { formatDate } from '@utils/formatTime';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import { Autocomplete, Box, TextField } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

import { StyledTextField } from '../styled';

const SelectDayAndTime: FC = () => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const isWeekly = watch('repeat.weekly');
  const isMonthly = watch('repeat.monthly');

  useEffect(() => {
    if (isMonthly) {
      setValue('dayOfTheMonth', generateMonthlyOptions(MAX_DAYS_IN_MONTH)[0].value);
    }
    if (isWeekly) {
      setValue('numberOfWeeks', 1);
    }
  }, []);

  return (
    <>
      {isWeekly && (
        <Controller
          control={control}
          name="numberOfWeeks"
          rules={{ required: isWeekly }}
          render={({ field, fieldState }) => (
            <StyledTextField
              {...field}
              type="number"
              defaultValue={1}
              variant="outlined"
              size="medium"
              label={t('dashboard.newScraper.scheduling.numberOfWeeks')}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              inputProps={{ maxLength: 10 }}
              onKeyDown={e => {
                if (
                  e.key === 'e' ||
                  e.key === 'E' ||
                  e.key === '-' ||
                  e.key === '+' ||
                  e.key === '.' ||
                  e.key === ','
                ) {
                  e.preventDefault();
                }
              }}
              onWheel={e => (e.target as HTMLElement).blur()}
            />
          )}
        />
      )}
      {isMonthly && (
        <Controller
          control={control}
          name="numberOfMonths"
          rules={{ required: isMonthly }}
          render={({ field, fieldState }) => (
            <StyledTextField
              {...field}
              type="number"
              defaultValue={1}
              variant="outlined"
              size="medium"
              label={t('dashboard.newScraper.scheduling.numberOfMonths')}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error &&
                t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message })
              }
              inputProps={{ maxLength: 10 }}
              onKeyDown={e => {
                if (
                  e.key === 'e' ||
                  e.key === 'E' ||
                  e.key === '-' ||
                  e.key === '+' ||
                  e.key === '.' ||
                  e.key === ','
                ) {
                  e.preventDefault();
                }
              }}
              onWheel={e => (e.target as HTMLElement).blur()}
            />
          )}
        />
      )}
      <Box display="flex" gap={2}>
        {!isMonthly && (
          <Controller
            control={control}
            name="startDate"
            rules={{
              required: isWeekly,
            }}
            render={({ field, fieldState }) => (
              <DatePicker
                {...field}
                sx={{ flex: 1 }}
                disablePast
                label={t('dashboard.newScraper.scheduling.startDate')}
                value={field.value || startDate}
                onChange={newValue => {
                  field.onChange(newValue);
                  setStartDate(newValue);
                }}
                slots={{
                  openPickerIcon: CalendarTodayIcon,
                }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    error: Boolean(fieldState.error),
                    helperText:
                      fieldState.error &&
                      t(`common.errors.${fieldState.error.type}`, { defaultValue: fieldState.error.message }),
                    inputProps: {
                      value: formatDate(field.value) || formatDate(startDate),
                    },
                  },
                }}
              />
            )}
          />
        )}

        {isMonthly && (
          <Controller
            control={control}
            name="dayOfTheMonth"
            rules={{
              required: isMonthly,
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                sx={{ flex: 1 }}
                disableClearable
                defaultValue={generateMonthlyOptions(MAX_DAYS_IN_MONTH)[0].value}
                disablePortal
                value={field.value}
                onChange={(_, value) => {
                  field.onChange(value.value);
                }}
                options={generateMonthlyOptions(MAX_DAYS_IN_MONTH)}
                renderInput={params => (
                  <TextField {...params} variant="outlined" label={t('dashboard.newScraper.scheduling.dateOfMonth')} />
                )}
              />
            )}
          />
        )}

        <Controller
          control={control}
          name="startTime"
          render={({ field }) => (
            <TimePicker
              {...field}
              disablePast
              sx={{ flex: 1, height: '56px' }}
              label={t('dashboard.newScraper.scheduling.startTime')}
              slots={{
                openPickerIcon: WatchLaterRoundedIcon,
              }}
              slotProps={{
                textField: {
                  variant: 'outlined',
                },
              }}
            />
          )}
        />
      </Box>
    </>
  );
};

export default memo(SelectDayAndTime);
