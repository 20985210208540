import { memo } from 'react';
import { TransactionsDto, useGetInvoiceDetailsQuery } from '@api/api';

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Box } from '@mui/material';
import LoadingIconButton from '@components/LoadingIconButton';

const DownloadInvoiceButton = ({ row }: { row: TransactionsDto }) => {
  const { data: invoiceData, isLoading: isLoadingInvoice } = useGetInvoiceDetailsQuery(
    { id: row.invoice_id },
    { skip: !row.invoice_id },
  );

  return (
    <Box color="grey">
      {row.invoice_id && (
        <LoadingIconButton
          loaderSize="2rem"
          loading={isLoadingInvoice}
          loaderColor="secondary"
          component="a"
          // @ts-ignore
          href={invoiceData?.hosted_invoice_url}
          target="_blank"
        >
          <DownloadRoundedIcon />
        </LoadingIconButton>
      )}
    </Box>
  );
};

export default memo(DownloadInvoiceButton);
