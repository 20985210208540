import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDownloadCsv from '@hooks/common/useDownloadCsv';
import { useHeight } from '@hooks/common/useHeight';
import { usePageFilterUrlParams } from '@hooks/common/usePageFilterUrlParams';

import DownloadIcon from '@mui/icons-material/Download';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid';
import CustomFilters from '@components/CustomFilters';
import { ScrapekingDbDynamicDataGrid } from '@components/DynamicDataGrid';
import MobileMyLeadsTable from '@components/LeadsTable/mobile/MobileMyLeadsTable';
import { LeadsDbTableProps } from '@components/LeadsTable/types';
import LoadingIconButton from '@components/LoadingIconButton';

import {
  StyledIconButton,
  StyledLeadsBox,
  StyledSmartHeightDataGridContainer,
  StyledSmartHeightFiltersContainer,
} from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';
import { useProtectedRoutesContext } from '@/shared/ProtectedRoutesContext';

const MyLeadsTable: FC<LeadsDbTableProps> = ({
  totalLeadsFound,
  leadsData,
  isLoadingLeads,
  refetch,
  campaignType,
  campaignTypeId,
  serializedFilters,
}) => {
  const [selectedLeadIds, setSelectedLeadIds] = useState<never[] | GridRowSelectionModel>([]);
  const { t } = useTranslation();
  const { setPage, setLimit, page, limit } = usePageFilterUrlParams();
  const { expandedFilters, toggleFilters } = useProtectedRoutesContext();
  const { isLargerScreen } = useScreenSizeContext();
  const { headerAppHeight } = useHeight();
  const { downloadCsv, isLoading: isLoadingCsv } = useDownloadCsv();

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    setPage(newPaginationModel.page);
    setLimit(newPaginationModel.pageSize);
  };

  const handleSelectionChange = (selectionModel: GridRowSelectionModel) => {
    setSelectedLeadIds([...selectionModel]);
  };

  const handleDownloadCsv = async () => {
    await downloadCsv({
      scraperId: leadsData?.data?.[0].scraper_id as number,
      campaignId: '',
      selectedLeadIds: selectedLeadIds.length > 0 ? selectedLeadIds.map(item => +item) : [],
      ...(campaignTypeId && { campaignType: +campaignTypeId }),
      ...(serializedFilters && { filters: serializedFilters }),
    });

    setSelectedLeadIds([]);
  };

  if (!isLargerScreen) {
    return (
      <MobileMyLeadsTable
        totalLeadsFound={totalLeadsFound}
        handlePaginationModelChange={handlePaginationModelChange}
        refetch={refetch}
        leadsData={leadsData}
        campaignType={campaignType}
        totalLeadsSelected={selectedLeadIds.length}
        isLoadingLeads={isLoadingLeads}
        handleSelectionChange={handleSelectionChange}
        isLoadingCsv={isLoadingCsv}
        handleDownloadCsv={handleDownloadCsv}
      />
    );
  }

  return (
    <>
      <Box width="100%" display="flex">
        <Box display="flex" alignItems="center" width="100%">
          <Box display="flex" justifyContent="space-between" width="250px">
            <Box>
              <Typography variant="subtitle2" display="inline" mr={1}>
                {t('common.filtersTitle')}
              </Typography>
            </Box>

            <StyledIconButton onClick={toggleFilters} disableRipple>
              {expandedFilters ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </StyledIconButton>
          </Box>

          <StyledLeadsBox display="flex" justifyContent="space-between" maxHeight="72px">
            <Box pl={3} my={3}>
              <Typography variant="subtitle2" mr={1} display="inline">
                {totalLeadsFound || 0}
              </Typography>
              <Typography variant="body2" display="inline">
                {t('dashboard.campaignDetails.leadsFound')}
              </Typography>
              {selectedLeadIds.length > 0 && (
                <>
                  <Typography variant="body2" display="inline" ml={1}>
                    {t('dashboard.leads.selected')}
                  </Typography>
                  <Typography variant="subtitle2" display="inline" ml={1}>
                    {`${selectedLeadIds.length} ${t('dashboard.newScraper.leadsLabel')}`}
                  </Typography>
                </>
              )}
            </Box>

            <Box display="flex" gap={3} my={2}>
              <Button disableRipple variant="contained" color="primary" size="medium">
                <Typography variant="subtitle2" minWidth="140px">
                  {t('dashboard.campaignDetails.addToCampaign')}
                </Typography>
              </Button>

              <LoadingIconButton
                loaderSize="1.5rem"
                onClick={handleDownloadCsv}
                loading={isLoadingCsv}
                loaderColor="secondary"
                size="medium"
                color="primary"
                disabled={!totalLeadsFound}
              >
                <DownloadIcon />
              </LoadingIconButton>
            </Box>
          </StyledLeadsBox>
        </Box>
      </Box>

      <Box display="flex" height="100%">
        {expandedFilters && (
          <Collapse in={expandedFilters}>
            <StyledSmartHeightFiltersContainer
              display="flex"
              flexDirection="column"
              gap={1}
              pr={2}
              width="250px"
              headerAppHeight={headerAppHeight}
            >
              <CustomFilters campaignType={campaignType} refetch={refetch} />
            </StyledSmartHeightFiltersContainer>
          </Collapse>
        )}
        <StyledSmartHeightDataGridContainer expanded={expandedFilters} headerAppHeight={headerAppHeight}>
          {/* ToDo for now use the same component, that can be renamed */}
          <ScrapekingDbDynamicDataGrid
            data={leadsData}
            handlePageChange={handlePaginationModelChange}
            paginationModel={{ page, pageSize: limit }}
            isDataLoading={isLoadingLeads}
            expandedFilters={expandedFilters}
            campaignType={campaignType}
            handleCheckboxSelection={handleSelectionChange}
          />
        </StyledSmartHeightDataGridContainer>
      </Box>
    </>
  );
};

export default memo(MyLeadsTable);
