import { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';
import { RootState } from '@store/store';

const PrivateRoutes: FC = () => {
  const navigate = useNavigate();
  const authState = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (authState.isConfirmed === false) {
      navigate(`/${ROUTING.CONFIRM_EMAIL}`, { replace: true });
    }
  }, [navigate, authState.isConfirmed]);

  if (!authState.isAuthenticated) return <Navigate to={ROUTING.LOGIN} replace />;

  return <Outlet />;
};

export default memo(PrivateRoutes);
