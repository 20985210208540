import { NavigateOptions, useSearchParams } from 'react-router-dom';
import { CampaignScrapersDto } from '@api/api';

export const useSourceNameQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sourceName = searchParams.get('source') || null;

  return {
    sourceName,
    handleChangeSourceName: (value: CampaignScrapersDto, navigateOpts?: NavigateOptions) => {
      searchParams.set('source', value.scrapers.scraper_name.trim());

      setSearchParams(searchParams, navigateOpts);
    },
    handleClearSourceName: () => {
      searchParams.delete('source');

      setSearchParams(searchParams);
    },
  };
};
