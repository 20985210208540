import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTING } from '@constants/routing';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

import { ScraperButton } from './styled';
import { useScreenSizeContext } from '@/shared/context/ScreenSizeContext';

const AddScraperButton: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLargerScreen } = useScreenSizeContext();

  const handleRedirect = () => navigate(`/${ROUTING.NEW_SCRAPER}`);

  return (
    <ScraperButton
      aria-label="amount of tokens"
      disableRipple
      disableElevation
      onClick={handleRedirect}
      variant="contained"
      color="secondary"
      fullWidth={!isLargerScreen}
    >
      <Typography mr={1} variant="subtitle2">
        {t('common.startScraperText')}
      </Typography>
      <AddIcon />
    </ScraperButton>
  );
};

export default memo(AddScraperButton);
