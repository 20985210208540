import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { PERIOD } from '@constants/billing';

import { PeriodValue } from '@/shared/types';

const PricingContext = createContext<ReturnType<typeof usePricingContextValue> | null>(null);

const usePricingContextValue = () => {
  const [period, setPeriod] = useState<PeriodValue>(PERIOD.month);

  return {
    period,
    setPeriod,
  };
};

export const usePricingContext = () => {
  const context = useContext(PricingContext);
  if (!context) throw new Error('usePricingContext must be inside SettingsProvider');
  return context;
};

export const PricingProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = usePricingContextValue();
  return <PricingContext.Provider value={value}>{children}</PricingContext.Provider>;
};
