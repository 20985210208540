import { api } from './api';
import Tag from './tag';

const provideTagsArray = <T extends { id: number }>(
  result: T[] | { results: T[] | undefined } | undefined,
  tag: Tag,
) => {
  const results = Array.isArray(result) ? result : result?.results;

  if (results?.length) {
    return results.map(({ id }) => ({ type: tag, id }));
  }

  return [{ type: tag }];
};

api.enhanceEndpoints({
  endpoints: {
    getCampaign: {
      providesTags: (result, error, args) => {
        return [{ type: Tag.CAMPAIGN, id: args.id }];
      },
    },
    getCampaigns: {
      providesTags: result =>
        result?.data?.map(({ campaign_id }) => {
          return { type: Tag.CAMPAIGN, id: campaign_id };
        }) || [{ type: Tag.CAMPAIGN }],
    },
    getMe: {
      providesTags: () => {
        return [{ type: Tag.USER }];
      },
    },
    updateCampaign: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.CAMPAIGN, id: args.id }, { type: Tag.BILLING }, { type: Tag.TRANSACTIONS }];
      },
    },
    updateMe: {
      invalidatesTags: () => {
        return [{ type: Tag.USER }];
      },
    },
    deleteCampaign: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.CAMPAIGN, id: args.id }, { type: Tag.BILLING }, { type: Tag.TRANSACTIONS }];
      },
    },
    getBalance: {
      providesTags: result => {
        return [{ type: Tag.BILLING }];
      },
    },
    createCampaign: {
      invalidatesTags: (result, error, args) => {
        return [{ type: Tag.BILLING }, { type: Tag.TRANSACTIONS }];
      },
    },
    confirmEmail: {
      invalidatesTags: () => {
        return [{ type: Tag.USER }];
      },
    },
    getSubscription: {
      providesTags: () => {
        return [{ type: Tag.SUBSCRIPTIONS }];
      },
    },
    getPrices: {
      providesTags: () => {
        return [{ type: Tag.PRICES }];
      },
    },
    getPackages: {
      providesTags: () => {
        return [{ type: Tag.PACKAGES }];
      },
    },
    cancelSubscription: {
      invalidatesTags: () => {
        return [{ type: Tag.SUBSCRIPTIONS }, { type: Tag.USER }, { type: Tag.PRICES }, { type: Tag.PACKAGES }];
      },
    },
    createTopup: {
      invalidatesTags: () => {
        return [{ type: Tag.BILLING }, { type: Tag.TRANSACTIONS }];
      },
    },
    getLeadsDatabase: {
      providesTags: result =>
        result?.data?.map(({ leads_id }) => {
          return { type: Tag.DB_LEADS, id: leads_id };
        }) || [{ type: Tag.DB_LEADS }],
    },
    purchaseLeads: {
      invalidatesTags: () => {
        return [
          { type: Tag.DB_LEADS },
          { type: Tag.BILLING },
          { type: Tag.TRANSACTIONS },
          { type: Tag.PURCHASED_LEADS },
        ];
      },
    },
    purchaseAllLeads: {
      invalidatesTags: () => {
        return [
          { type: Tag.DB_LEADS },
          { type: Tag.BILLING },
          { type: Tag.TRANSACTIONS },
          { type: Tag.PURCHASED_LEADS },
        ];
      },
    },
    getTransactions: {
      providesTags: result =>
        result?.data.map(({ billing_transactions_id }) => {
          return { type: Tag.TRANSACTIONS, id: billing_transactions_id };
        }) || [{ type: Tag.TRANSACTIONS }],
    },
    getPurchasedLeads: {
      providesTags: () => {
        return [{ type: Tag.PURCHASED_LEADS }];
      },
    },
  },
});
