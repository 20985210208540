import { FC, memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLeadScraperContext } from '@pages/LeadScraper/context';
import { StepContentProps } from '@pages/LeadScraper/types';
import { StyledAreaBox } from '@pages/styled';

import EditableBlock from '../EditableBlock';

import SourceSettingsForm from './SourceSettingsForm';

const SourceSettings: FC<StepContentProps> = ({ isActive, handleNext, handleBack, isCompleted, campaignId }) => {
  const { sourceSettingsForm } = useLeadScraperContext();

  return (
    <StyledAreaBox ml="8px" active={isActive}>
      <EditableBlock
        isCompleted={isCompleted}
        handleClick={handleBack}
        title="dashboard.newScraper.settings.sourceSettingsTitle"
        subtitle="dashboard.newScraper.settings.sourceSettingsSubtitle"
      />

      {isActive && (
        <FormProvider {...sourceSettingsForm}>
          <form onSubmit={sourceSettingsForm.handleSubmit(handleNext)}>
            <SourceSettingsForm id={campaignId} />
          </form>
        </FormProvider>
      )}
    </StyledAreaBox>
  );
};

export default memo(SourceSettings);
